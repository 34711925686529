import React from 'react';
import {Modal} from "react-bootstrap";
import './ModalNotification.scss';

const ModalNotification = ({
  showModal = false,
  notificationTitle = '',
  notificationText = '',
  setShowModal = () => {},
}) => {
  //#region Render
  return (
    <>
      <Modal
        show={showModal}
        fullscreen='true'
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{notificationTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={'ModalNotification-Text'}>{notificationText}</div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type={'button'}
            className={'modal-go-back-button'}
            onClick={() => {
              setShowModal(false)
            }}
          >
            {'Повернутися'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
  //#endregion
};

export default ModalNotification;
