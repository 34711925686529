import React, {useContext} from 'react';
import {Modal} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';
import {AppContext} from "../../context/appContext";
import {auth} from "../../utils/firebaseConfigAndFunctions";
import {deleteAccount} from "../../utils/helpers";

const ModalNotification = ({
  showModal,
  setShowModal,
}) => {
  const navigate = useNavigate();
  const {user} = useContext(AppContext);

  //#region Render
  return (
    <>
      <Modal
        show={showModal}
        fullscreen='true'
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Видалення облікового запису</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Ви дійсно бажаєте видалити свій обілковий запис?
            <br/>
            Всі дані буде втрачено без  можливості відновлення!
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type={'button'}
            className={'regular-button search-trip-results__button ' +
              'search-trip-results__button-phone'}
            onClick={() => {
              deleteAccount(auth.currentUser, user.idPost, navigate)
                .then(() => setShowModal(false));
            }}
          >
            Так, видалити
          </button>
          <button
            type={'button'}
            className={'modal-go-back-button'}
            onClick={() => {
              setShowModal(false)
            }}
          >
            Повернутися
          </button>
        </Modal.Footer>
      </Modal>

    </>
  );
  //#endregion
};

export default ModalNotification;
