import React from 'react';
import Chats from "../Chats/Chats";
import './ChatLeftSidebar.scss';

function ChatLeftSidebar({contactsList}) {
  //#region Render
  return (
    <React.Fragment>
      <div className={"chat-leftsidebar"}>
        <Chats
          contactsList={contactsList}
          setContactsList={() => {}}
        />
      </div>
    </React.Fragment>
  );
  //#endregion
}

export default ChatLeftSidebar;
