import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {auth} from "../../utils/firebaseConfigAndFunctions";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import ModalNotification
  from "../../modals/ModalNotification/ModalNotification";
import ModalReAuth from "../../modals/ModalReAuth/ModalReAuth";
import './ChangePasswordForm.scss';

const ChangePasswordForm = ({addClasses = ''}) => {
  //#region Get navigation
  const navigate = useNavigate();
  //#endregion

  //#region Modal for reAuth
  const [showReAuthModal, setShowReAuthModal] = useState(false);
  //#endregion

  //#region Notification
  const [notification, setNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Current password data
  const [currentPassword, setCurrentPassword] = useState('');
  //#endregion

  //#region New password data
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  //#endregion

  //#region Change password function
  const changePasswordSubmit = async (event, currentPassword, newPassword,
  confirmNewPassword) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setNotification(true);
      setNotificationTitle('Помилка введення даних');
      setNotificationMessage('Паролі не співпадають');

      return;
    }

    if (newPassword.length < 6) {
      setNotification(true);
      setNotificationTitle('Помилка введення даних');
      setNotificationMessage('Пароль занадто короткий. Має бути не менше шести ' +
        'символів');

      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword,
      );

      await reauthenticateWithCredential(auth.currentUser, credential);

      const curUser = auth.currentUser;

      await updatePassword(curUser, newPassword);

      setNotification(true);
      setNotificationTitle('Повідомлення');
      setNotificationMessage('Пароль успішно змінено');

      setTimeout(() => {
        navigate('/profile');
      }, 1500);

    } catch (error) {
      if (error?.message?.includes('auth/wrong-password')) {
        setNotification(true);
        setNotificationTitle('Помилка введення даних');
        setNotificationMessage('Невірний діючий пароль');
      }

      if (error?.message?.includes('auth/too-many-requests')) {
        setNotificationTitle('Помилка введення даних');
        setNotificationMessage('Перевищено кількість спроб авторизації. Спробуйте пізніше');
      }
    }
  };
  //#endregion

  //#region Render
  return (
    <>
      <h4 className={'change-password-form__title'}>
        {'Зміна пароля'}
      </h4>

      <form
        onSubmit={(event) => changePasswordSubmit(event,
          currentPassword, newPassword, confirmNewPassword)}
        className={`change-password-form ${addClasses}`}
      >
        <input
          type="password"
          className={'regular-form-control change-password-form__item'}
          placeholder="Діючий пароль"
          value={currentPassword || ''}
          onChange={(event) => setCurrentPassword(event.target.value)}
          required
        />

        <input
          type="password"
          className={'regular-form-control change-password-form__item'}
          placeholder="Новий пароль"
          value={newPassword || ''}
          onChange={(event) => setNewPassword(event.target.value)}
          required
        />

        <input
          type="password"
          className={'regular-form-control change-password-form__item'}
          placeholder={'Підтвердіть пароль'}
          value={confirmNewPassword || ''}
          onChange={(event) => setConfirmNewPassword(event.target.value)}
          required
        />

        <button
          type="submit"
          className={'regular-button change-password-form__item ' +
            'change-password-form__change-button'}
        >
          Змінити пароль
        </button>
      </form>

      {showReAuthModal &&
        <ModalReAuth
          setShowModal={setShowReAuthModal}
          showModal={showReAuthModal}
          newPassword={newPassword}
        />
      }

      {notification &&
        <ModalNotification
          showModal={notification}
          setShowModal={setNotification}
          notificationTitle={notificationTitle}
          notificationText={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default ChangePasswordForm;
