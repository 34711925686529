import React from "react";
import {Link} from 'react-router-dom';
import './CustomButton.scss';

export const CustomButton = ({
  onClick,
  content,
  linkTo
}) => {

  return (
    <div
      onClick={onClick}
    >
      <Link
        to={linkTo}
        className="custom-button"
      >
        {content}
      </Link>
    </div>
  );
};
