import React, {useContext, useState} from 'react';
import {Modal} from "react-bootstrap";
import {
  createTelegramMessage,
  saveOrderToCrmDb,
  sendTelegramMessage,
} from "../../utils/helpers";
import {botApi, chatId} from "../../utils/models";
import InputMask from "react-input-mask";
import './ModalOrderTickets.scss';
import {AppContext} from "../../context/appContext";

const ModalOrderTickets = ({showModal, setShowModal, tripData}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Handle form fields updating
  const [clientData, setClientData] = useState({});

  const updateClientData = (fieldName, newValue) => {
    setClientData(state => ({
      ...state,
      [fieldName]: newValue,
    }));
  };
  //#endregion

  //#region Handle buy ticket form submit
  const handleBuyTicketSubmit = async (event) => {
    try {
      event.preventDefault();

      const message = createTelegramMessage(clientData, tripData);

      await sendTelegramMessage(botApi, chatId, message);

      await saveOrderToCrmDb(clientData, tripData, user.idPost);

      setShowModal(false);
      return true;
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <Modal
        show={showModal}
        fullscreen='true'
        onHide={() => {
          setShowModal(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{'Замовлення квитків'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="order-tickets-form"
            onSubmit={(event) => handleBuyTicketSubmit(
              event)}
          >
            <input
              type="text"
              className="order-tickets-form__item"
              placeholder="Повне ім’я:"
              value={clientData.fullName}
              required
              onChange={(event) => updateClientData('fullName',
                event.target.value)}
            />

            <InputMask
              mask="+380 \ 99 999 99 99"
              maskChar=" "
              placeholder="Телефон"
              required
              className="order-tickets-form__item"
              onChange={(event) => updateClientData('phoneNumber',
                event.target.value)}
            />

            <textarea
              name=""
              id=""
              rows="5"
              className="order-tickets-form__item"
              placeholder="Коментар:"
              value={clientData.comment}
              onChange={(event) => updateClientData('comment',
                event.target.value)}
            ></textarea>

            <div className={'buttons-container'}>
              <button
                type={'button'}
                className={'modal-go-back-button'}
                onClick={() => {
                  setShowModal(false)
                }}
              >
                {'Повернутися'}
              </button>

              <button
                type={'submit'}
                className="send-buy-ticket-order-button"
              >
                {'Надіслати заявку'}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
  //#endregion
};

export default ModalOrderTickets;
