import React from 'react';
import RegistrationForm from "../../components/RegistrationForm/RegistrationForm";
import './Register.scss';

const Register = ({addClasses = ''}) => {
  //#region Render
  return (
    <div className={`register-page ${addClasses}`}>
      <RegistrationForm
        addClasses={'register-page__registration-form'}
      />
    </div>
  );
  //#endregion
};

export default Register;
