import React from 'react';
import './OfferAgreement.scss';

const OfferAgreement = () => {
  return (
    <div className="offer-agreement">
      <h1>Договір Публічної Оферти</h1>
      <div className="offer-content">
        <h2>Предмет договору</h2>
        <p>1. Договір публічної оферти - публічний договір, зразок якого розміщено на сайті SEM-BUS.COM, є офіційною публічною пропозицією (офертою).</p>
        <p>2. При оформленні квитків за допомогою сайту, Користувач погоджується з умовами цього Договору та приймає він зобов'язання, які їм визначені.</p>
        <p>3. Згідно з умовами цього Договору, Перевізник зобов'язується надати Послуги пасажирського перевезення Замовника та його багажу відповідно до обраного ним маршруту, дати та часу відправлення, а Замовник зобов'язується попередньо сплатити Послуги Перевізника.</p>

        <h2>Терміни та визначення</h2>
        <p><strong>Перевізник</strong> - юридична особа, яка безпосередньо надає Послугу з перевезення Замовника та його багажу, обрану та оплачену за допомогою системи бронювання та оплати на Сайті.</p>
        <p><strong>Замовник</strong> - дієздатна фізична особа, яка досягла 18 років і має законне право вступати у договірні відносини, у тому числі розміщувати замовлення або бронювати Послуги на Сайті Перевізника. Замовник може діяти в рамках Договору від свого імені та на свою користь та/або від імені третьої сторони та її користь, у разі Пасажир діє виключно межах повноважень, наданих третьою стороною.</p>
        <p><strong>Послуга</strong> - комплекс платних послуг, які можна отримати Замовником на умовах, способом та в порядку, що регламентуються цим Договором.</p>
        <p><strong>Сайт</strong> – відкритий для вільного візуального ознайомлення будь-якою особою, публічно доступний сайт, що належить Перевізнику, розташований у мережі Інтернет за адресою www.sem-bus.com  для отримання Послуг з пошуку маршруту, місця та часу відправлення автобуса, вартості Послуг та інше.</p>
        <p><strong>Акцепт</strong> - повне, безумовне та беззастережне прийняття Замовником умов цього Договору.</p>

        <h2>Загальні положення</h2>
        <p>1. Цей Договір є публічним і відповідно до ст. 633 Цивільного кодексу України умови однакові всім Замовників.</p>
        <p>2. Цей Договір вважається укладеним (акцептованим) з моменту заповнення Замовником формуляру бронювання на Сайті Перевізника та надходження коштів Замовника на розрахунковий рахунок Перевізника.</p>
        <p>3. Цей Договір вважається укладеним (акцептованим) з моменту заповнення Пасажиром формуляру бронювання на Сайті Перевізника та надходження коштів Пасажира на розрахунковий рахунок Перевізника.</p>
        <p>4. Договір має юридичну силу відповідно до ч. 2 ст. 642 Цивільного кодексу України рівносильним укладання двостороннього письмового Договору, підписаного Сторонами.</p>

        <h2>Порядок розрахунків</h2>
        <p>1. Послуга надається Замовнику на попередній платній основі шляхом оплати Замовником обраної перевезення відповідно до тарифів та валюти, зазначених на Сайті Перевізника.</p>
        <p>2. Оплата Послуг Перевізника, а також інших фінансових зобов'язань Замовника перед Перевізником, які можуть виникати відповідно до вимог цього Договору, що здійснюються шляхом перерахування коштів на поточний рахунок Перевізника через установу банку за реквізитами, зазначеними на Сайті Перевізника.</p>

        <h2>Умови повернення Квитка</h2>
        <p>1. Добровільно, більш ніж за 48 години до відправлення рейсу. Повертається 95%  від вартості тарифу.</p>
        <p>2. При скасуванні рейсу Повертається 100% вартості тарифу.</p>
        <p>3. Добровільно, більш ніж за 24 години і менше ніж за 48 години до відправлення рейсу. Повертається  при поверненні: 80%  вартості тарифу.</p>
        <p>4. Менш ніж за 24 год і більше за 3 год.  до відправлення рейсу. Повертається при поверненні: 50% вартості тарифу.</p>
        <p>5. Менш ніж за 3 год.  до відправлення рейсу. ПОВЕРНЕННЯ НЕ ДОПУСКАЄТЬСЯ</p>

        <h2>Права та обов'язки Сторін</h2>
        <h3>1. Права Перевізника:</h3>
        <p>1.1. Отримувати від Замовника інформацію, необхідну для надання послуг.</p>
        <p>1.2. Зберігати та обробляти персональні дані, отримані в процесі надання послуг, відповідно до вимог чинного законодавства України.</p>
        <p>1.3. Перевізник має право не пустити в автобус Замовника, який: прийшов на посадку у нетверезому стані, з явними ознаками нездоров'я, які є небезпечними для інших пасажирів автобуса, та/або порушує правила поведінки в громадських місцях, дії якої можуть бути небезпечними для оточуючих.</p>
        <p>1.4. У разі, якщо Пасажир не підтверджує свій виїзд, Перевізник залишає за собою право продати місце без грошової компенсації Замовнику.</p>
        <p>1.5. Самостійно встановлювати ціну послуг та інші умови їх надання шляхом розміщення відповідної інформації на веб-сайті Виконавця;</p>
        <p>1.6. Вартість послуг на Сайті може змінюватися Продавцем в односторонньому порядку. При цьому вартість   замовленої  Покупцем послуги  не підлягає зміні, без погодження.</p>

        <h3>2. Обов'язки Перевізника:</h3>
        <p>2.1. Забезпечити захист персональних даних, отриманих від Замовника.</p>
        <p>2.2. Забезпечити своєчасне подання транспортного засобу для посадки Замовника та його відправлення в відповідно до розкладу руху.</p>
        <p>2.3. Надати Замовнику посадкові місця відповідно до придбаного Проїзного документа.</p>
        <p>2.4. Доставити Пасажира та його багаж до пункту призначення.</p>
        <p>2.5. Забезпечити безпеку перевезення та якісне обслуговування Замовника в автобусі.</p>
        <p>2.6. Керуватися Правилами дорожнього руху та Правилами технічної експлуатації автобуса.</p>

        <h3>3. Права Пасажира:</h3>
        <p>3.1. Отримати якісні послуги перевезення.</p>
        <p>3.2. Отримати своєчасну та повну інформацію про умови та порядок надання Послуг з перевезення Замовника та його багажу.</p>
        <p>3.3. Замовник має право перевезти безкоштовно 1 одиницю багажу розміром не більше 60х40х40 см, загальною вагою до 30 кг, включаючи ручну поклажу до 5 кг.</p>

        <h3>4. Обов'язки Пасажира:</h3>
        <p>4.1. За 1-2 дні до поїздки Замовник зобов'язаний підтвердити свій виїзд.</p>
        <p>4.2. Замовник повинен прибути на посадку та зареєструватися не пізніше ніж за 15 хвилин до вказаного в Квитку часу відправлення автобуса. У разі запізнення на посадку відповідальність несе Замовник.</p>
        <p>4.3. Зайняти вказане місце в автобусі.</p>
        <p>4.4. У разі скасування поїздки повідомити Перевізнику не менше ніж за 24 години до відправлення.</p>
        <p>4.5. Обережно звертатися з майном автобуса, не допускати його псування.</p>

        <h2>Відповідальність Сторін та вирішення спорів</h2>
        <p>1. За невиконання або неналежне виконання своїх зобов'язань за цим Договором Сторони несуть відповідальність відповідно до чинного законодавства України.</p>
        <p>2. Перевізник відповідає за зберігання та обробку персональних даних Пасажира, забезпечує конфіденційність цих даних у процесі їх обробки та використовує їх виключно для якісного надання послуг.</p>
        <p>3. Перевізник не несе відповідальності:</p>
        <ul>
          <li>3.1. За затримку, скасування, перенесення, зміну умов надання Послуг, якщо такі зміни виникли не з вини Перевізника.</li>
          <li>3.2. За витрати, які виникли у Замовника внаслідок несвоєчасного прибуття до пункту призначення.</li>
          <li>3.3. За речі, залишені чи забуті в салоні автобуса.</li>
          <li>3.4. За несвоєчасне подання транспортного засобу, яке виникло внаслідок обставин непереборним сили (включаючи, але не обмежуючись: погодні умови, пробки на дорогах, дії державних органів тощо, а також дії пасажирів, які впливають на належне виконання Перевізником своїх зобов'язань), відхилення від розкладу або інших обставин (технічні неполадки автобуса по дорозі до місця відправлення, військові дії), які перевізник, незважаючи на прийняті заходи, не зміг передбачити та запобігти.</li>
        </ul>
        <p>4. Замовник відповідає:</p>
        <ul>
          <li>4.1. За надання достовірних даних під час оформлення Послуг.</li>
        </ul>
        <p>5. Будь-які спірні питання, які можуть виникнути за цим Договором або у зв'язку з його виконанням, Перевізник та Замовник вирішують шляхом переговорів та виключно за наявності письмової заяви Замовника, переданого засобами поштового зв'язку.</p>

        <h2>Термін дії Договору та порядок його розірвання</h2>
        <p>1. Цей Договір набирає чинності з моменту його Акцепту Замовником та діє до повного виконання Сторонами зобов'язань за Договором.</p>
        <p>2. Цей Договір може бути розірваний до закінчення його дії в односторонньому порядку у випадках, передбачених умовами Договору.</p>

        <p>тел. для довідок: +380975013093</p>
        <p>e-mail: sembus2024@ukr.net</p>
        <p>Адресa: 34600, Рівненська обл., Рівненський район,м. Березне., вул. Затишна, буд 25</p>
      </div>
    </div>
  );
};

export default OfferAgreement;
