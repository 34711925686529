import React, {useEffect} from 'react';
import {Ordering} from "../Ordering/Ordering";
import {CityCard} from "../CityCard/CityCard";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import './Order.scss';
import { TbArrowsLeftRight } from "react-icons/tb";

export const Order = ({
  tripData,
  setTripData,
  setSearchResultsTitle,
  setAreSearchResultsVisible,
  setSuitableRoutes,
  setBackwardRoutes,
  tripsForSlider
}) => {
  return (
    <div className="order">
      <h1 className="order__title order__title--composed">
        <span> Пасажирські перевезення Україна </span>
        <span> <TbArrowsLeftRight /> </span>
        <span> Естонія </span>
        <span> <TbArrowsLeftRight /> </span>
        <span> Литва </span>
        <span> <TbArrowsLeftRight /> </span>
        <span> Латвія </span>
      </h1>

      <div className="order__ordering">
        <Ordering
          tripData={tripData}
          setTripData={setTripData}
          setSearchResultsTitle={setSearchResultsTitle}
          setSuitableRoutes={setSuitableRoutes}
          setBackwardRoutes={setBackwardRoutes}
          setAreSearchResultsVisible={setAreSearchResultsVisible}
        />
      </div>

      <div className="order__circle">
        <div className="order__circle-circle"></div>

        <div className="order__circle-core"></div>

        <div className="order__circle-card-bottom">
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 5000,
            }}
            slidesPerView={1}
            loop={true}
          >
            {tripsForSlider.map(trip => (
              <SwiperSlide
                key={trip.id}
                className="order__circle-card-wrap"
              >
                <div className="order__circle-card__item">
                  <CityCard
                    trip={trip}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="order__circle-card-top">
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 5000,
            }}
            slidesPerView={1}
            loop={true}
          >
            {tripsForSlider.reverse().map(trip => (
              <SwiperSlide
                key={trip.id}
                className="order__circle-card-wrap"
              >
                <div className="order__circle-card__item">
                  <CityCard
                    trip={trip}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

    </div>
  );
}
