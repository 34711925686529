import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './ScrollBlock.scss';

const countries = [
  {
    map: "country1",
    name: "Литва",
  },
  {
    map: "country2",
    name: "Латвія",
  },
  {
    map: "country3",
    name: "Естонія",
  },
]

export const ScrollBlock = () => {
  //#region Render
  return (
    <div className="scrollblock">
      {/*<div className="scrollblock__scrollmap">*/}
      {/*  <Swiper*/}
      {/*    slidesPerView={1}*/}
      {/*    breakpoints={{*/}
      {/*      1024: {*/}
      {/*        slidesPerView: 2*/}
      {/*      }*/}
      {/*    }}*/}
      {/*    loop={true}*/}
      {/*  >*/}
      {/*    {countries.map((cn, i) => (*/}
      {/*      <SwiperSlide*/}
      {/*        key={`${cn.map.substring(0, 3)}-${i}`}*/}
      {/*        className="scrollblock__scrollmap-item"*/}
      {/*      >*/}
      {/*        <div>*/}
      {/*          <h1 className="scrollblock__scrollmap-title">*/}
      {/*            {cn.name}*/}
      {/*          </h1>*/}
      {/*          <img*/}
      {/*            className="scrollblock__scrollmap-img"*/}
      {/*            src={`./assets/images/carouselCountry/${cn.map}.png`}*/}
      {/*            alt={cn.name}*/}
      {/*          />*/}
      {/*        </div>*/}

      {/*      </SwiperSlide>*/}
      {/*    ))}*/}
      {/*  </Swiper>*/}
      {/*</div>*/}

      <div className="scrollblock__content">
        {/*<h1  className="scrollblock__content-title">*/}
        {/*  Завозимо прямо за адресою!*/}
        {/*</h1>*/}

        {/*<p  className="scrollblock__content-text">*/}
        {/*  Автопарк нашої компанії складається тільки з сучасних комфортабельних автобусів SETRA  Євро 5/6  на яких поїздки запам’ятовуються виключним комфортом.*/}
        {/*  Це вже відчувається при посадці в автобус. Велика кількість продуманих деталей доповнює картину*/}
        {/*</p>*/}
      </div>

    </div>
  );
  //#endregion
};
