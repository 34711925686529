import React, {useState} from 'react';
import ModalNotification
  from "../../modals/ModalNotification/ModalNotification";
import {Link} from "react-router-dom";
import {sendPasswordResetEmail} from "firebase/auth";
import {auth} from "../../utils/firebaseConfigAndFunctions";
import './RecoverPassword.scss';

const RecoverPassword = () => {
  //#region Notification
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationText, setNotificationText] = useState('');
  //#endregion

  //#region Data and handlers for password recover
  const [userEmail, setUserEmail] = useState('');

  function updateEmail(newValue) {
    setUserEmail(newValue);
  };

  async function recoverPasswordFormSubmitHandler(event) {
    event.preventDefault();

    try {
      await sendPasswordResetEmail(auth, userEmail);

      setIsNotification(true);
      setNotificationTitle('Notification');
      setNotificationText('Link for password recover was sent. Visit your email');
      console.log('Recover link was sent');
    } catch (error) {
      console.log(error.message);
      if (error.message.slice(22, -2) === 'invalid-email') {
        setNotificationTitle('Notification');
        setIsNotification(true);
        setNotificationText('You have entered wrong email, try again');
      } else {
        setNotificationTitle('Notification');
        setIsNotification(true);
        setNotificationText('Something went wrong, try again');
      }
    }
  };
  //#endregion

  //#region Render
  return (
    <>
      <div className="recover-password-page">
        <h4
          className={'recover-form__title'}
        >
          Відновлення пароля
        </h4>

        <form className={'login-form recover-password-page__recover-form'}>
          <input
            type="email"
            className={'regular-form-control recover-form__item'}
            placeholder="Введіть електронну пошту"
            onChange={(event) => updateEmail(event.target.value)}
            value={userEmail}
          />

          <button
            type={'submit'}
            className={
              'regular-button recover-form__item recover-form__send-button'
            }
            onClick={(event) => recoverPasswordFormSubmitHandler(event)}
          >
            Відправити
          </button>

          <h4 className={'recover-form__remember-password'}>
            Згадали пароль?
            <Link to="/login" className={'recover-form__login-link'}>
              Увійти
            </Link>
          </h4>
        </form>
      </div>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          notificationTitle={notificationTitle}
          notificationText={notificationText}
        />
      }
    </>
  );
  //#endregion
};

export default RecoverPassword;
