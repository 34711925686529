import React, {memo, useEffect} from "react";
import './CityCard.scss';

export const CityCard = memo(({
  trip
}) => {

  const {
    from,
    to = 'city',
    minPrice,
    departure
  } = trip

  // const from = trip?.points[0].cityData.city;
  // const to = trip?.points[trip.points.length - 1].cityData.city;

  return (
    <div className="city-card">
      <div className="city-card__img-wrap">
        <img
          className="city-card__img"
          src={`/assets/images/cities/${to}.webp`}
          alt={to}
        />
      </div>

      {/*<h4 className="city-card__title">{from} - {to}</h4>*/}
      <h4 className="city-card__title">{from} - {to}</h4>

      <div className="city-card__price">{minPrice} &#8372;</div>
      <p className="city-card__content">Щоденні виїзди о {departure[0]}:{departure[1]}</p>
    </div>
  );
});
