import React, {useState, useContext} from 'react';
import {Modal} from "react-bootstrap";
import {AppContext} from "../../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  auth,
  signIn,
  updateFieldInDocumentInCollection
} from "../../utils/firebaseConfigAndFunctions";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import {collectionsInterface} from "../../utils/models";
import './ModalReAuth.scss';

const ModalReAuth = ({
  showModal,
  setShowModal,
  newEmail,
  newPassword,
  setUserAccountData = () => {},
}) => {
  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get navigation
  const navigate = useNavigate();
  //#endregion

  //#region Update credentials
  const [credentials, setCredentials] = useState({});

  function updateCredentials(fieldName, newValue) {
    setCredentials(data => ({
      ...data,
      [fieldName]: newValue,
    }));
  }
  //#endregion

  //#region Submit form and reauth user
  async function reAuthUserAndUpdateEmailOrPassword(event, email, password) {
    event.preventDefault();

    try {
      await signIn(email, password);

      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        credentials.password
      );

      await reauthenticateWithCredential(auth.currentUser, credential);

      if (newEmail) {
        await updateEmail(auth.currentUser, newEmail);

        await updateFieldInDocumentInCollection(collectionsInterface.users,
          user.idPost, 'email', newEmail);

        setUser(prevUser => ({
          ...prevUser,
          email: newEmail,
        }));
      }

      if (newPassword) {
        const curUser = auth.currentUser;

        await updatePassword(curUser, newPassword);

        navigate('/profile');
      }

      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region Render
  return (
    <>
      <Modal show={showModal} fullscreen='true' onHide={() => {
        setShowModal(false);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {'Авторизація'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h6 className={'modal-re-auth__title'}>
            {'Авторизуйтесь повторно для виконання дії'}
          </h6>

          <form
            onSubmit={
            (event) => reAuthUserAndUpdateEmailOrPassword(
              event, credentials.email, credentials.password)}
            className={'modal-re-auth__re-auth-form re-auth-form'}
          >
            <input
              type="email"
              className={'regular-form-control re-auth-form__item'}
              placeholder="Електронна пошта"
              value={credentials.email || ''}
              onChange={(event) => updateCredentials('email',
                event.target.value)}
              required
            />

            <input
              type="password"
              className={'regular-form-control re-auth-form__item'}
              placeholder="Пароль"
              value={credentials.password || ''}
              onChange={(event) => updateCredentials('password',
                event.target.value)}
              required
            />

            <button
              type="submit"
              className={'regular-button re-auth-form__item re-auth-form__button'}
            >
              {'Підтвердити'}
            </button>
          </form>

          <div className={'modal-re-auth__footer'}>
            <button
              type={'button'}
              className={'regular-button modal-go-back-button'}
              onClick={() => {
                setShowModal(false);
                setUserAccountData(user);
              }}
            >
              {'Повернутися'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
  //#endregion
};

export default ModalReAuth;
