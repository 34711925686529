import React, {useContext, useEffect, useState} from 'react';
import './Profile.scss';
import {AppContext} from "../../context/appContext";
import {useNavigate} from "react-router-dom";
import {truncateString} from "../../utils/helpers";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import AdminChat from "../../components/AdminChat/AdminChat";
import ClientChat from "../../components/ClientChat/ClientChat";
import {auth} from "../../utils/firebaseConfigAndFunctions";

const Profile = () => {
  //#region Get user from app context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get history object
  const navigate = useNavigate();
  //#endregion

  //#region Redirect to main if not authorized
  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/');
    }
  }, []);
  //#endregion

  //#region Tabs managing
  const tabsNames = {
    profile: 'profile',
    chat: 'chat',
  }

  const [currentTab, setCurrentTab] = useState(tabsNames.profile);
  //#endregion

  //#region Render
  return (
    <div className="profile">
      <div className="profile__sidebar sidebar-in-profile">
        <div
          className={'avatar-with-text sidebar-in-profile__avatar-with-text'}
        >
          <div className={'avatar-outside-container'}>
            {user?.photoUrl ?
                <img src={user?.photoUrl} alt={'Avatar'} className={'avatar'}/>
                :
                <div className={'avatar-anonym'}></div>
            }
          </div>

          <div className={'text-container'}>
            <div className={'text-container__name'}>
              {`${user?.fullName || 'not defined'} `}
            </div>
            <div className={'text-container__id'}>
              {`ID: ${user?.uid ? truncateString(user?.uid, 4) 
                : 'not defined'}`}
            </div>
          </div>
        </div>

        <div className='sidebar-in-profile__tabs-switches'>
          <div
              className={'sidebar-in-profile__tab-item'}
              onClick={() => setCurrentTab(tabsNames.profile)}
          >
            <div
                className={currentTab === tabsNames.profile
                  ? 'sidebar-in-profile__tab-switch ' +
                  'sidebar-in-profile__tab-switch--active'
                    : 'sidebar-in-profile__tab-switch'}
            >
              <span className={'icon-with-text'}>
                <img
                    className={'icon-with-text__icon'}
                    src={'assets/images/person-icon.svg'}
                    alt={'person icon'}
                />
                <span className={'icon-with-text__text'}>
                  {'Мій профіль'}
                </span>
              </span>
            </div>
          </div>

          <div
            className={'sidebar-in-profile__tab-item'}
            onClick={() => setCurrentTab(tabsNames.chat)}
          >
            <div
                className={currentTab === tabsNames.chat
                  ? 'sidebar-in-profile__tab-switch' +
                  ' sidebar-in-profile__tab-switch--active'
                    : 'sidebar-in-profile__tab-switch'}
            >
              <span className={'icon-with-text'}>
                <img
                    className={'icon-with-text__icon'}
                    src={'assets/images/chat-icon.svg'}
                    alt={'chat icon'}
                />
                <span className={'icon-with-text__text'}>
                  {'Чат'}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="profile__main">
        {currentTab === tabsNames.profile ?
          <ProfileInfo addClasses={'profile__profile-info'} />
          :
          <div className={'profile__chat'}>
            {user?.role === 'admin' ?
              <AdminChat />
              :
              <ClientChat />
            }
          </div>
        }
      </div>
    </div>
  );
  //#endregion
};

export default Profile;
