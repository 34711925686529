import React, {useContext, useState} from 'react';
import {AppContext} from "../../context/appContext";
import {ClientChat} from "../../components/ClientChat/ClientChat";
import {Order} from "../../components/Order/Order";
import {Benefits} from "../../components/Benefits/Benefits";
import {ScrollBlock} from "../../components/ScrollBlock/ScrollBlock";
import {BusPark} from "../../components/BusPark/BusPark";
import {Feedbacks} from "../../components/Feedbacks/Feedbacks";
import {VideoBlock} from "../../components/VideoBlock/VideoBlock";
import {CitySlider} from "../../components/CitySlider/CitySlider";
import {Certificates} from "../../components/Certificates/Certificates";
import {ContactUs} from "../../components/ContactUs/ContactUs";
import SearchTripResults from "../../components/SearchTripResults/SearchTripResults";
import {tripSearchModel} from "../../utils/models";
import {getTripsForCitiesSlider} from "../../utils/helpers";
import './Home.scss';
import {cities, trips} from "../../utils/busRoutes";

//This code extend data about cities in trips
trips.forEach(trip => {
  trip.points.forEach(point => {
    point.cityData = cities.find(city => city.cityName === point.cityName);
  });
});

//#region Create array with data for slider
const tripsForSlider = getTripsForCitiesSlider();
//#endregion

export const Home = ({
  areSearchResultsVisible,
  setAreSearchResultsVisible
}) => {
  //#region Get user and auth state from app context
  const {isLoggedIn} = useContext(AppContext);
  //#endregion

  //#region Handle chat appearance
  const [isChatVisible, setIsChatVisible] = useState(false);

  const toggleChat = () => {
    setIsChatVisible(state => !state);
  }
  //#endregion


  //#region Handle search result visibility
  const [searchResultsTitle, setSearchResultsTitle] = useState('');

  const [suitableRoutes, setSuitableRoutes] = useState([]);
  const [backwardRoutes, setBackwardRoutes] = useState([]);
  //#endregion

  //#region Trip data
  const [tripData, setTripData] = useState(tripSearchModel);
  //#endregion

  //#region Render
  return (
    <>
      {areSearchResultsVisible ?
        <SearchTripResults
          tripData={tripData}
          title={searchResultsTitle}
          setAreSearchResultsVisible={setAreSearchResultsVisible}
          suitableRoutes={suitableRoutes}
          backwardRoutes={backwardRoutes}
          addClasses={'home__search-trip-results'}
        />
        : (
          <section>
            <div className="wrap">
              <Order
                tripData={tripData}
                setTripData={setTripData}
                setSearchResultsTitle={setSearchResultsTitle}
                setAreSearchResultsVisible={setAreSearchResultsVisible}
                setSuitableRoutes={setSuitableRoutes}
                setBackwardRoutes={setBackwardRoutes}
                tripsForSlider={tripsForSlider}
              />
            </div>

            <div className="wrap">
              <Benefits/>
            </div>

            <div className="wrap">
              <ScrollBlock/>
            </div>

            <div className="wrap">
              <BusPark/>
            </div>

            <div className="wrap">
              <Feedbacks/>
            </div>

            <div className="wrap">
              <VideoBlock/>
            </div>

            <div className="wrap">
              <CitySlider
                tripsForSlider={tripsForSlider}
              />
            </div>

            <div className="wrap">
              <Certificates/>
            </div>

            <div className="wrap">
              <ContactUs/>
            </div>
          </section>
        )}

      {/*#region POP-UP CHAT*/}
      {!isLoggedIn &&
        <>
          {isChatVisible ?
            <div className="chat-wrapper">
              <div className="UnauthorizedChat">
                <div
                  className="UnauthorizedChat__close"
                  onClick={() => toggleChat()}
                >
              </div>

                <ClientChat
                  addClasses='unauth-chat'
                  isAuthorized={false}
                />
              </div>
            </div>
            :
            <div
              onClick={() => toggleChat()}
              className="OpenChat"
            >
            </div>
          }
        </>
      }
      {/*#endregion*/}
    </>
  );
  //#endregion
};
