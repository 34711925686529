import React from 'react';
import ChangePasswordForm
  from "../../components/ChangePasswordForm/ChangePasswordForm";

const ChangePassword = ({addClasses=''}) => {
  //#region Render
  return (
    <div className={`change-password-page ${addClasses}`}>
      <ChangePasswordForm
        addClasses={'change-password-page__change-password-form'}
      />
    </div>
  );
  //#endregion
};

export default ChangePassword;
