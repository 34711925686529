import React from 'react';
import {SocialBar} from "../SocialBar/SocialBar";
import {Link as ScrollLink} from "react-scroll";
import './Footer.scss';
import {Link} from "react-router-dom";

const socialsList = ["fb", "insta"];
const contactsList = ["viber", "whatsapp", "telegram"];

export const Footer = ({setAreSearchResultsVisible}) => {
    //#region Render
    return (
        <footer className="footer">
            <ScrollLink
                to="main"
                href="/"
                className="header__list-link"
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => setAreSearchResultsVisible(false)}
            >
                <img
                    className="footer__logo"
                    src="./assets/images/logo.svg"
                    alt="logo"
                />
            </ScrollLink>

            <div className="footer__socials">
        <span
            className="footer__socials-title"
        >
          Контакти:
        </span>

                <div className={'footer__phone-numbers'}>
                    <div className={'footer__numbers-block'}>
                        <a
                            href="tel:+380975013093"
                            className="footer__list-link"
                        >
                            <span className="no-wrap">+38(097) 501 30 93</span>
                        </a>

                        <a
                            href="tel:+380988082606"
                            className="footer__list-link"
                        >
                            <span className="no-wrap">+38(098) 808 26 06</span>
                        </a>
                    </div>

                    <div className={'footer__numbers-block'}>
                        <a
                            href="tel:+380982175063"
                            className="footer__list-link"
                        >
                            <span className="no-wrap">+38(098) 217 50 63</span>
                        </a>

                        <a
                            href="tel:+380977561208"
                            className="footer__list-link"
                        >
                            <span className="no-wrap">+38(097) 756 12 08</span>
                        </a>
                    </div>
                </div>

                <SocialBar
                    socialsList={contactsList}
                />

                <ScrollLink
                    to="main"
                    href="/"
                    className="header__list-link"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => setAreSearchResultsVisible(false)}
                >
                    <Link to="/offer-agreement" className="footer__list-link-oferta">
                        Договір Публічної Оферти
                    </Link>
                </ScrollLink>

                <span>34600, Рівненська обл., Рівненський район,м. Березне., вул. Затишна, буд 25</span>

            </div>

            <div className="footer__socials">
        <span
            className="footer__socials-title"
        >
          Медіа:
        </span>
                <SocialBar
                    socialsList={socialsList}
                />
            </div>
        </footer>
    );
    //#endregion
};

