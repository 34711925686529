//#region User
export const userModel = {
    photoUrl: '',
    fullName: '',
    birthDay: '',
    phoneNumber: '',
    email: '',
    role: '',
    uid: '',
};
//#endregion

//#region Trip
export const cityModel = {
    address: '',
    id: 0,
    city: '',
    cityName: '',
    country: '',
};

export const tripSearchModel = {
    adultsAmount: 0,
    arrivingPoint: cityModel,
    backDepartureDate: '',
    childrenAmount: 0,
    departureDate: '',
    departurePoint: cityModel,
    directions: 'one',
};
//#endregion


//#region Other
export const sessionUserId = 'sessionUserId';
//#endregion

//#region Chats
export const contactDynamicModel = {
    id: '',
    photoUrl: '',
    fullName: '',
    country: '',
    newMessagesReceivedAmount: '',
    messages: [],
}
//#endregion

//#region Interfaces
export const collectionsInterface = {
    users: 'users',
    orders: 'orders',
}
//#endregion

//#region Data for telegram
export const botApi = '5953528215:AAF2zWkLS2oAuPi9maVPnq2nZMwi_l2ezbE';

export const chatId = '1849637951';
//#endregion

//#region Links to files in storage
export const videoLink = 'https://firebasestorage.googleapis.com/v0/b/sem-bus.appspot.com/o/video.mp4?alt=media&token=12e6d190-9bb8-4f53-9b4d-e9d18d610b61';
//#endregion

//#region Days of week
export const daysOfWeek = {
    0: 'неділя',
    1: 'понеділок',
    2: 'віворок',
    3: 'середа',
    4: 'четвер',
    5: 'п\'ятниця',
    6: 'субота',
}
//#endregion
