import React, {useState} from "react";
import InputMask from "react-input-mask";
import {createTelegramMessage, sendTelegramMessage} from "../../utils/helpers";
import {botApi, chatId} from "../../utils/models";
import './ContactUs.scss';

export const ContactUs = ({
  title = 'Зворотній зв\'язок',
  text = 'Якщо у Вас залишилися питання або є індивідуальне замовлення,\n' +
  ' що вимагає розмови з менеджером – залиште свій контакт і ми\n' +
  ' зв\'яжемося з Вами.'
}) => {
  //#region Managing contact data
  const [contactData, setContactData] = useState({});

  const updateContactData = (fieldName, newValue) => {
    setContactData(state => ({
      ...state,
      [fieldName]: newValue,
    }));
  }
  //#endregion

  //#region Submit contact form
  const submitContactForm = async (event) => {
    event.preventDefault();
    window.dataLayer.push({'event':'ticket_order'})

    const message = createTelegramMessage(contactData);

    try {
      await sendTelegramMessage(botApi, chatId, message);

      setContactData({});
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region Render
  return (
    <div className="contact-us">
      <h1 className="contact-us__title">
        {title}
      </h1>

      <div className="contact-us__text">
        {text}
      </div>

      <form
        className="contact-us__form"
        onSubmit={(event) => submitContactForm(event)}
      >
        <input
          type="text"
          className="contact-us__form-input"
          placeholder="Повне Ім’я:"
          onChange={(event) => updateContactData('fullName',
            event.target.value)}
          value={contactData?.fullName || ''}
          required
        />

        <InputMask
          type={'text'}
          mask="+380 \ 99 999 99 99"
          maskChar=" "
          placeholder="Введіть номер"
          className={'contact-us__form-input'}
          value={contactData?.phoneNumber || ''}
          onChange={(event) => updateContactData('phoneNumber',
            event.target.value)}
        />

        <textarea
          name=""
          id=""
          rows="5"
          className="contact-us__form-input"
          placeholder="Повідомлення:"
          value={contactData?.comment || ''}
          onChange={(event) => updateContactData('comment',
            event.target.value)}
          required
        ></textarea>

        <button
          type={'submit'}
          className="contact-us__form-button"
        >
          Надіслати заявку
        </button>
      </form>
    </div>
  );
  //#endregion
};
