import React, {useState, useEffect, useRef, useContext} from 'react';
import {AppContext} from "../../context/appContext";
import {getContactById} from "../../utils/helpers";
import ChatInput from "../ChatInput/ChatInput";
import ChatConversation from "../ChatConversation/ChatConversation";
import './UserChat.scss';

function UserChat({contactsList}) {
  //#region Get active contact id
  const {activeChatUserId} = useContext(AppContext);
  //#endregion

  //#region Messages and list of messages change
  const [chatMessages, setChatMessages] = useState([]);

  useEffect(() => {
    const curContact = getContactById(contactsList, activeChatUserId);
    if (curContact) {
      setChatMessages(curContact.messages || []);
      setContactName(curContact.fullName);
    } else {
      setContactName('');
      setChatMessages([]);
    }
  }, [activeChatUserId, contactsList]);
  //#endregion

  //#region Ref
  const myRef = useRef();

  function scrolltoBottom() {
    if (myRef.current) {
      myRef.current.scrollTop = myRef.current.scrollHeight;
    }
  }

  //#endregion

  //#region Messages list scroll
  useEffect(() => {
    scrolltoBottom();
  }, [chatMessages]);
  //#endregion

  //#region Check who sent the message
  const checkIsSender = (chat) => {
    return chat?.sender?.toString() === 'admin';
  };
  //#endregion

  //#region Get contact name
  const [contactName, setContactName] = useState('');
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={'user-chat-container'}>
        {activeChatUserId &&
          <h3 className={'user-chat-header'}>Повідомлення</h3>
        }

        <div className={'admin-chat__container'} ref={myRef}>
          {activeChatUserId ?
            <>
              <ChatConversation
                chatMessages={chatMessages}
                checkIsSender={checkIsSender}
                isAdmin={true}
                contactName={contactName}
              />
            </>
            :
            <div className={'admin-chat__empty-chat'}>
              Оберіть контакт для відображення чату
            </div>
          }
        </div>

        {activeChatUserId &&
          <ChatInput/>
        }
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default UserChat;

