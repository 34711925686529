import React from 'react';
import {CustomButton} from "../../components/CustomButton/CustomButton";
import './Page404.scss';


function Page404() {
    return (
      <div className="page-404">
        <div className="page-404__code">
          404
        </div>

        <div className="page-404__text">
          <p>УПС!</p>
          <p>
            Здається, ми не можемо
            <br/>
            знайти таку сторінку...😢
          </p>
        </div>

        <CustomButton
          content="Повернутися на головну"
          linkTo={'/'}
        />
      </div>
    );
}

export default Page404;
