import React, {useState} from 'react';
import {getMessageTime, truncateString} from "../../utils/helpers";
import './DisplayContactsWithMessages.scss';

const DisplayContactsWithMessages = ({contactsList, openUserChat}) => {
  //#region Get active chat user and set function from context
  const {activeChatUserId} = useState('');
  //#enregion

  const sortContactsList = (contactsList) => {
    const contactsCopy = [...contactsList];

    contactsCopy.sort((contact1, contact2) => {
      if (contact1?.messages?.length > 0 && contact2?.messages?.length > 0) {
        let contact1LastMes = contact1?.messages[contact1.messages.length - 1];
        let contact2LastMes = contact2?.messages[contact2.messages.length - 1];
        if (contact1LastMes?.timestamp > contact2LastMes?.timestamp) {
          return -1;
        }
      }

      return 0;
    });

    return contactsCopy;
  };
  //#endregion

  //#region Check is new message
  const checkIsNewMessage = (contact) => {
    if (contact?.messages?.length > 0) {
      const lastMessage = contact?.messages?.[contact.messages.length - 1];

      if (lastMessage?.sender !== 'admin') {
        return true;
      }
    }

    return false;
  }
  //#endregion

  //#region Render
  return (
    <>
      {contactsList.length === 0 &&
        <ul className='contacts-list'>
          <li className={'contacts-list__item'}>
            <div className={'contacts-list__content contact-data'}>
              <div className={'contact-data__avatar-text'}>
                <span>
                  {'N'}
                </span>
              </div>

              <div className={'contacts-list__no-contacts'}>
                {'Контакти відсутні'}
              </div>
            </div>
          </li>
        </ul>
      }

      <ul className={'contacts-list'} id="chat-list">
        {sortContactsList(contactsList).map((chat, key) =>
          <li
            key={key} id={"conversation" + key}
            className={key === activeChatUserId ? 'contacts-list__item ' +
              'contacts-list__item--active' : 'contacts-list__item'}
          >
            <div
              className={'contacts-list__content contact-data'}
              onClick={(e) => openUserChat(e, chat)}
            >
              <div className={'contact-data__avatar-with-name'}>
                {!chat.photoUrl ?
                  <div className={'contact-data__avatar-text'}>
                    <span>
                      {chat.fullName.charAt(0).toUpperCase()}
                    </span>
                  </div>
                  :
                  <div className={'contact-data__avatar-image'}>
                    <img
                      src={chat.photoUrl}
                      className={'contact-avatar-image'}
                      alt="avatar"
                    />
                  </div>
                }

                <div className={'contact-data__text'}>
                  <h5 className={'contact-data__name'}>
                    {truncateString(chat.fullName, 10)}
                  </h5>
                  <h5
                    className={'contact-data__name contact-data__name--mobile'}>
                    {truncateString(chat.fullName, 6)}
                  </h5>

                  <p className={'contact-data__message'}>
                    <>
                      {
                        chat.messages && (chat.messages.length > 0 && chat
                          .messages[(chat.messages).length - 1].isImageMessage === true) ?
                          <i
                            className="ri-image-fill align-middle me-1"
                          ></i> : null
                      }
                      {
                        chat.messages && (chat.messages.length > 0 && chat
                          .messages[(chat.messages).length - 1]
                          .isFileMessage === true) ?
                          <i
                            className="ri-file-text-fill align-middle me-1"
                          ></i> : null
                      }
                      {
                        chat.messages && chat.messages.length > 0
                          ? truncateString(chat.messages[(chat.messages)
                            .length - 1].messageText, 16) : null
                      }
                    </>
                  </p>

                  <p
                    className={'contact-data__message contact-data__message--mobile'}>
                    <>
                      {
                        chat.messages && (chat.messages.length > 0 && chat
                          .messages[(chat.messages).length - 1]
                          .isImageMessage === true) ?
                          <i
                            className="ri-image-fill align-middle me-1"
                          ></i> : null
                      }
                      {
                        chat.messages && (chat.messages.length > 0 && chat
                          .messages[(chat.messages).length - 1]
                          .isFileMessage === true) ?
                          <i
                            className="ri-file-text-fill align-middle me-1"
                          ></i> : null
                      }
                      {
                        chat.messages && chat.messages.length > 0
                          ? truncateString(chat.messages[(chat.messages)
                            .length - 1].messageText, 6) : null
                      }
                    </>
                  </p>
                </div>
              </div>

              <div className={'contact-data__right'}>
                <div className={'contact-data__new-message'}>
                  {checkIsNewMessage(chat) ?
                    <span> {'new'} </span> : ''
                  }
                </div>
                <div className={'contact-data__time'}>
                  {chat.messages && chat.messages.length > 0
                    ? getMessageTime(chat.messages[(chat.messages)
                      .length - 1]) : null}
                </div>
              </div>
            </div>
          </li>
        )}
      </ul>
    </>
  );
  //#endregion
};

export default DisplayContactsWithMessages;
