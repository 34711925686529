import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppProvider} from "./context/appContext";
import {BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import './index.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Helmet>
      <meta charSet="utf-8"/>
      <meta
        name="viewport"
        content="width=device-width,
        initial-scale=1,
        maximum-scale=1,
        user-scalable=0"
      />
    </Helmet>

    <BrowserRouter basename={'/'}>
      <AppProvider>
        <App/>
      </AppProvider>
    </BrowserRouter>
  </>
);

reportWebVitals();
