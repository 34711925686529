import React, {useContext} from 'react';
import ImageList from "../ImageList/ImageList";
import FileList from "../FileList/FileList";
import {AppContext} from "../../context/appContext";
import {format} from "date-fns";
import './ChatConversation.scss';

const ChatConversation = ({
  chatMessages,
  checkIsSender,
  isAdmin = false,
  contactName = '',
  isAuthorized,
}) => {
  //#region Get user and its setting function from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div
        className="chat-conversation"
        id="messages"
      >
        <ul className="list-unstyled mb-0">
          {chatMessages?.map((chat, key) =>
            <li
              key={key}
              className={checkIsSender(chat)
                ? 'right'
                : ''
              }
            >
              <div className="conversation-list">
                {chatMessages[key + 1] ?
                  (checkIsSender(chatMessages[key])
                    === checkIsSender(chatMessages[key + 1])
                      ?
                      <div
                        className="conversation-list__chat-avatar chat-avatar"
                      >
                        <div className="blank-div"></div>
                      </div>
                      :
                      <div
                        className={isAuthorized
                          ? 'conversation-list__chat-avatar chat-avatar'
                          : 'conversation-list__chat-avatar chat-avatar ' +
                          'chat-avatar--unauth'
                        }
                      >
                        {checkIsSender(chat) ?
                          (user?.photoUrl ?
                              <img
                                src={user?.photoUrl}
                                alt="Avatar"
                                className={
                                  isAuthorized ? 'chat-avatar__image'
                                    : 'chat-avatar__image ' +
                                    'chat-avatar__image--unauth'
                                }
                              />
                              :
                              <div
                                className={
                                  isAuthorized ? 'chat-avatar__text'
                                  : 'chat-avatar__text chat-avatar__text--unauth'
                                }
                              >
                                <span>
                                  {user?.fullName && user?.fullName.charAt(0)
                                    .toUpperCase()}
                                </span>
                              </div>
                          ) : (isAdmin ?
                            <>
                              {chat?.photoUrl ?
                                <img
                                  src={chat?.photoUrl}
                                  alt="Avatar"
                                  className={
                                    isAuthorized ? 'chat-avatar__image'
                                    : 'chat-avatar__image ' +
                                      'chat-avatar__image--unauth'
                                  }
                                />
                                :
                                <div
                                  className={
                                    isAuthorized ? 'chat-avatar__text'
                                    : 'chat-avatar__text ' +
                                      'chat-avatar__text--unauth'
                                  }
                                >
                                  <span>
                                    {contactName && contactName.charAt(0)
                                      .toUpperCase()}
                                  </span>
                                </div>
                              }
                            </>
                            :
                            <img
                              src={'./assets/images/admin-avatar.png'}
                              alt="Avatar"
                              className={
                                isAuthorized ? 'chat-avatar'
                                : 'chat-avatar--unauth'
                              }
                            />)}
                      </div>
                  ) : (
                    <div
                      className={isAuthorized
                        ? 'conversation-list__chat-avatar chat-avatar'
                        : 'conversation-list__chat-avatar chat-avatar ' +
                        'chat-avatar--unauth'
                      }
                    >
                      {checkIsSender(chat) ?
                        (user?.photoUrl ?
                            (<img
                                src={user?.photoUrl}
                                alt="Avatar"
                                className={isAuthorized ? 'chat-avatar'
                                  : 'chat-avatar chat-avatar--unauth'}
                              />
                            ) : (
                              <div
                                className={
                                  isAuthorized ? 'chat-avatar__text'
                                  : 'chat-avatar__text chat-avatar__text--unauth'
                                }
                              >
                                <span>
                                  {user?.fullName.charAt(0).toUpperCase()}
                                </span>
                              </div>)
                        ) : (isAdmin ?
                          <>
                            {chat?.photoUrl ?
                              <img
                                src={chat?.photoUrl}
                                alt="Avatar"
                                className={isAuthorized ? 'chat-avatar__image'
                                  : 'chat-avatar__image ' +
                                  'chat-avatar__image--unauth'}
                              />
                              :
                              <div
                                className={isAuthorized
                                  ? 'chat-avatar__text'
                                  : 'chat-avatar__text chat-avatar__text--unauth'
                                }
                              >
                                <span>
                                  {contactName && contactName.charAt(0)
                                    .toUpperCase()}
                                </span>
                              </div>
                            }
                          </>
                          :
                          <img
                            src={'./assets/images/admin-avatar.png'}
                            alt="Avatar"
                            className={
                              isAuthorized ? 'chat-avatar'
                              : 'chat-avatar chat-avatar--unauth'
                            }
                          />)}
                    </div>)}

                <div
                  className={isAuthorized
                    ? 'user-chat-content'
                    : 'user-chat-content user-chat-content--unauth'
                  }
                >
                  <div className={isAuthorized
                    ? 'ctext-wrap'
                    : 'ctext-wrap ctext-wrap--unauth'
                    }
                  >
                    <div
                      className={isAuthorized
                        ? 'ctext-wrap-content'
                        : 'ctext-wrap-content ctext-wrap-content--unauth'
                      }
                    >
                      {chat?.messageText &&
                        <p className={isAuthorized
                          ? 'mb-0'
                          : 'mb-0 chat-conversation__main-text'
                          }
                        >
                          {chat?.messageText}
                        </p>
                      }

                      {chat?.imageMessage &&
                        <ImageList images={chat.imageMessage}/>}

                      {chat?.fileMessage &&
                        <FileList
                          fileName={chat.fileMessage}
                          fileSize={chat.size}
                        />
                      }

                      {<p className="chat-time">
                        {chat?.timestamp &&
                          <span>
                            {format(new Date(Number(chat.timestamp)),
                              'dd.MM.yy HH:mm')}
                          </span>}
                      </p>}
                    </div>
                  </div>

                  {chatMessages[key + 1] ?
                    checkIsSender(chatMessages[key])
                    === checkIsSender(chatMessages[key + 1]) ? null : (
                      <div className="conversation-name">
                        {checkIsSender(chat) ?
                          user?.fullName : (isAdmin ? contactName : 'Admin')}
                      </div>
                    ) : (
                      <div className="conversation-name">{checkIsSender(chat)
                        ? user?.fullName : (isAdmin ? contactName : 'Admin')}
                      </div>
                    )}
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default ChatConversation;
