import React, { useRef, useState } from "react";
import './VideoBlock.scss';
import classNames from 'classnames/bind';

import { CustomButton } from "../CustomButton/CustomButton";
import {videoLink} from "../../utils/models";
import ModalOrderTickets from "../../modals/ModalOrderTickets/ModalOrderTickets";

export const VideoBlock = () => {
  const videoRef = useRef(null);
  const [videoPlay, setVideoPlay] = useState(false);
  const [videoMute, setVideoMute] = useState(true);
  const blur = videoPlay ? "blur(0)" : "blur(5px)";

  //#region Manage tickets order modal
  const [showModal, setShowModal] = useState(false);

  const handleOrderTicketsClick = () => {
    window.dataLayer.push({'event':'ticket_request'});
    setShowModal(true);
  };
  //#endregion

  //#region Render
  return (
    <div className="videoblock">
      <div className="videoblock__content">
        <h2 className="videoblock__title">
          Щоденні прямі рейси з Естонії, Литви, Латвії без посередників та
          предоплат за квиток
        </h2>

        <p className="videoblock__text">
          Здійснюємо щоденні поїздки до Естонії, Литви, Латвії.
          <br/>
          Ми відповідаємо за комфорт, сервіс та
          безпеку наших пасажирів.
          <br/>
          На відео наш транспорт.
        </p>

        <div className="videoblock__button">
          <CustomButton
            content="Замовити"
            onClick={() => handleOrderTicketsClick()}
          />
        </div>
      </div>

      <div
        className="videoblock__player"
      >
        <video
          className="videoblock__video"
          src={videoLink}
          id="video"
          ref={videoRef}
          onPlay={() => setVideoPlay(true)}
          onPause={() => setVideoPlay(false)}
          onEnded={() => setVideoPlay(false)}
          muted={videoMute}
        >
        </video>

        <div
          className={classNames(
            "videoblock__player-mute",
            {"videoblock__player-mute-active": videoMute}
          )}
          onClick={() => {
            setVideoMute(!videoMute)
          }}
        >
        </div>

        <div
          className="videoblock__mask"
          onClick={(e) => {
            videoRef.current?.pause();
          }}
          style={{backdropFilter: blur}}
        >
        </div>

        {videoPlay || (
          <>
            <button
              className="videoblock__control"
              onClick={() => {
                videoRef.current?.play();

              }}
            >
            </button>
          </>
        )}
      </div>


      {showModal &&
        <ModalOrderTickets
          showModal={showModal}
          setShowModal={setShowModal}
        />
      }
    </div>
  );
  //#endregion
};
