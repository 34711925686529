import React from "react";
import { FeedbackItem } from "../FeedbackItem/FeedbackItem";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Feedbacks.scss';
import {SocialBar} from "../SocialBar/SocialBar";

const feedBack = [
  // {
  //   clientID: "client1",
  //   clientName: "Оля Кравченко",
  //   clientCity: "Львів",
  //   clientRate: 5,
  //   clientContent: "Дякую за подорож! Все пройшло відмінно! Подорож була " +
  //     "зручною, тому що Ви та обіцяли спасибі!!"
  // },
  // {
  //   clientID: "client2",
  //   clientName: "Олександра Виноградова",
  //   clientCity: "Київ",
  //   clientRate: 5,
  //   clientContent: "Дякую вам за хорошого водія, який сьогодні віз Київ - " +
  //     "Варшава. Водій від Бога. Ввічливий, уважний і дуже гарна людина."
  // },
  {
    clientID: "client3",
    clientName: "Ілля Фоменко",
    clientCity: "Київ",
    clientRate: 5,
    clientContent: "Низький уклін усім водіям та керівникам. Вони Люди з великої " +
      "літери, таких людей на нашій землі дуже мало."
  },
  // {
  //   clientID: "client4",
  //   clientName: "Інна Гуменюк",
  //   clientCity: "Чернівці",
  //   clientRate: 4,
  //   clientContent: "Їздила в Латвію з дитиною 5 років, нервувалася трішки але " +
  //     "все пройшло дуже добре та спокійно!. Сидіння комфортні - не було ніякої " +
  //     "\"морської хвороби\". Дякую вам!!!"
  // },
  {
    clientID: "client5",
    clientName: "Матушевська Іванна",
    clientCity: "Рівне",
    clientRate: 5,
    clientContent: "Їхала цим автобусом з Рівного до Таліна. Автобус чистий, " +
      "водій був ввічливий та уважний до всіх пасажирів. В автобусі було тепло. " +
      "Перед тим їхала іншими перевізниками, то вони навіть автобус не " +
      "обігрівали, змерзла. А цей сподобався. Є з чим порівняти."
  },
  {
    clientID: "client6",
    clientName: "Володимир Синкевич",
    clientCity: "Київ",
    clientRate: 4,
    clientContent: "Висловлюємо щирі слова подяки sem-bus. З вами комфортно і " +
      "безпечно! Привітні і ввічливі водії, комфортабельні автобуси! Рекомендую!"
  },
];

const socialsList = ["fb", "insta"];

export const Feedbacks = () => {
  //#region Render
  return (
    <div className="feedbacks">
      <h1 className="feedbacks__title">
        Відгуки наших клієнтів
      </h1>

      <SocialBar
        isLarge={true}
        socialsList={socialsList}
      />
      {/*#region CAROUSEL*/}
      {/*<div className="feedbacks__slider">*/}
      {/*    <Swiper*/}
      {/*      slidesPerView={1}*/}
      {/*      breakpoints={{*/}
      {/*          1024: {*/}
      {/*            slidesPerView: 3*/}
      {/*          }*/}
      {/*        }}*/}
      {/*      loop={true}*/}
      {/*    >*/}
      {/*      {feedBack.map((cl, i) => (*/}
      {/*        <SwiperSlide*/}
      {/*          key={`${cl.clientName.substring(0, 3)}-${i}`}*/}
      {/*          className="feedbacks__slider-item"*/}
      {/*        >*/}
      {/*          <FeedbackItem*/}
      {/*            photo={cl.clientID}*/}
      {/*            name={cl.clientName}*/}
      {/*            city={cl.clientCity}*/}
      {/*            rate={cl.clientRate}*/}
      {/*            content={cl.clientContent}*/}
      {/*          />*/}
      {/*        </SwiperSlide>*/}
      {/*      ))}*/}
      {/*    </Swiper>*/}
      {/*  </div>*/}
      {/*#end region*/}
    </div>
  );
  //#endregion
};
