import React from 'react';
import './RaceDetails.scss';

import {RxDotFilled} from "react-icons/rx";

const details = [
  {
    id: "return",
    title: "Умови повернення",
    description: (<>
      <p><RxDotFilled/>
        "Квити бронюються без предоплати!"
      </p>
    </>)
  },
  {
    id: "bag",
    title: "Умови перевезення багажу",
    description: (
      <>
        <p>
          <RxDotFilled/>

          Кожен пасажир має право на перевезення 1 місця багажу розміром: 45x65x80,
          загальною вагою до 31кг на 1 особу та ручного багажу вагою до 5 кг.
          <br/>
          (Умови стосуються транспорту від 40- 80 місць, умови перевезення багажу
          відрізняються в залежності від виду транспортного засобу.)
        </p>

        <p>
          <RxDotFilled/>

          Якщо це мікроавтобус на 8-16 місць , то умови перевезення багажу
          індивідуальні і обговорюються з диспетчером.
        </p>

        <p>
          <RxDotFilled/>

          При перевищенні даних лімітів щодо ваги і розмірів за багаж береться
          доплата як за додатковий багаж.
        </p>

        <p>
          <RxDotFilled/>

          Додатковий багаж перевозиться при наявності вільного місця в багажному
          відділенні автобуса, за який береться оплата.
        </p>

        <p>
          <RxDotFilled/>

          Не перевозиться багаж негабаритних розмірів (холодильники і т.п.).
          Перевізник не відповідає за ручний багаж.
        </p>

        <p>
          <RxDotFilled/>

          Перевізник несе відповідальність за знищений або пошкоджений багаж у
          багажному відділенні автобуса тільки якщо це сталося з вини перевізника,
          що доведено пасажиром, але не більше ніж на суму 100 Євро на особу за
          умови подання заяви не пізніше ніж 10 днів з дня виїзду автобуса.
        </p>

        <p>
          <RxDotFilled/>

          Заборонено перевозити багаж, що загрожує безпеці або здоров'ю інших
          пасажирів.
        </p>

        <p>
          <RxDotFilled/>

          Перевізник не відповідає за втрату пасажиром грошей, біжутерії, паспорта,
          цінних паперів, колекційних речей, речей, які мають наукову цінність та
          інших цінностей, що не знаходяться у багажному відділенні автобуса.
        </p>

        <p>
          <RxDotFilled/>

          Пасажир особисто несе відповідальність за легальність власного багажу.
        </p>

        <p>
          <RxDotFilled/>

          При ускладненнях на кордоні фірма-перевізник має право відмовитися від
          виконання обов'язків по перевезенню багажу і продовжити рейс без
          вказаного багажу.
        </p>
      </>
    )
  },
  // {
  //   id: "tag",
  //   title: "Знижки",
  //   description: (
  //     <>
  //       <p>
  //         <RxDotFilled/>
  //
  //         Діти 0-6р: -45%
  //       </p>
  //
  //       <p>
  //         <RxDotFilled/>
  //
  //         Діти 6-15р: -20%
  //       </p>
  //
  //       <p>
  //         <RxDotFilled/>
  //
  //         Люди похилого віку: -10%
  //       </p>
  //
  //       <p>
  //         <RxDotFilled/>
  //
  //         Люди з обмеженими можливостями: -10%
  //         <br/>
  //         (Узгоджувати з диспетчером)
  //       </p>
  //     </>
  //   )
  // },
]

const RaceDetails = ({addClasses = ''}) => {
  //#region Render
  return (
    <div className={`race-details ${addClasses}`}>

      {details.map((det, index) => (
        <div
          key={index}
          className={'race-details__item details-card'}
        >
          <div className={'card-title details-card__title'}>
            <img
              alt={`${det.id} icon`}
              src={`assets/images/${det.id}-icon.svg`}
              className={'card-title__icon'}
            />
            <span className={'card-title__text'}>
                {det.title}
              </span>
          </div>
          <div className={'card-text details-card__text'}>
            <span>
                {det.description}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
  //#endregion
};

export default RaceDetails;
