import React, {useState} from "react";
import {BenefitsListItem} from "../BenefitsListItem/BenefitsListItem";
import './Benefits.scss';

const listItems = [
  "Виконуємо перевезення до країн Балтії",
  "Досвідчені водії зі стажем, які залишать після себе приємне враження",
  "В авто 2, або 3 водії",
  "Тільки Сучасні комфортабельні  автобуси  SETRA Euro 5, SETRA Euro 6 на один , або два поверхи!",
  "Діє система знижок для людей з обмеженими можливостями, дітей та літніх людей",
  "Працюємо без передоплат та посередників, оплата здійснюється при посадці в автобус або на нашому сайті",
  "Приємні стюардеси",
  "Безкоштовні напої  чай, кава , вода  і т.ін.",
  "У всіх автобусах завжди працючі  туалети",
  "На бортах наших автобусів встановлені STARLINK , тому інтернет працює на" +
  " кожному відрізку дороги",
  "У вигляді бонусу компанія SEM BUS пропонує безкоштовні трансфери від" +
  " автобуса до вказаної адреси на комфортабельних мікроавтобусах мерседес," +
  " фольксваген! (Потрібно уточняти у диспетчера)",
];

export const Benefits = () => {
  const [benefit, setBenefit] = useState("like");

  return (
    <div className="benefits">
      <div className="benefits__visual">

        <div className="benefits__visual-img">
          <img
            src={`./assets/images/benefits-${benefit}.webp`}
            alt="main-benefit"
            className="benefits__visual-img-main"
          />
        </div>

        <ul className="benefits__visual-list">
          <BenefitsListItem
            content="Популярне"
            icon="like"
            setBenefit={setBenefit}
          />
          <BenefitsListItem
            content="USB-зарядки, розетки 220 вольт"
            icon="usb"
            setBenefit={setBenefit}
          />
          <BenefitsListItem
            content="Зручні сидіння"
            icon="seats"
            setBenefit={setBenefit}
          />
          <BenefitsListItem
            content="Кондиціонер"
            icon="conditioner"
            setBenefit={setBenefit}
          />
          <BenefitsListItem
            content="Туалети"
            icon="wc"
            setBenefit={setBenefit}
          />
          <BenefitsListItem
            content="WIFI, є Starlink"
            icon="wifi"
            setBenefit={setBenefit}
          />
          {/*<BenefitsListItem*/}
          {/*  content="Стюардеса"*/}
          {/*  icon="stuard"*/}
          {/*  setBenefit={setBenefit}*/}
          {/*/>*/}
          {/*<BenefitsListItem*/}
          {/*  content="Безкоштовні напої на борту"*/}
          {/*  icon="beverages"*/}
          {/*  setBenefit={setBenefit}*/}
          {/*/>*/}
        </ul>
      </div>

      <div className="benefits__text">
        <h1 className="benefits__text-title">
          Компанія SEM BUS
        </h1>

        <h4 className="benefits__text-subtitle mb-2">
          здійснює щоденні поїздки до Естонії, Литви, Латвії.
          Ми відповідаємо за комфорт, сервіс та безпеку наших пасажирів.
        </h4>

        <h4 className="benefits__text-listtitle">
          Наші особливості:
        </h4>

        <ul className="benefits__text-list">
          {listItems.map((item, i) => (
            <li
              className="benefits__text-list-item"
              key={i}
            >
              {`${item};`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
