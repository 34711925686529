import React, {useEffect, useContext, useState} from 'react';
import {AppContext} from "../../context/appContext";
import {Link} from "react-router-dom";
import {collectionsInterface, userModel} from "../../utils/models";
import {truncateString} from "../../utils/helpers";
import {auth, updateFieldInDocumentInCollection} from "../../utils/firebaseConfigAndFunctions";
import ModalReAuth from "../../modals/ModalReAuth/ModalReAuth";
import InputMask from "react-input-mask";
import ModalNotification from "../../modals/ModalNotification/ModalNotification";
import ModalDeleteProfile from "../../modals/ModalDeleteProfile/ModalDeleteProfile";
import './ProfileInfo.scss';

const ProfileInfo = ({addClasses = ''}) => {
  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Manage edit modes
  const [isFullNameEditMode, setIsFullNameEditMode] = useState(false);
  const [isEmailEditMode, setIsEmailEditMode] = useState(false);
  const [isPhoneEditMode, setIsPhoneEditMode] = useState(false);
  const [isBirthdayEditMode, setIsBirthdayEditMode] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  //#endregion

  //#region Set and update userAccount data
  const [userAccountData, setUserAccountData] = useState(userModel);

  useEffect(() => {
    setUserAccountData(user);
  }, [user]);

  function updateAccountData(fieldName, newValue) {
    setUserAccountData(data => ({
      ...data,
      [fieldName]: newValue,
    }));
  }

  //#endregion

  //#region Notification
  const [notification, setNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Edit click handlers
  const handleEditEmailClick = () => {
    if (auth?.currentUser?.providerData[0]?.providerId === 'google.com') {
      setNotification(true);
      setNotificationTitle('Повідомлення');
      setNotificationMessage(
        'Не можна редагувати email, якщо авторизація через Google');

      setTimeout(() => {
        setNotification(false);
      }, 2000);

      return;
    }

    setIsEmailEditMode(true);
  }

  const handleEditPhoneClick = () => {
    if (auth?.currentUser?.providerData[0]?.providerId === 'phone') {
      setNotification(true);
      setNotificationTitle('Повідомлення');
      setNotificationMessage('Не можна редагувати телефон, якщо він ' +
        'використовується для авторизації');

      setTimeout(() => {
        setNotification(false);
      }, 2000);

      return;
    }

    setIsPhoneEditMode(true);
  }
  //#endregion

  //#region Update profile in DB
  const confirmProfileFieldEditing = async (fieldName) => {
    try {
      if (fieldName === 'email' && auth?.currentUser
        ?.providerData[0].providerId === 'password') {
        setShowReAuthModal(true);

        return;
      };

      await updateFieldInDocumentInCollection(collectionsInterface.users,
        user.idPost, fieldName, userAccountData[fieldName]);

      setUser(prevUser => ({
        ...prevUser,
        [fieldName]: userAccountData[fieldName],
      }));
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region Modal for reAuth
  const [showReAuthModal, setShowReAuthModal] = useState(false);
  //#endregion

  //#region Render
  return (
    <div className={`profile-info ${addClasses}`}>
      <div className={'avatar-with-text profile-info__avatar-with-text'}>
        <div className={'avatar-outside-container profile-info__avatar-outside-container'}>
          {user?.photoUrl ?
            <img
              src={user?.photoUrl}
              alt={'Avatar'}
              className={'avatar profile-info__avatar'}
            />
            :
            <div className={'avatar-anonym profile-info__avatar-anonym'}></div>
          }
        </div>

        <div className={'text-container profile-info__text-container'}>
          <div className={'text-container__name profile-info__name'}>
            {`${user?.fullName || 'not defined'} `}
          </div>
          <div className={'text-container__id profile-info__id'}>
            {`ID: ${user?.uid ? truncateString(user?.uid, 18) 
              : 'not defined'}`}
          </div>
        </div>
      </div>

      <div className={'profile-info__data profile-data'}>
        <div className={'profile-data__item'}>
          <div className={'profile-data__input-title'}>
            <span>
              Ім'я:
            </span>

            <div className={'profile-data__edit-on-mobile'}>
              {isFullNameEditMode ?
                <button
                  className={'profile-data__button'}
                  onClick={() => {
                    confirmProfileFieldEditing('fullName')
                      .then(r => setIsFullNameEditMode(false))
                      .catch(e => console.log(e));
                  }}
                >
                  <img src={'/assets/images/save-icon.svg'} alt={'save icon'}/>
                </button>
                :
                <button
                  className={'profile-data__button'}
                  onClick={() => setIsFullNameEditMode(true)}
                >
                  <img src={'/assets/images/edit.svg'} alt={'edit'}/>
                </button>
              }
            </div>
          </div>

          <input
            type={'text'}
            className={'regular-form-control profile-data__input'}
            placeholder="Введіть ім'я"
            value={userAccountData?.fullName || ''}
            onChange={(event) => updateAccountData('fullName',
              event.target.value)}
            disabled={!isFullNameEditMode}
          />

          <div className={'profile-data__edit-on-desktop'}>
            {isFullNameEditMode ?
              <button
                className={'profile-data__button'}
                onClick={() => {
                  confirmProfileFieldEditing('fullName')
                    .then(r => setIsFullNameEditMode(false))
                    .catch(e => console.log(e));
                }}
              >
                <img src={'/assets/images/save-icon.svg'} alt={'save icon'}/>
              </button>
              :
              <button
                className={'profile-data__button'}
                onClick={() => setIsFullNameEditMode(true)}
              >
                <img src={'/assets/images/edit.svg'} alt={'edit'}/>
              </button>
            }
          </div>
        </div>

        <div className={'profile-data__item'}>
          <div className={'profile-data__input-title'}>
            <span>
                Пошта:
            </span>

            <div className={'profile-data__edit-on-mobile'}>
              {isEmailEditMode ?
                <button
                  className={'profile-data__button'}
                  onClick={() => {
                    confirmProfileFieldEditing('email')
                      .then(r => setIsEmailEditMode(false))
                      .catch(e => console.log(e));
                  }}
                >
                  <img src={'/assets/images/save-icon.svg'} alt={'save icon'}/>
                </button>
                :
                <button
                  className={'profile-data__button'}
                  onClick={() => handleEditEmailClick()}
                >
                  <img src={'/assets/images/edit.svg'} alt={'edit'}/>
                </button>
              }
            </div>
          </div>

          <input
            type="email"
            className={'regular-form-control profile-data__input'}
            placeholder="Введіть пошту"
            value={userAccountData?.email || ''}
            onChange={(event) => updateAccountData('email',
              event.target.value)}
            disabled={!isEmailEditMode}
          />

          <div className={'profile-data__edit-on-desktop'}>
            {isEmailEditMode ?
              <button
                className={'profile-data__button'}
                onClick={() => {
                  confirmProfileFieldEditing('email')
                    .then(r => setIsEmailEditMode(false))
                    .catch(e => console.log(e));
                }}
              >
                <img src={'/assets/images/save-icon.svg'} alt={'save icon'}/>
              </button>
              :
              <button
                className={'profile-data__button'}
                onClick={() => handleEditEmailClick()}
              >
                <img src={'/assets/images/edit.svg'} alt={'edit'}/>
              </button>
            }
          </div>
        </div>

        <div className={'profile-data__item'}>
          <div className={'profile-data__input-title'}>
            <span>
                Телефон:
            </span>

            <div className={'profile-data__edit-on-mobile'}>
              {isPhoneEditMode ?
                <button
                  className={'profile-data__button'}
                  onClick={() => {
                    confirmProfileFieldEditing('phoneNumber')
                      .then(r => setIsPhoneEditMode(false))
                      .catch(e => console.log(e));
                  }}
                >
                  <img src={'/assets/images/save-icon.svg'} alt={'save icon'}/>
                </button>
                :
                <button
                  className={'profile-data__button'}
                  onClick={() => handleEditPhoneClick()}
                >
                  <img src={'/assets/images/edit.svg'} alt={'edit'}/>
                </button>
              }
            </div>
          </div>

          <InputMask
            type={'text'}
            mask="+380 \ 99 999 99 99"
            maskChar=" "
            placeholder="Введіть номер"
            className={'regular-form-control profile-data__input'}
            value={userAccountData?.phoneNumber || ''}
            onChange={(event) => updateAccountData('phoneNumber',
              event.target.value)}
            disabled={!isPhoneEditMode}
          />

          <div className={'profile-data__edit-on-desktop'}>
            {isPhoneEditMode ?
              <button
                className={'profile-data__button'}
                onClick={() => {
                  confirmProfileFieldEditing('phoneNumber')
                    .then(r => setIsPhoneEditMode(false))
                    .catch(e => console.log(e));
                }}
              >
                <img src={'/assets/images/save-icon.svg'} alt={'save icon'}/>
              </button>
              :
              <button
                className={'profile-data__button'}
                onClick={() => handleEditPhoneClick()}
              >
                <img src={'/assets/images/edit.svg'} alt={'edit'}/>
              </button>
            }

          </div>
        </div>

        <p className={'profile-data__optional-field'}>
          Це поле не обов'язкове для заповнення
        </p>

        <div className={'profile-data__item'}>
          <div className={'profile-data__input-title'}>
            <span>
              дд/мм/рр:
            </span>

            <div className={'profile-data__edit-on-mobile'}>
              {isBirthdayEditMode ?
                <>
                  <button
                    className={'profile-data__button'}
                    onClick={() => {
                      confirmProfileFieldEditing('birthDay')
                        .then(r => setIsBirthdayEditMode(false))
                        .catch(e => console.log(e));
                    }}
                  >
                    <img
                      src={'/assets/images/save-icon.svg'}
                      alt={'save icon'}
                    />
                  </button>
                </>
                :
                <>
                  <button
                    className={'profile-data__button'}
                    onClick={() => setIsBirthdayEditMode(true)}
                  >
                    <img src={'/assets/images/edit.svg'} alt={'edit'}/>
                  </button>
                </>
              }
            </div>
          </div>

          <input
            type={'date'}
            className={'regular-form-control profile-data__input'}
            value={userAccountData?.birthDay}
            onChange={(event) => updateAccountData('birthDay',
              event.target.value)}
            placeholder={'Оберіть дату'}
            disabled={!isBirthdayEditMode}
          />

          <div className={'profile-data__edit-on-desktop'}>
            {isBirthdayEditMode ?
              <>
                <button
                  className={'profile-data__button'}
                  onClick={() => {
                    confirmProfileFieldEditing('birthDay')
                      .then(r => setIsBirthdayEditMode(false))
                      .catch(e => console.log(e));
                  }}
                >
                  <img src={'/assets/images/save-icon.svg'} alt={'save icon'}/>
                </button>
              </>
              :
              <>
                <button
                  className={'profile-data__button'}
                  onClick={() => setIsBirthdayEditMode(true)}
                >
                  <img src={'/assets/images/edit.svg'} alt={'edit'}/>
                </button>
              </>
            }
          </div>
        </div>

        <div className={'profile-data__item'}>
          <div className={'profile-data__input-title'}>

          </div>

          <div className={'profile-data__input profile-data__link-container'}>
            <Link
              className={'profile-data__change-password-link'}
              to={'/changepassword'}
            >
              {'Змінити пароль'}
            </Link>
          </div>

          <div className={'profile-data__button'}>
          </div>
        </div>

        <div className={'profile-data__item'}>
          <div className={'profile-data__input-title'}>

          </div>

          <div className={'profile-data__input profile-data__link-container'}>
            <span
              onClick={() => setIsDeleteModalVisible(true)}
              className={
                'profile-data__change-password-link profile-data__delete'
              }
            >
              Видалити профіль
            </span>
          </div>

          <div className={'profile-data__button'}>
          </div>
        </div>
      </div>

      {isDeleteModalVisible &&
        <ModalDeleteProfile
          showModal={isDeleteModalVisible}
          setShowModal={setIsDeleteModalVisible}
          notificationTitle={notificationTitle}
          notificationText={notificationMessage}
        />
      }

      {showReAuthModal &&
        <ModalReAuth
          setShowModal={setShowReAuthModal}
          showModal={showReAuthModal}
          newEmail={userAccountData.email}
          setUserAccountData={setUserAccountData}
        />
      }

      {notification &&
        <ModalNotification
          showModal={notification}
          setShowModal={setNotification}
          notificationTitle={notificationTitle}
          notificationText={notificationMessage}
        />
      }
    </div>
  );
  //#endregion
};

export default ProfileInfo;
