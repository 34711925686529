import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {
  auth,
  confirmCodeAndSignInWithPhone,
  sendCodeToSignInWithPhone,
  signIn,
} from "../../utils/firebaseConfigAndFunctions";
import {signInWithGoogleButtonClickHandler} from "../../utils/helpers";
import {AppContext} from "../../context/appContext";
import InputMask from "react-input-mask";
import './LoginForm.scss';

const LoginForm = ({addClasses = ''}) => {
  //#region Get setUser function from context
  const {setUser} = useContext(AppContext);
  //#endregion

  //#region Handle errors
  const [error, setError] = useState('');

  const clearError = () => {
    setError('');
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        clearError();
      }, 5000);
    }
  }, [error]);
  //#endregion

  //#region Get history object
  const navigate = useNavigate();
  //#endregion

  //#region Phone auth
  const [phoneToAuth, setPhoneToAuth] = useState('');
  const [isFieldForCodeVisible, setIsFieldForCodeVisible] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState({});

  const signInViaPhoneNumber = async () => {
    try {
      await confirmCodeAndSignInWithPhone(confirmationResult, verificationCode,
        setUser);

      navigate('/profile');
    } catch (error) {
      console.log(error);
    }
  }
  //#endregion

  //#region Update credentials
  const [credentials, setCredentials] = useState({});

  function updateCredentials(fieldName, newValue) {
    setCredentials(data => ({
      ...data,
      [fieldName]: newValue,
    }));

  }

  //#endregion

  //#region Submit form and login user
  async function signInToApp(event, email, password) {
    event.preventDefault();

    try {
      await signIn(email, password);

      navigate('/profile');
    } catch (error) {
      console.log(error);
    }
  }

  //#endregion

  //#region Switch to phone auth and back, handle phone auth
  const [isPhoneAuth, setIsPhoneAuth] = useState(false);
  //#endregion

  //#region Render
  return (
    <>
      <div className={`regular-form login-form ${addClasses}`}>
        {!isPhoneAuth ?
          <form
            onSubmit={
              (event) => signInToApp(event,
                credentials.email, credentials.password)
            }
          >
            <h4
              className={'login-form__title'}
            >
              Вхід в особистий кабінет
            </h4>

            <input
              type="email"
              className={'regular-form-control login-form__item'}
              placeholder="Електронна пошта"
              value={credentials.email || ''}
              onChange={(event) => updateCredentials('email',
                event.target.value)}
              required
            />

            <input
              type="password"
              className={'regular-form-control login-form__item'}
              placeholder="Пароль"
              value={credentials.password || ''}
              onChange={(event) => updateCredentials('password',
                event.target.value)}
              required
            />

            <div className={'login-form__links-container'}>
              <Link to="/recoverpassword" className={'login-form__forget-link'}>
                Забули пароль?
              </Link>

              <Link to="/register" className={'login-form__register-link'}>
                Зареєструватися
              </Link>
            </div>

            <div className={'buttons-container'}>
              <button
                type="submit"
                className={'regular-button login-form__email-sign-in-button'}
              >
                Увійти
              </button>

              <button
                type={'button'}
                className={'regular-button button-with-icon ' +
                  'login-form__phone-sign-in-button'}
                onClick={() => setIsPhoneAuth(true)}
              >
                <span>
                  Вхід
                </span>

                <img
                  src={'assets/images/cell-phone-icon.svg'}
                  className={'phone-icon'}
                />
              </button>

              <button
                className={'regular-button button-with-icon ' +
                  'login-form__google-sign-in-button'}
                onClick={(event) => signInWithGoogleButtonClickHandler(event,
                  setUser, navigate)}
              >
                <span>
                  Вхід
                </span>

                <img
                  src={'assets/images/logo_google_g_icon.svg'}
                  className={'icon-in-button button-with-icon_icon'}
                />
              </button>
            </div>
          </form>
          :
          <div>
            <h4
              className={'login-form__title'}
            >
              Вхід в особистий кабінет
            </h4>

            <InputMask
              mask="+380 \ 99 999 99 99"
              maskChar=" "
              placeholder="Телефон"
              required
              className={'regular-form-control login-form__item'}
              onChange={(event => setPhoneToAuth(event.target.value))}
              disabled={isFieldForCodeVisible}
            />

            {!isFieldForCodeVisible &&
              <button
                onClick={() => sendCodeToSignInWithPhone(auth, phoneToAuth,
                  setIsFieldForCodeVisible, setConfirmationResult)}
                className={'regular-button login-form__item ' +
                  'login-form__send-code-button'}
              >
                Відправити код
              </button>
            }
            <div id={'recaptcha-container'}></div>

            {isFieldForCodeVisible &&
              <>
                <input
                  type={'text'}
                  className={'regular-form-control login-form__item'}
                  onChange={
                    (event) => setVerificationCode(event.target.value)
                  }
                />

                <button
                  onClick={() => signInViaPhoneNumber()} type={'button'}
                  className={'login-form__item regular-button ' +
                    'login-form__confirm-code-button'}
                >
                  Підтвердити код
                </button>
              </>
            }

            <button
              type={'button'}
              className={'regular-button login-form__go-back-button ' +
                'login-form__item'}
              onClick={() => setIsPhoneAuth(false)}
            >
              Назад
            </button>
          </div>
        }
      </div>
    </>
  );
  //#endregion
};

export default LoginForm;
