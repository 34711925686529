import React from "react";
import classNames from 'classnames';
import './SocialBar.scss';

import {socialsLink} from "../../utils/socialLinks";

export const SocialBar = ({
  socialsList,
  fullWidth=false,
  greyIcon = false,
  isLarge = false,
}) => {

  const isGrey = greyIcon ? "-gr" : "";

  return (
      <ul className={classNames("socials",
        {"socials-full-width": fullWidth},
      )}>
        {socialsList.map((soc, i) => (
          <li
            key={`${soc}-${i}`}
          >
            <a
              href={socialsLink[soc]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classNames("socials__img", {
                  "socials__img-large": isLarge
                })}
                src={`./assets/images/icons/icon-${soc}${isGrey}.svg`}
                alt="soc"
              />
            </a>
          </li>
        ))}
      </ul>
  );
}
