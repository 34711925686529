import {
  deleteCredentials, deleteDocumentFromCollectionWithID, facebookSignIn,
  getCollectionWhereKeyValue, googleSignIn, logOut, rdbCrm,
  setDocumentToCollection, setDocumentToCollectionInCrm,
} from "./firebaseConfigAndFunctions";
import {ref, onValue, child, get, set} from "firebase/database";
import {
  collectionsInterface,
  contactDynamicModel,
  userModel
} from "./models";
import firebase from "firebase/compat/app";
import {format} from "date-fns";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import {cities, trips} from "./busRoutes";
import {
  arrivingCitiesForCitySlider,
  departureCitiesForCitySlider
} from "./data";

//#region Parse dates
export function getDateWithoutTime(date) {
  if (date === undefined) {
    return 0;
  }

  try {
    const initialDate = new Date(date);

    return initialDate.getFullYear() + '-' + (initialDate.getMonth() + 1) + '-' + initialDate.getUTCDate();
  } catch (e) {
    return 0;
  }
}

//#endregion

//#region Work with user
export function createOrGetUser(regInfo, actionFunction = () => {
}) {
  // console.log('We are inside createNewUser');
  // console.log(regInfo);

  return new Promise(function (resolve, reject) {
    getCollectionWhereKeyValue('users', 'uid', regInfo.uid).then(r => {
      if (r.length === 0) {
        let user_to_firebase_start = {
          ...userModel,
          uid: regInfo?.uid || '',
          email: regInfo?.email || '',
          //password: regInfo.password,
          // gender: regInfo?.gender,
          // firstName: regInfo?.firstName,
          // lastName: regInfo?.lastName,
          fullName: regInfo?.fullName || '',
          phoneNumber: regInfo?.phoneNumber || [],
          photoUrl: regInfo?.photoUrl || '',
          isEmailVerified: regInfo?.isEmailVerified || false,
          dateCreating: format(new Date(), "dd-MM-yyyy HH:mm"),
          role: 'user',
        };

        setDocumentToCollection('users', user_to_firebase_start).then(r => {
          console.log('user saved in DB');
          localStorage.setItem('international-driver-eu', JSON.stringify(user_to_firebase_start));
          getCollectionWhereKeyValue(collectionsInterface.users, 'uid', regInfo.uid).then(res => {
            actionFunction(res[0]);
            setContactToRDB(false, res[0]).then(result => console.log(result))
              .catch(error => console.log(error));
          }).catch(error => console.log(error));
          resolve(r);
        }).catch(e => {
          reject(e)
        });
      } else {
        // console.log('We are inside returning user from DB');
        // console.log(r[0]);
        resolve(r[0]);
      }
    }).catch(e => {
      reject('')
    })
  })
};

export const registerUser = (username, email, password, actionFunction = () => {
}) => {
  // console.log('We are inside registerUser');
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
      console.log(firebase.auth().currentUser);
      const curUser = firebase.auth().currentUser;
      const regInfo = {
        ...userModel,
        uid: curUser.uid || '',
        email: curUser.email || '',
        fullName: username || '',
        isEmailVerified: curUser.emailVerified || false,
        phoneNumber: curUser.phoneNumber || '',
        photoURL: curUser.photoURL || '',
        role: 'user',
      };
      console.log(regInfo);
      createOrGetUser(regInfo, actionFunction).then(result => {
        console.log(result);
      }).catch(e => console.log(e));
      resolve(firebase.auth().currentUser);
    }, (error) => {
      console.log('Error occured in registerUser');
      reject(error);
    });
  });
};

export const signInWithGoogleButtonClickHandler = async (event, setUser, navigate) => {
  event.preventDefault()

  try {
    await googleSignIn(setUser);

    navigate('/profile');
  } catch (error) {
    console.log(error);
  }
};

export const signInWithFacebookButtonClickHandler = async (event, setUser, navigate) => {
  event.preventDefault()

  try {
    await facebookSignIn(setUser);

    navigate('/profile');
  } catch (error) {
    console.log(error);
  }
};

export const deleteAccount = async (authUser, userId, navigate = () => {
}) => {
  try {
    await deleteDocumentFromCollectionWithID(collectionsInterface.users,
      userId);

    await deleteCredentials(authUser);

    await logOut();

    navigate('/');
  } catch (error) {

  }
}
//#endregion

//#region Get message time
export const getMessageTime = (timeFromDb) => {
  let timestamp = Number(timeFromDb.timestamp);

  try {
    const timeToDisplay = format(new Date(timestamp), 'dd.MM.yy HH:mm');

    return timeToDisplay;
  } catch (error) {
    console.log(error);
    return '00:00';
  }
};
//#endregion

//#region Manage contacts list
export const getContactById = (contactsList, contactId) => {
  const contact = contactsList.find(cont => cont.id === contactId);

  if (contact) {
    return contact;
  } else {
    return contactDynamicModel;
  }
};

export const getAccountContactsList = async (accountId) => {
  // console.log('We are inside get account contact list');
  const dbRef = ref(rdbCrm);
  let allContacts = {};
  let contactsIds = [];
  let accountContacts = [];

  try {
    const snapshot = await get(child(dbRef, `contacts/`));
    if (snapshot.exists()) {
      allContacts = snapshot.val();
      // console.log(allContacts);
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.log(error);
  }

  try {
    const currentMessages = await getAccountMessages(accountId);

    if (currentMessages) {
      contactsIds = Object.keys(currentMessages);
    }

    contactsIds.forEach(id => {
      if (allContacts.hasOwnProperty(id)) {
        accountContacts.push({
          ...allContacts[id],
          id: id,
          messages: Object.values(currentMessages[id]),
        });
      }
    });
  } catch (error) {
    console.log(error);
  }

  return accountContacts.filter(contact => contact.hasOwnProperty('fullName'));
};
//#endregion

//#region Work with realtime database
export const listenToAccountMessages = (accountId, actionFunction = () => {
}, ...args) => {
  const messagesRef = ref(rdbCrm, `messages/admin/${accountId}`);

  onValue(messagesRef, (snapshot) => {
    // console.log('onValue triggered');
    // console.log(snapshot);

    const data = snapshot.val();

    // console.log(data);
    actionFunction(data, accountId, ...args);
  });

  return messagesRef;
};

export const listenToAllMessagesForAdmin = (actionFunction = () => {
}, ...args) => {
  const messagesRef = ref(rdbCrm, `messages/admin`);

  onValue(messagesRef, (snapshot) => {
    const data = snapshot.val();

    actionFunction(data, ...args);
  });

  return messagesRef;
}

export const getAccountMessages = async (accountId) => {
  const dbRef = ref(rdbCrm);
  let messages = {};

  try {
    let snapshot = await get(child(dbRef, `messages/${accountId}`));

    if (snapshot.exists()) {
      messages = snapshot.val();
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.log(error);
  }

  return messages;
};

export const getAccountMessagesAsArray = async (accountId) => {
  let messages;

  try {
    messages = await getAccountMessages(accountId);
  } catch (error) {
    console.log(error);
  }

  return messages ? Object.values(messages) : [];
};

export const getAccountMessagesWithExactContact = async (accountId, contactId) => {
  const dbRef = ref(rdbCrm);
  let messages = {};

  try {
    let snapshot = await get(child(dbRef, `messages/${accountId}/${contactId}`));
    if (snapshot.exists()) {
      messages = snapshot.val();
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.log(error);
  }

  return messages;
};

export const getAccountMessagesWithExactContactAsArray = async (accountId, contactId) => {
  let messages;

  try {
    messages = await getAccountMessagesWithExactContact(accountId, contactId);
  } catch (error) {
    console.log(error);
  }

  return messages ? Object.values(messages) : [];
};

export const getAdminContactsList = async (accountId) => {
  // console.log('We are inside get account contact list');
  const dbRef = ref(rdbCrm);
  let allContacts = {};
  let contactsIds = [];
  let accountContacts = [];

  try {
    const snapshot = await get(child(dbRef, `contacts/`));
    if (snapshot.exists()) {
      allContacts = snapshot.val();
      // console.log(allContacts);
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.log(error);
  }

  try {
    const currentMessages = await getAccountMessages(accountId);

    if (currentMessages) {
      contactsIds = Object.keys(currentMessages);
    }

    contactsIds.forEach(id => {
      if (allContacts.hasOwnProperty(id)) {
        accountContacts.push({
          ...allContacts[id],
          messages: Object.values(currentMessages[id]),
        });
      }
    });
  } catch (error) {
    console.log(error);
  }

  return accountContacts.filter(contact => contact.hasOwnProperty('fullName'));
};

export const setContactToRDB = async (isAnonym, user) => {
  let contactData = {};

  if (isAnonym) {
    contactData = {
      fullName: 'Anonym',
      id: user.id,
      photoUrl: '',
    }
  } else {
    contactData = {
      fullName: user.fullName,
      photoUrl: user.photoUrl,
      id: user.idPost,
    }
  }

  try {
    const rdbRes = await set(ref(rdbCrm, `contacts/${user.idPost}`), contactData);
  } catch (error) {
    console.log(error);
  }
}
//#endregion

//#region Send message via RDB
export const sendMessage = async (messageData, accountId, contactId, path = 'messages') => {
  try {
    await set(ref(rdbCrm, `${path}/${accountId}/${contactId}/m${messageData?.timestamp}`), messageData);
  } catch (error) {
    console.log(error);
  }
};
//#endregion

//#region Get user ip address
export const removeDotsFromIp = (ip) => {
  return ip.split('.').join('');
}

export const getIpNumbers = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  // console.log(res.data);
  // console.log(res.data.IPv4);
  // console.log(removeDotsFromIp(res.data.IPv4));

  return removeDotsFromIp(res.data.IPv4);
};
//#endregion

//#region Send message to telegram
// export const createTelegramMessageForContactForm = (client) => {
//     const messageText = `👤 ${client.fullName} 📞 ${client.phoneNumber}
//         ${client.comment ? '✉️ ' + client.comment : ''}`;
//
//     return messageText;
// };

export const createTelegramMessage = (client, trip) => {
  let messageText = '';
  if (trip) {
    messageText = `👤 ${client.fullName} 📞 ${client.phoneNumber} ${client.comment ? '✉️ ' + client.comment : ''} 
        📅 Виїзд: ${format(new Date(trip.departureDate), 'dd.MM.yyyy')} ${trip.directions === 'both' ?
      `📅 Повернення: ${format(new Date(trip.departureDate), 'dd.MM.yyyy')}` : ''} 👶 Дітей: ${trip.childrenAmount} 
        👨 Дорослих: ${trip.adultsAmount}`;
  } else {
    messageText = `👤 ${client.fullName} 📞 ${client.phoneNumber} ${client.comment ? '✉️ ' + client.comment : ''}`
  }

  return messageText;
};

export const sendTelegramMessage = async (botApi, chatId, messageData) => {
  try {
    await fetch(`https://api.telegram.org/bot${botApi}/sendMessage?chat_id=${chatId}&text=${messageData}`);
  } catch (error) {
    console.log(error);
  }
  ;
};

export const sendTelegramMessageToSomeRecipients = async (botApi, idsList, messageData) => {
  try {
    for (let i = 0; i < idsList.length; i++) {
      await fetch(`https://api.telegram.org/bot${botApi}/sendMessage?chat_id=${idsList[i]}&text=${messageData}`);
    }
  } catch (error) {
    console.log(error);
  }
  ;
};
//#endregion

//#region Save order to CRM
export const saveOrderToCrmDb = async (clientData, tripData, userId) => {
  try {
    const orderToSave = {
      clientFullName: clientData.fullName,
      clientId: userId || 'anonym-' + uuidv4(),
      clientPhone: clientData.phoneNumber,
      comment: clientData.comment,
      tripData,
    }

    await setDocumentToCollectionInCrm(collectionsInterface.orders, orderToSave);

    return true;
  } catch (error) {
    return error;
  }
}
//#endregion

//#region Truncate function
export const truncateString = (text, symbolsNumber) => {
  if (symbolsNumber >= text.length) {
    return text;
  }

  let newText = '';

  if (symbolsNumber < text.length + 1) {
    newText = `${text.slice(0, symbolsNumber)}...`
  } else {
    newText = text;
  }

  return newText
}
//#endregion

//#region Get data for cities slider
export const getTripsForCitiesSlider = () => {
  const dataForSlider = [];
  const tripsForSlider = [];

  departureCitiesForCitySlider.forEach(city => {
    const tripFromCity = trips.find(trip => trip.points[0]
      .cityName === city.cityName);

    if (tripFromCity) {
      tripFromCity.points.forEach(point => {
        if (arrivingCitiesForCitySlider.includes(point.cityName)) {
          const tripData = {
            from: city.city,
            to: 'city',
          };

          tripData.id = `${tripFromCity.id}${point.point}`;
          tripData.to = cities.find(curCity => curCity.cityName === point.cityName)
              ?.city || 'city';
          tripData.departure = point.time;
          tripData.minPrice = point.priceFromEntry;

          tripsForSlider.push(tripData)
        }
      })
    }

    // trips.forEach(trip => {
    //   if (trip.points[0].cityName === city.cityName) {
    //     tripData.from.departure = trip.points[0].time;
    //
    //     trip.points.forEach(point => {
    //       if (point.point && !tripData.to[point.cityData.country]) {
    //         tripData.to[point.cityData.country] = {
    //           departure: point.time,
    //           minPrice: point.priceFromEntry,
    //         }
    //       }
    //     });
    //   }
    // });
    //
    // dataForSlider.push(tripData);
  });

  return tripsForSlider;
}
//#endregion
