import React, {useState, useEffect, useRef, useContext} from 'react';
import {AppContext} from "../../context/appContext";
import {
  getIpNumbers,
  listenToAccountMessages,
  setContactToRDB
} from "../../utils/helpers";
import ChatInput from "../ChatInput/ChatInput";
import {sessionUserId, userModel} from "../../utils/models";
import {auth} from "../../utils/firebaseConfigAndFunctions";
import ChatConversation from "../ChatConversation/ChatConversation";
import './ClientChat.scss';

export const ClientChat = ({isAuthorized = true}) => {
  //#region Get user and its setting function from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Handle setting unauthorized user
  useEffect(() => {
    if (!auth.currentUser) {
      if (sessionStorage.getItem(sessionUserId)) {
        setUser({
          ...userModel,
          idPost: sessionStorage.getItem(sessionUserId),
          fullName: 'Anonym',
        });
      } else {
        let userId = '';

        getIpNumbers().then(res => {
          userId = res.toString() + Date.now().toString();

          sessionStorage.setItem(sessionUserId, userId);

          setUser({
            ...userModel,
            idPost: userId,
            fullName: 'Anonym',
          });

          setContactToRDB(true, {
            ...userModel,
            idPost: userId,
            id: userId,
          }).then(r => console.log(r)).catch(e => console.log(e));

        }).catch(error => console.log(error));
      }
    }
  }, []);
  //#endregion

  //#region Variable for listener unsubscribe
  const [unsubscribe, setUnsubscribe] = useState([]);
  //#endregion

  //#region Launch listener and set chatMessages
  const [chatMessages, setChatMessages] = useState([]);

  const updateChatMessages = (messagesObj) => {
    if (messagesObj) {
      const messagesList = Object.values(messagesObj);
      setChatMessages(messagesList);
    } else {
      setChatMessages([]);
    }
  }

  useEffect(() => {
    if (user?.idPost) {
      try {
        let curRef = listenToAccountMessages(user.idPost, updateChatMessages);

        setUnsubscribe(state => [...state, curRef]);
      } catch (error) {
        console.log(error);
      }
    }

    return () => {
      try {
        unsubscribe.forEach(unsubRef => {
          unsubRef.off();
        });
      } catch (error) {
        console.log(error);
      }

      setUnsubscribe([]);
    };
  }, [user]);
  //#endregion

  //#region Ref
  const myRef = useRef();

  function scrolltoBottom() {
    if (myRef.current) {
      myRef.current.scrollTop = myRef.current.scrollHeight;
    }
  }
  //#endregion

  //#region Messages list scroll
  useEffect(() => {
    scrolltoBottom();
  }, [chatMessages]);
  //#endregion

  //#region Check who sent the message
  const checkIsSender = (chat) => {
    return chat?.sender?.toString() !== 'admin';
  };
  //#endregion

  //#region Render
  return (
    <>
      <div
        className={isAuthorized
          ? 'client-chat'
          : 'client-chat client-chat--unauth'
        }
      >
        {!isAuthorized &&
          <div className={'decorative-line'}></div>
        }

        <h1
          className={isAuthorized
            ? 'client-chat__header'
            : 'client-chat__header client-chat__header--unauth'}
        >
          Нещодавні повідомлення
        </h1>

        <div
          className={isAuthorized
            ? 'client-chat__container'
            : 'client-chat__container client-chat__container--unauth'
          }
          ref={myRef}>
          <ChatConversation
            chatMessages={chatMessages}
            checkIsSender={checkIsSender}
            isAuthorized={isAuthorized}
          />
        </div>

        <ChatInput isAuthorized={false}/>
      </div>
    </>
  );
  //#endregion
};

export default ClientChat;

