import React, {useState, useContext} from 'react';
import {sendMessage} from "../../utils/helpers";
import {AppContext} from "../../context/appContext";
import {Form} from "reactstrap";
import './ChatInput.scss';

import TextareaAutosize from 'react-textarea-autosize';

function ChatInput({isAuthorized = true}) {
  //#region Get user from app context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get user from app context
  const {activeChatUserId} = useContext(AppContext);
  //#endregion

  //#region State with data
  const [textMessage, setTextMessage] = useState("");
  const [file, setFile] = useState({
    name: "",
    size: "",
  });
  const [fileImage, setFileImage] = useState({
    name: "",
    size: "",
  });
  //#endregion

  //#region Function for text input value change
  const handleChange = e => {
    setTextMessage(e.target.value)
  }
  //#endregion

  //#region Function for send message
  const addMessage = async (message, type, accountData, contactId) => {
    let curTime = Date.now();
    const messageObj = {
      messageText: message.messageText || '',
      timestamp: curTime,
      sender: accountData.idPost || '',
      senderLogin: accountData.login || '',
      recipientId: contactId || '',
      fileLink: message?.fileUrl || '',
      imageLink: message?.imageUrl || '',
      isTextMessage: !!message.messageText,
      isFileMessage: !!message.fileUrl,
      isImageMessage: !!message.imageUrl,
    };

    try {
      if (user.role === 'admin') {
        await sendMessage(messageObj, accountData.idPost, contactId,
          'messages');
      } else {
        await sendMessage(messageObj, contactId, accountData.idPost,
          'messages');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onaddMessage = async (e, textMessage) => {
    e.preventDefault();

    if (textMessage !== "") {
      try {
        if (user.role === 'admin') {
          await addMessage({
            messageText: textMessage,
          }, "textMessage", {idPost: 'admin'}, activeChatUserId);
        } else {
          await addMessage({
            messageText: textMessage,
          }, "textMessage", user, 'admin');
        }
      } catch (error) {
        console.log(error);
      }
    }

    clearMessages();
  };
  //#endregion

  //#region Reset message values if contact changes
  const clearMessages = () => {
    setTextMessage('');

    setFile({
      name: "",
      size: ""
    });

    setFileImage({
      name: "",
      size: ""
    });
  };
  //#endregion

  //#region Render
  return (
    <Form
      className={isAuthorized
        ? 'chat-input'
        : 'chat-input chat-input--unauth'}
      onSubmit={(e) => onaddMessage(e, textMessage)
      }>
      <div
        className={isAuthorized
          ? 'chat-input__field-wrap'
          : 'chat-input__field-wrap chat-input__field-wrap--unauth'
      }
      >

        <TextareaAutosize
          value={textMessage}
          onChange={handleChange}
          className={isAuthorized ? 'chat-input__field' : 'chat-input__field ' +
            'chat-input__field--unauth'}
          placeholder="Введіть повідомлення..."
          maxRows={6}
        />

        <span>
          {file?.name}
        </span>
        <span>
          {fileImage?.name}
        </span>
      </div>

      <button
        type={'submit'}
        className={isAuthorized
          ? 'chat-input__button'
          : 'chat-input__button chat-input__button--unauth'
      }
      >
      </button>
    </Form>
  );
  //#endregion
}

export default ChatInput;
