import React, {useContext, useEffect} from 'react';
import DisplayContactsWithMessages
  from "../DisplayContactsWithMessages/DisplayContactsWithMessages";
import {AppContext} from "../../context/appContext";
import './Chats.scss';


function Chats({contactsList}) {
  //#region Get active chat user and set function from context
  const {activeChatUserId, setActiveChatUserId} = useContext(AppContext);
  //#enregion

  //#region Set active css class for active chat
  useEffect(() => {
    const li = document.getElementById("conversation"
      + activeChatUserId);
    if (li) {
      li.classList.add("active");
    }
  }, []);
  //#endregion

  //#region Open chat with user
  function openUserChat(e, chat) {
    e.preventDefault();

    let id = chat.id;

    setActiveChatUserId(id);

    let chatList = document.getElementById("chat-list");
    let clickedItem = e.target;
    let currentli = null;

    if (chatList) {
      let li = chatList.getElementsByTagName("li");

      for (let i = 0; i < li.length; ++i) {
        if (li[i].classList.contains('active')) {
          li[i].classList.remove('active');
        }
      }

      for (let k = 0; k < li.length; ++k) {
        if (li[k].contains(clickedItem)) {
          currentli = li[k];
          break;
        }
      }
    }

    if (currentli) {
      currentli.classList.add('active');
    }

    let userChat = document.getElementsByClassName("user-chat");

    if (userChat[0]) {
      userChat[0].classList.add("user-chat-show");
    }

    let unread = document.getElementById("unRead" + chat.id);
    if (unread) {
      unread.style.display = "none";
    }
  };
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={'chats'}>
        <h4 className='chats__header'>
          Контакти
        </h4>

        <DisplayContactsWithMessages
          contactsList={contactsList}
          openUserChat={openUserChat}
        />
      </div>
    </React.Fragment>
  );
  //#endregion
}

export default Chats;
