import React, {useEffect} from "react";
import {CityCard} from "../CityCard/CityCard";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import './CitySlider.scss';

export const CitySlider = ({
  tripsForSlider
}) => {
  //#region Render
  return (
    <div className="city-slider">
      <Swiper
        slidesPerView={1}
        slidesPerColumn={1}
        breakpoints={{
          1024: {
            slidesPerView: 4,
          }
        }}
        loop={true}
      >
        {tripsForSlider.map(trip => (
          <SwiperSlide
            key={trip.id}
            className="city-slider__item-wrap"
          >
            <div className="city-slider__item">
              <CityCard
                trip={trip}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
  //#endregion
};
