import React from "react";

const TermsOfUse = () => (
  <div className='terms-of-use'>
    <h1>
      ПОЛОЖЕННЯ ТА УМОВИ ВИКОРИСТАННЯ SEM BUS
    </h1>

    <p>
      У цих умовах використання ви ("Користувач" або "Ви") матимете
      доступ до описів основних функцій платформи та основних умов
      використання її послуг. Інформація про особисті дані, зібрані під час
      використання платформи SEM BUS, доступна в нашій Політиці конфіденційності.
    </p>

    <p>
      Використовуючи платформу або послуги, пропоновані SEM BUS, користувач заявляє,
      що приймає та підпорядковується умовам цих умов. Якщо у вас є
      будь-які запитання щодо змісту цього документа, зв'яжіться з нами за
      електронною поштою sembus.director@gmail.com.
    </p>


    <p>
      <strong>1. ГЛОСАРІЙ</strong>
    </p>

    <p>
      1.1. Для полегшення розуміння ми використовуємо слова і терміни з першою
      літерою у верхньому регістрі. Щоразу, коли це зустрічається в умовах
      використання та в політиці конфіденційності, значення цих слів і
      умов слід розуміти так, як написано нижче:
    </p>

    <p>
      1.2. "Користувач": фізична особа, яка отримує доступ до платформи
      і виконує особисту реєстрацію для навігації по платформі та/або
      придбання продуктів і послуг, пропонованих SEM BUS, прямо приймаючи
      умови цього документа.
    </p>

    <p>
      1.3. "Платформа": Сайт і додаток, керовані SEM BUS за допомогою
      яких користувачеві надається інформація про розклад маршрутів
      автобусів між країнами, де представлена компанія, а також надано
      можливість забронювати квиток на обраний маршрут.
    </p>

    <p>
      <strong>2. ДОСТУП ТА ОСНОВНІ ПРАВИЛА ВИКОРИСТАННЯ ПЛАТФОРМИ</strong>
    </p>

    <p>
      2.1. Доступ:
    </p>

    <p>
      Більшість функцій платформи доступні користувачеві без реєстрації.
    </p>

    <p>
      Щоб отримати повний доступ до функцій платформи та мати можливість
      придбати послуги та/або продукти SEM BUS, користувач має бути старшим за
      18 років і повинен бути зареєстрованим. Доступ за допомогою реєстрації
      має на увазі прийняття прав і обов'язків, викладених у цьому документі.
    </p>

    <p>
      Реєстрація може бути виконана безпосередньо на платформі шляхом
      заповнення ваших особистих даних, запитаних SEM BUS і описаних у нашій
      політиці конфіденційності, або шляхом інтеграції платформи Google.
    </p>

    <p>
      Ваш доступ може бути призупинено або відхилено на власний розсуд SEM BUS.
      нагляду SEM BUS і без попереднього повідомлення, щоразу, коли
      виявляється будь-яке порушення в наданій інформації або в
      способі використання платформи.
    </p>

    <p>
      Користувач повинен надати правильні та точні дані для
      використання SEM BUS і повинен оновлювати їх.
    </p>

    <p>
      Користувач несе відповідальність за забезпечення безпеки свого
      пароля доступу до платформи. Створюючи обліковий запис у SEM BUS, ви
      зобов'язуєтеся не розголошувати свій пароль третім особам і берете на себе повну
      відповідальність за будь-які дії, вчинені з використанням ваших
      облікових даних для доступу.
    </p>

    <p>
      Користувач зобов'язується негайно повідомити SEM BUS за вказаними каналами
      зв'язку про будь-які підозри або виявлення несанкціонованого
      використання його/її облікового запису.
    </p>

    <p>
      Ваш обліковий запис є особистим і не підлягає передачі іншій особі.
    </p>

    <p>
      2.2. Правила використання
    </p>

    <p>
      Користувач не може:
    </p>

    <ul>
      <li>
        Використовувати будь-яким чином особисті дані третіх осіб або неправдиву
        інформацію;
      </li>

      <li>
        Використовувати платформу в незаконних або аморальних цілях;
      </li>

      <li>
        Збирати або розкривати інформацію про інших користувачів на платформі
        без попереднього та явного дозволу;
      </li>

      <li>
        Порушувати права інтелектуальної та промислової власності SEM BUS або
        третіх осіб;
      </li>

      <li>
        Втручатися в роботу SEM BUS, включно з серверами або мережами,
        які вона використовує.
      </li>

      <li>
        Виконувати зворотний інжиніринг або будь-яке інше використання вихідного
        коду платформи;
      </li>

      <li>
        Відтворення на будь-якому носії торговельної марки SEM BUS або будь-якого
        іншого тексту, зображення або графічного/аудіовізуального елемента,
        існуючого на платформі;
      </li>

      <li>
        Вставляти або поширювати контент, який може бути витлумачений як такий,
        що такий, що суперечить закону, образливий, аморальний або огидний;
      </li>

      <li>
        Публікувати або передавати будь-які файли, що містять віруси або будь-які
        інші засоби, які заражають або знищують чи можуть перешкодити
        правильному функціонуванню платформи або програмного забезпечення.
      </li>
    </ul>

    <p>
      Зверніть увагу, що бронювання/придбання квитків має бути виконано
      користувачами, які досягли 18 (вісімнадцяти) років.
    </p>

    <p>
      <strong>
        3. ВІДПОВІДАЛЬНІСТЬ ТА ОБОВ'ЯЗКИ
      </strong>
    </p>

    <p>
      3.1. Ви несете одноосібну відповідальність за використання платформи,
      наданої SEM BUS, яка, в свою чергу, не несе відповідальності
      за дії та поведінку своїх користувачів.
    </p>

    <p>
      3.2. SEM BUS не несе відповідальності за доступ і транзакції,
      що відбуваються за межами її платформи, а також за продукти або послуги,
      пропоновані користувачами та/або третіми особами, навіть якщо на платформі
      є гіперпосилання на такі середовища.
    </p>

    <p>
      3.3. SEM BUS не несе відповідальності за помилки або збої в роботі систем,
      на які SEM BUS не має жодного впливу. Таким чином, SEM BUS також
      не несе відповідальності за будь-які збитки або шкоду через збої
      (включно з безпекою) використовуваного вами інтернет-з'єднання.
    </p>

    <p>
      3.4. SEM BUS не несе відповідальності за дії третіх осіб, які
      можуть призвести до появи вірусів, троянів, шкідливих програм,
      шпигунських програм або будь-якого іншого шкідливого програмного
      забезпечення. Порушення будь-якого з вищезазначених зобов'язань користувача,
      а також будь-якого іншого положення цього документа може призвести, на
      власного розсуду SEM BUS, до призупинення доступу або видалення
      облікового запису користувача без попереднього повідомлення.
    </p>

    <p>
      <strong>
        4. ОПЛАТА
      </strong>
    </p>

    <p>
      4.1. Доступ до оплати продуктів та/або послуг буде доступний тільки після
      отримання SEM BUS підтвердження транзакції, що залежить від строків,
      встановлених третіми особами (посередниками, банками, операторами
      кредитних карток тощо), не контрольованими SEM BUS.
    </p>

    <p>
      4.3. Там, де це можливо та за умови, що використання придбаних
      послуг та/або продуктів не вичерпано, користувач може відмовитися від
      укладення контракту на продукти та/або послуги протягом семи 14 днів з
      моменту їх придбання, при цьому суми, в кінцевому підсумку сплачені
      повністю повертається користувачеві.
    </p>

    <p>
      <strong>
        5. ПРАВА ІНТЕЛЕКТУАЛЬНОЇ ВЛАСНОСТІ
      </strong>
    </p>

    <p>
      5.1. Вводячи будь-який контент на платформу, користувач надає нам
      безстрокову ліцензію на використання з метою, пов'язаною з її роботою.
    </p>

    <p>
      5.2. Доступ до платформи та її використання не дає користувачеві жодних
      прав інтелектуальної власності на будь-який контент, представлений або
      доступний через платформу.
    </p>

    <p>
      <strong>
        6. КОНФІДЕНЦІЙНІСТЬ І ЗАХИСТ ОСОБИСТИХ ДАНИХ
      </strong>
    </p>

    <p>
      {'Те, як ми збираємо, зберігаємо та використовуємо дані Користувачів,' +
        ' описано в політиці конфіденційності SEM BUS, з якою можна' +
        ' ознайомитися за '}
      <a href={'https://sem-bus.com/privacy-policy'} target={'_blank'}>
        посиланням
      </a>.
    </p>

    <p>
      <strong>
        7. ЗАГАЛЬНІ ПОЛОЖЕННЯ
      </strong>
    </p>

    <p>
      7.1.Якщо будь-яку частину цих умов відповідний орган визнає незаконною,
      недійсною або нездійсненною, інші положення залишаться чинними в
      максимально можливій мірі.
    </p>

    <p>
      7.2. Будь-яка нездатність або інерція SEM BUS у забезпеченні або виконанні
      будь-якого положення цих умов не означає відмову від ваших прав.
    </p>

    <p>
      7.3. SEM BUS може змінити ці умови в будь-який час, і в разі
      змін, що зачіпають права та обов'язки користувача, він буде
      повідомлений користувачеві.
    </p>

    <p>
      7.4. SEM BUS може повністю або частково припинити роботу платформи в
      будь-який час на власний розсуд, якщо користувачеві не належить
      відшкодування збитків або відшкодування шкоди.
    </p>


    <p>Останнє оновлення: 20.06.2023</p>
  </div>
);

export default TermsOfUse
