import React, {useState} from "react";
import {Header} from "./components/Header/Header";
import {Footer} from "./components/Footer/Footer";
import {Route, Routes, useLocation} from "react-router-dom";
import Page404 from "./pages/Page404/Page404";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-chat-widget/lib/styles.css';
import {useContext, useEffect} from "react";
import {AppContext} from "./context/appContext";
import {fire, getCollectionWhereKeyValue} from "./utils/firebaseConfigAndFunctions";
import './index.scss';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Home} from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import './utils/_reset.scss';
import './App.scss';
import OfferAgreement from "./pages/OfferAgreement/OfferAgreement";

function App() {
  useEffect(() => {
    document.title = 'SEM BUS'
  }, []);

  //#region Get user, auth status from context
  const {setUser, setIsLoggedIn} = useContext(AppContext);
  //#endregion

  //#region Set user to context after auth change
  useEffect(() => {
    fire.auth().onAuthStateChanged((result) => {
      try {
        if (result) {
          setIsLoggedIn(true);
          getCollectionWhereKeyValue('users', 'uid', result.uid)
            .then(users => {
              if (users.length > 0) {
                setUser(users[0]);
                sessionStorage.clear();
              } else {
                setTimeout(() => {
                  getCollectionWhereKeyValue('users', 'uid', result.uid)
                    .then(users => {
                      if (users.length > 0) {
                        setUser(users[0]);
                        sessionStorage.clear();
                      }
                    }).catch(error => console.log(error));
                }, 1000)
              }
            }).catch(error => console.log(error));
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, []);
  //#endregion

  //#region Get location object
  const location = useLocation();
  //#endregion

  const [areSearchResultsVisible, setAreSearchResultsVisible] = useState(false);

  //#region Render
  return (
    <div className="App">
      {(location.pathname !== '/terms-of-use'
        && location.pathname !== '/privacy-policy')
        &&
        <Header
          setAreSearchResultsVisible={setAreSearchResultsVisible}
        />
      }

      <Routes>
        <Route
          path='/'
          element={
            <Home
              setAreSearchResultsVisible={setAreSearchResultsVisible}
              areSearchResultsVisible={areSearchResultsVisible}
            />
        }
          exact
        />

        <Route
          path='/profile/*'
          element={<Profile />}
          exact
        />

        <Route
          path='/login'
          element={<Login />}
          exact
        />

        <Route
          path='/register'
          element={ <Register />}
          exact
        />

        <Route
          path='/recoverpassword'
          element={<RecoverPassword />}
          exact
        />
        <Route
          path='/changepassword'
          element={<ChangePassword />}
          exact
        />
        <Route
          path='/terms-of-use'
          element={<TermsOfUse />}
          exact
        />
        <Route
          path='/privacy-policy'
          element={<PrivacyPolicy />}
          exact
        />
        <Route
          path="/offer-agreement"
          element={<OfferAgreement />}
          exact
        />
        <Route path='*' element={<Page404/>}/>
      </Routes>

      {(location.pathname !== '/profile'
          && location.pathname !== '/terms-of-use'
          && location.pathname !== '/privacy-policy')
        &&
        <Footer
          setAreSearchResultsVisible={setAreSearchResultsVisible}
        />
      }
    </div>
  );
  //#endregion
}

export default App;
