import React from 'react';
import LoginForm from "../../components/LoginForm/LoginForm";

const Login = () => {
  //#region Render
  return (
    <div className={'login-page'}>
      <LoginForm addClasses={'login-page__login-form'}/>
    </div>
  );
  //#endregion
};

export default Login;
