import React, {useEffect, useState} from "react";
import {Row, Col} from 'react-bootstrap';
import {cityModel, daysOfWeek, tripSearchModel} from "../../utils/models";
import {cities, trips} from "../../utils/busRoutes";
import ModalNotification from "../../modals/ModalNotification/ModalNotification";
import isAfter from 'date-fns/isAfter'
import format from 'date-fns/format'
import {Link as ScrollLink} from "react-scroll";
import {DatePicker, Dropdown} from 'antd';
import dayjs from 'dayjs';
import './Ordering.scss';

export const Ordering = ({
  tripData,
  setTripData,
  setSearchResultsTitle,
  setAreSearchResultsVisible,
  setSuitableRoutes,
  setBackwardRoutes,
}) => {
  //#region Handle trip data
  const updateTripData = (fieldName, newValue) => {
    switch (fieldName) {
      case 'departurePoint':
        setTripData(state => ({
          ...state, departurePoint: cities
            ?.find(city => city.id === newValue) || cityModel,
        }));
        break;
      case 'arrivingPoint':
        setTripData(state => ({
          ...state, arrivingPoint: cities
            ?.find(city => city.id === newValue) || cityModel,
        }));
        break;
      default:
        setTripData(state => ({
          ...state, [fieldName]: newValue,
        }));
    }
  };

  const checkForwardDate = () => {
    if ((new Date()).setHours(0, 0, 0, 0) > (new Date(
      tripData.departureDate)).setHours(0, 0, 0, 0)) {
      setShowModal(true);
      setModalTitle('Помилка введення дат');
      setModalText('Дата виїзду має бути не раніше поточної дати');
      return false;
    }

    return true;
  };

  const checkBackDate = () => {
    if (!isAfter(new Date(tripData.backDepartureDate),
      new Date(tripData.departureDate))) {
      setShowModal(true);
      setModalTitle('Помилка введення дат');
      setModalText('Дата повернення має бути пізнішою за дату відправлення');
      return false;
    }

    return true;
  };

  useEffect(() => {
    setTripData({
      ...tripSearchModel,
      departureDate: format(new Date(), 'yyyy-MM-dd'),
      backDepartureDate: format(new Date(), 'yyyy-MM-dd'),
      departurePoint: cities[0],
      arrivingPoint: cities[4],
      childrenAmount: 0,
      adultsAmount: 1,
    });
  }, []);
  //#endregion

  //#region Handling search
  const getDaysNames = (daysNumbers) => {
    let daysNumbersSundayLast = daysNumbers;

    if (daysNumbers[0] === 0) {
      daysNumbersSundayLast.shift();
      daysNumbersSundayLast.push(0);
    }

    return daysNumbersSundayLast.map(day => daysOfWeek[day]).join(', ');
  }

  const getSuitableRoutes = () => {
    let foundRoutes = [];
    let foundBackwardRoutes = [];
    let suitableRoutes = [];
    let suitableRoutesBack = [];

    foundRoutes = trips.filter(trip => {
      const departurePoint = trip.points[0]?.cityData?.id === tripData
        ?.departurePoint?.id ? trip.points[0] : false;

      if (departurePoint) {
        const arrivingPoint = trip.points.find(point => point
          ?.cityData?.id === tripData?.arrivingPoint?.id);

        if (!arrivingPoint) {
          return false;
        } else {
          return Number(arrivingPoint.point) > Number(departurePoint.point);
        }
      } else {
        return false;
      }
    });

    const result = {
      canHaveATrip: false,
    }

    if (foundRoutes.length === 0) {
      setShowModal(true);
      setModalTitle('Повідомлення');
      setModalText('За Вашим запитом нічого не знайдено');

      return result;
    }

    const departureDate = new Date(tripData.departureDate?.$d || tripData
      .departureDate);

    const dayOfWeekDeparture = departureDate.getDay();

    suitableRoutes = foundRoutes.filter(route => route.daysOfWeek
      .includes(dayOfWeekDeparture));

    if (tripData.directions === 'both') {
      foundBackwardRoutes = trips.filter(trip => {
        const departurePoint = trip.points[0]?.cityData?.id === tripData
          ?.arrivingPoint?.id ? trip.points[0] : false;

        if (departurePoint) {
          const arrivingPoint = trip.points.find(point => point
            ?.cityData?.id === tripData?.departurePoint?.id);

          if (!arrivingPoint) {
            return false;
          } else {
            return Number(arrivingPoint.point) > Number(departurePoint.point);
          }
        } else {
          return false;
        }
      });

      const departureDateBack = new Date(tripData.backDepartureDate?.$d ||
        tripData.backDepartureDate);

      const dayOfWeekDepartureBack = departureDateBack.getDay();

      suitableRoutesBack = foundBackwardRoutes.filter(route => route.daysOfWeek
        .includes(dayOfWeekDepartureBack));
    }

    if (suitableRoutes.length == 0 && tripData.directions !== 'both') {
      setShowModal(true);
      setModalTitle('Повідомлення');
      setModalText('За обраним маршрутом відсутні поїздки у вказану дату.' +
        ` Відправлення відбувається у такі дні: ${getDaysNames(foundRoutes[0]
          .daysOfWeek)}.`);

      return result;
    }

    if (tripData.directions == 'both') {
      if (suitableRoutes.length === 0 && suitableRoutesBack.length == 0) {
        setShowModal(true);
        setModalTitle('Повідомлення');
        setModalText('За обраним маршрутом відсутні поїздки у вказані дати' +
          ' як в прямому, так і в зворотньому напрямках.' +
          ` Відправлення в прямому напрямку відбувається у такі дні: ${
          getDaysNames(foundRoutes[0].daysOfWeek)}.` + ` Відправлення у 
          зворотньому напрямку відбувається у такі дні: ${
            getDaysNames(foundBackwardRoutes[0].daysOfWeek)}`);

        return result;
      }

      if (suitableRoutes.length === 0 && suitableRoutesBack.length > 0) {
        setShowModal(true);
        setModalTitle('Повідомлення');
        setModalText('За обраним маршрутом відсутні поїздки у вказану дату' +
          ' в прямому напрямку (проте в зворотньому напрямку є поїздка).' +
          ` Відправлення в прямому напрямку відбувається у такі дні: ${
          getDaysNames(foundRoutes[0].daysOfWeek)}.`);

        return result;
      }

      if (suitableRoutes.length > 0 && suitableRoutesBack.length === 0) {
        setShowModal(true);
        setModalTitle('Повідомлення');
        setModalText('За обраним маршрутом можна поїхати у вказану дату' +
          ' в прямому напрямку, проте в зворотньому напрямку поїздки відсутні' +
          ` Відправлення в зворотньому напрямку відбувається у такі дні: ${
          getDaysNames(foundBackwardRoutes[0].daysOfWeek)}.`);

        return result;
      }
    }

    result.forward = suitableRoutes;
    result.backward = suitableRoutesBack;
    result.canHaveATrip = true;

    return result;
  }

  const handleTripSearch = (event) => {
    event.preventDefault();

    if (tripData.directions === 'both' && !checkForwardDate()) {
      return;
    }

    if (tripData.directions === 'both' && !checkBackDate()) {
      return;
    }

    if (tripData.adultsAmount === 0 && tripData.childrenAmount === 0) {
      setShowModal(true);
      setModalTitle('Помилка введення кількості місць');
      setModalText('Має бути обрано щонайменше одне місце');
      return;
    }

    if (tripData.adultsAmount === 0 && tripData.childrenAmount > 0) {
      setShowModal(true);
      setModalTitle('Помилка введення кількості місць');
      setModalText('Діти не можуть їхати без дорослих');
      return;
    }

    const foundRoutes = getSuitableRoutes();

    // foundRoutes = trips.filter(trip => {
    //   const departurePoint = trip.points[0]?.cityData?.id === tripData
    //     ?.departurePoint?.id ? trip.points[0] : false;
    //
    //   if (departurePoint) {
    //     const arrivingPoint = trip.points.find(point => point
    //       ?.cityData?.id === tripData?.arrivingPoint?.id);
    //
    //     if (!arrivingPoint) {
    //       return false;
    //     } else {
    //       return Number(arrivingPoint.point) > Number(departurePoint.point);
    //     }
    //   } else {
    //     return false;
    //   }
    // });

    setTripData(data => ({
      ...data,
      departureDate: typeof tripData.departureDate === 'object'
          ? format (new Date(tripData.departureDate.$d), 'yyyy-MM-dd')
          : data.departureDate,
      backDepartureDate: typeof tripData.backDepartureDate === 'object'
          ? format (new Date(tripData.backDepartureDate.$d), 'yyyy-MM-dd')
          : data.backDepartureDate,
    }));

    if (!foundRoutes.canHaveATrip) {
      return;
    }

    setSearchResultsTitle('Знайдено такі поіздки');

    setSuitableRoutes(foundRoutes.forward);

    setBackwardRoutes(foundRoutes.backward);

    setAreSearchResultsVisible(true);
  };
  //#endregion

  //#region Handling modal appearance
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  //#endregion

  //#region Handle list of cities sort
  const getCitiesSortedByAlphabet = (cities) => {
    let sortedCities = [...cities];

    return sortedCities.sort((city1, city2) => city1.city
      .localeCompare(city2.city));
  }

  const items2 = [
    {
      key: '1',
      type: 'group',
      label: 'Україна',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Україна')).map(city => {
          return {key: city.id, label: city.city}
        })
    },
    {
      key: '2',
      type: 'group',
      label: 'Литва',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Литва')).map(city => {
          return {label: city.city, key: city.id}
        }),
    },
    {
      key: '3',
      type: 'group',
      label: 'Латвія',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Латвія')).map(city => {
          return {label: city.city, key: city.id}
        }),
    },
    {
      key: '4',
      type: 'group',
      label: 'Естонія',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Естонія')).map(city => {
          return {label: city.city, key: city.id}
        }),
    },
  ];

  const items3 = [
    {
      key: '1',
      type: 'group',
      label: 'Україна',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Україна')).map(city => {
          return {key: city.id, label: city.city}
        })
    },
    {
      key: '2',
      type: 'group',
      label: 'Литва',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Литва')).map(city => {
          return {label: city.city, key: city.id}
        }),
    },
    {
      key: '3',
      type: 'group',
      label: 'Латвія',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Латвія')).map(city => {
          return {label: city.city, key: city.id}
        }),
    },
    {
      key: '4',
      type: 'group',
      label: 'Естонія',
      children: getCitiesSortedByAlphabet(cities.filter(city => city
        .country === 'Естонія')).map(city => {
          return {label: city.city, key: city.id}
        }),
    },
    // {
    //   key: '5',
    //   type: 'group',
    //   label: 'Фінляндія',
    //   children: getCitiesSortedByAlphabet(cities.filter(city => city
    //     .country === 'Фінляндія')).map(city => {
    //       return {label: city.city, key: city.id}
    //     }),
    // },
  ];

  const items4 = [
    {key: "1", label: "1",},
    {key: "2", label: "2",},
    {key: "3", label: "3",},
    {key: "4", label: "4",},
    {key: "5", label: "5",},
    {key: "6", label: "6",},
    {key: "7", label: "7",},
    {key: "8", label: "8",},
    {key: "9", label: "9",},
    {key: "10", label: "11",},
    {key: "12", label: "12",},
    {key: "13", label: "13",},
    {key: "14", label: "14",},
    {key: "15", label: "15",},
    {key: "16", label: "16",},
    {key: "17", label: "17",},
    {key: "18", label: "18",},
    {key: "19", label: "19",},
    {key: "20", label: "20",},
    {key: "21", label: "21",},
    {key: "22", label: "22",},
    {key: "23", label: "23",},
    {key: "24", label: "24",},
    {key: "25", label: "25",},
    {key: "26", label: "26",},
    {key: "27", label: "27",},
  ];

  const onClick = ({key}) => {
    updateTripData('departurePoint', key)
  };

  const onClick2 = ({key}) => {
    updateTripData('arrivingPoint', key)
  };

  const onClick3 = ({key}) => {
    updateTripData('childrenAmount', key)
  };

  const onClick4 = ({key}) => {
    updateTripData('adultsAmount', key)
  };
  //#endregion

  return (<>
    <div className="ordering">
      <form
        onSubmit={handleTripSearch}
      >
        {/*#region radiobuttons*/}
        <Row className={'mb-2'}>
          <Col xl={6} className={'control-col'}>
            <label
              htmlFor={'oneDirection'}
              className={'radio-directions-label'}
            >
              <span className={'radio-directions-label__point'}>
                  {tripData.directions === 'one' && <span
                    className={'radio-directions-label__point-inner'}
                  >
                      </span>}
              </span>
              <span className={'radio-directions-label__text'}>
                  Один напрямок
              </span>
              <input
                className={'radio-directions-input'}
                name={'directions'}
                type={'radio'}
                value={'one'}
                id={'oneDirection'}
                checked={tripData.directions === 'one'}
                onChange={() => updateTripData('directions', 'one')}
                required
              />
            </label>
          </Col>
          <Col xl={6} className={'control-col'}>
            <label
              htmlFor={'bothDirections'}
              className={'radio-directions-label'}
            >
              <span className={'radio-directions-label__point'}>
                {tripData.directions === 'both' &&
                  <span
                    className={'radio-directions-label__point-inner'}
                  ></span>}
              </span>

              <span className={'radio-directions-label__text'}>
                Обидва напрямки
              </span>

              <input
                className={'radio-directions-input'}
                name={'directions'}
                type={'radio'}
                value={'both'}
                id={'bothDirections'}
                checked={tripData.directions === 'both'}
                onChange={() => updateTripData('directions', 'both')}
                required
              />
            </label>
          </Col>
        </Row>
        {/*#endregion*/}

        {/*#region dates new*/}
        <Row className={'mb-2'}>
          <Col xl={6} className={'control-col'}>
            <div
              className={'formdatapicker-label'}
            >
              <span
                className={
                  'formdatapicker-label__image-container image-container'
                }
              >
                <img
                  className={'formdatapicker-label__image'}
                  src='assets/images/icons/calendar-icon.svg'
                  alt={'Calendar icon'}
                />
              </span>

              <span className={'formdatapicker-label__text-container'}>
                <span className={'formdatapicker-label__selector-container'}>
                  <span className={'formdatapicker-label__title'}>
                      Відправлення
                  </span>

                  <span
                    className={
                      'formdatapicker-label__selector-button selector-button'
                    }
                  >
                    <img
                      className={'selector-button__image'}
                      src={'assets/images/icons/arrow-down-icon.svg'}
                      alt="arrow-down-icon"
                    />
                  </span>
                </span>

                <span className={'formdatapicker-label__content'}>
                  {format(new Date(tripData.departureDate || Date.now()),
                    'dd.MM.yyyy')}
                </span>
              </span>

              <DatePicker
                value={dayjs(tripData.departureDate)}
                format={'YYYY/MM/DD'}
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0
                }}
                onChange={(value) => {
                  updateTripData('departureDate', value);
                }}
              />
            </div>
          </Col>

          <Col xl={6} className={'control-col'}>
            <div
              className={
                (tripData.directions === 'one' || !tripData.departureDate)
                ? 'formdatapicker-label formdatapicker-label--disabled'
                  : 'formdatapicker-label'}
            >
              <span
                className={
                  'formdatapicker-label__image-container image-container'
                }
              >
                <img
                  className={'formdatapicker-label__image'}
                  src='assets/images/icons/calendar-icon.svg'
                  alt={'Calendar icon'}
                />
              </span>

              <span className={'formdatapicker-label__text-container'}>
                <span className={'formdatapicker-label__selector-container'}>
                  <span className={'formdatapicker-label__title'}>
                    Повернення
                  </span>

                  <span
                    className={
                      'formdatapicker-label__selector-button selector-button'
                    }
                  >
                    <img
                      className={'selector-button__image'}
                      src={'assets/images/icons/arrow-down-icon.svg'}
                      alt="arrow-down-icon"
                    />
                  </span>
                </span>

                <span className={'formdatapicker-label__content'}>
                  {format(new Date(tripData.backDepartureDate || Date.now()),
                    'dd.MM.yyyy')}
                </span>
              </span>

              <DatePicker
                value={dayjs(tripData.backDepartureDate)}
                format={'YYYY/MM/DD'}
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0
                }}
                onChange={(value) => {
                  updateTripData('backDepartureDate', value);
                }}
                disabled={tripData.directions === 'one' || !tripData.departureDate}
              />
            </div>
          </Col>
        </Row>
        {/*#endregion*/}

        {/*#region directions new*/}
        <Row className={'mb-2'}>
          <Col
            xl={6}
            style={{position: "relative"}}
            className={'control-col'}
          >
            <Dropdown
              defaultValue="uk01"
              menu={{
                items: items2,
                onClick
              }}
            >
              <div
                className={'formdatapicker-label'}
              >
                <span
                  className='formdatapicker-label__image-container image-container'
                >
                  <img
                    className={'formdatapicker-label__image'}
                    src='assets/images/icons/key-icon.svg'
                    alt={'placement icon'}
                  />
                </span>

                <span className={'formdatapicker-label__text-container'}>
                  <span className={'formdatapicker-label__selector-container'}>
                    <span className={'formdatapicker-label__title'}>
                      Звідки
                    </span>

                    <span
                      className={
                        'formdatapicker-label__selector-button selector-button'
                      }
                    >
                      <img
                        className={'selector-button__image'}
                        src={'assets/images/icons/arrow-down-icon.svg'}
                        alt="arrow-down-icon"
                      />
                    </span>
                  </span>

                  <span className={'formdatapicker-label__content'}>
                    {`${tripData?.departurePoint?.city || 'Місто'}, ${tripData
                      ?.departurePoint?.country || 'Країна'}`}
                  </span>
                </span>
              </div>
            </Dropdown>
          </Col>

          <Col
            xl={6}
            className={'control-col'}
          >
            <Dropdown
              defaultValue="uk01"
              menu={{
                items: items3,
                onClick: onClick2
              }}
            >
              <div
                className={'formdatapicker-label'}
              >
                <span
                  className={
                    'formdatapicker-label__image-container image-container'
                  }
                >
                  <img
                    className={'formdatapicker-label__image'}
                    src='assets/images/icons/key-icon.svg'
                    alt={'placement icon'}
                  />
                </span>

                <span className={'formdatapicker-label__text-container'}>
                  <span className={'formdatapicker-label__selector-container'}>
                    <span className={'formdatapicker-label__title'}>
                        Куди?
                    </span>
                    <span
                      className={
                        'formdatapicker-label__selector-button selector-button'
                      }
                    >
                      <img
                        className={'selector-button__image'}
                        src={'assets/images/icons/arrow-down-icon.svg'}
                        alt={'placement icon'}
                      />
                    </span>
                  </span>

                  <span className={'formdatapicker-label__content'}>
                    {`${tripData?.arrivingPoint?.city || 'Місто'}, ${tripData
                      ?.arrivingPoint?.country || 'Країна'}`}
                  </span>
                </span>
              </div>
            </Dropdown>
          </Col>
        </Row>
        {/*#endregion*/}

        {/*#region seats new*/}
        <Row className={'mb-2'}>
          <Col xl={6} className={'control-col'}>
            <Dropdown
              defaultValue="1"
              menu={{
                items: items4,
                onClick: onClick3
              }}
            >
              <div
                className={'formdatapicker-label'}
              >
                <span
                  className={
                    'formdatapicker-label__image-container image-container'
                  }
                >
                  <img
                    className={'formdatapicker-label__image children-icon'}
                    src='assets/images/icons/children-icon.svg'
                    alt={'human shape icon'}
                  />
                </span>

                <span className={'formdatapicker-label__text-container'}>
                  <span className={'formdatapicker-label__selector-container'}>
                    <span className={'formdatapicker-label__title'}>
                        Діти
                    </span>

                    <span
                      className={
                        'formdatapicker-label__selector-button selector-button'
                      }
                    >
                      <img
                        className={'selector-button__image'}
                        src={'assets/images/icons/arrow-down-icon.svg'}
                        alt={'human shape icon'}
                      />
                    </span>
                  </span>

                  <span className={'formdatapicker-label__content'}>
                    {tripData?.childrenAmount}
                  </span>
                </span>
              </div>
            </Dropdown>
          </Col>

          <Col xl={6} className={'control-col'}>
            <Dropdown
              defaultValue="1"
              menu={{
                items: items4,
                onClick: onClick4
              }}
            >
              <div
                className={'formdatapicker-label'}
              >
                <span
                  className={
                    'formdatapicker-label__image-container image-container'
                  }
                >
                  <img
                    className={'formdatapicker-label__image children-icon'}
                    src='assets/images/icons/adult-icon.svg'
                    alt={'human shape icon'}
                  />
                </span>

                <span className={'formdatapicker-label__text-container'}>
                  <span className={'formdatapicker-label__selector-container'}>
                    <span className={'formdatapicker-label__title'}>
                      Дорослі
                    </span>
                    <span
                      className={
                        'formdatapicker-label__selector-button selector-button'
                        }
                      >
                        <img
                          className={'selector-button__image'}
                          src={'assets/images/icons/arrow-down-icon.svg'}
                          alt="arrow-down-icon"
                        />
                    </span>
                  </span>

                  <span className={'formdatapicker-label__content'}>
                    {tripData?.adultsAmount}
                  </span>
                </span>
              </div>
            </Dropdown>
          </Col>
        </Row>
        {/*#endregion*/}

        {/*#region button*/}
        <Row className={'mb-2'}>
          <Col xl={12} className={'control-col'}>
            <ScrollLink
              to="main"
              href="#"
              className='trip-search-button'
              spy={true}
              smooth={true}
              duration={500}
              onClick={handleTripSearch}
            >
              Пошук квитку
            </ScrollLink>
          </Col>
        </Row>
        {/*#endregion*/}
      </form>
    </div>

    {showModal &&
      <ModalNotification
        showModal={showModal}
        setShowModal={setShowModal}
        notificationTitle={modalTitle}
        notificationText={modalText}
      />
    }
  </>);
};
