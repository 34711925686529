import React, {useState} from 'react';
import {format} from 'date-fns';
import ModalOrderTickets from "../../modals/ModalOrderTickets/ModalOrderTickets";
import './SearchTripResults.scss';
import {Link as ScrollLink} from "react-scroll";

import {SearchTripResultsCard} from "../SearchTripResultsCard/SearchTripResultsCard";

const SearchTripResults = ({
  tripData,
  setAreSearchResultsVisible,
  suitableRoutes,
  backwardRoutes,
  addClasses,
}) => {
  //#region Manage tickets order modal
  const [showModal, setShowModal] = useState(false);

  const handleOrderTicketsClick = () => {
    setShowModal(true);
  };
  //#endregion

  //#region Manage race details displaying
  const [raceDetailsIndex, setRaceDetailsIndex] = useState(-1);
  //#endregion

  //#region Handle details click
  const handleDetailsClick = (curIndex) => {
    if (curIndex === raceDetailsIndex) {
      setRaceDetailsIndex(-1);
    } else {
      setRaceDetailsIndex(curIndex);
    }
  };
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={`search-trip-results ${addClasses}`}>
        {suitableRoutes.length > 0 &&
          <>
            <div>
              <div className={'trips-type-title search-trip-results__title'}>

                <div className={'trips-type-title__direction'}>
                  <div>
                    {suitableRoutes[0].points[0].cityData.city}
                  </div>
                  <div>
                    <img
                      src={'assets/images/trip-arrow-icon.svg'}
                      alt={'arrow-icon'}
                    />
                  </div>
                  <div>
                    {/*{suitableRoutes[0].points[suitableRoutes[0].points*/}
                    {/*  .length - 1].cityData.country}*/}
                    {tripData?.arrivingPoint?.country}
                  </div>
                </div>

                <div className={'trips-type-title__date'}>
                  {`Дата: ${format(new Date(tripData.departureDate), 
                    'dd.MM.yyyy')}`}
                </div>
              </div>

              {suitableRoutes.map((route, index) => (

                <React.Fragment key={route.id}>
                  <div className="search-trip-results__info-card">
                    <SearchTripResultsCard
                      tripData={tripData}
                      format={format}
                      route={route}
                      handleDetailsClick={handleDetailsClick}
                      handleOrderTicketsClick={handleOrderTicketsClick}
                      raceDetailsIndex={raceDetailsIndex}
                      forward={true}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>

            {(tripData.directions === 'both' && backwardRoutes.length > 0) &&
              <div>
                <div className={'trips-type-title search-trip-results__title'}>
                  <div className={'trips-type-title__from'}>
                    {backwardRoutes[0]?.points[0]?.cityData.city}
                  </div>
                  <div className={'trips-type-title__arrow-icon'}>
                    <img src={'assets/images/trip-arrow-icon.svg'} alt={'arrow-icon'}/>
                  </div>
                  <div className={'trips-type-title__to'}>
                    {backwardRoutes[0]?.points[backwardRoutes[0].points
                      .length - 1]?.cityData.country}
                  </div>
                  <div className={'trips-type-title__date'}>
                    {`Дата: ${format(new Date(tripData.backDepartureDate), 
                      'dd.MM.yyyy')}`}
                  </div>
                </div>

                {backwardRoutes.map((route) => (
                  <React.Fragment key={route.id}>
                    <div className="search-trip-results__info-card">
                      <SearchTripResultsCard
                        tripData={tripData}
                        format={format}
                        route={route}
                        handleDetailsClick={handleDetailsClick}
                        handleOrderTicketsClick={handleOrderTicketsClick}
                        raceDetailsIndex={raceDetailsIndex}
                        forward={false}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            }
          </>
        }

        <div className={'buttons-container'}>
          <ScrollLink
            to="main"
            className={'regular-button regular-button--secondary ' +
              'search-trip-results__button'}
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => {
              setAreSearchResultsVisible(false);
            }}
          >
            Повернутися
          </ScrollLink>

          <a
            className='regular-button search-trip-results__button search-trip-results__button-phone'
            href="tel:+380967540271"
          >
            Зателефонувати
          </a>
        </div>
      </div>

      {showModal &&
        <ModalOrderTickets
          showModal={showModal}
          setShowModal={setShowModal}
          tripData={tripData}
        />
      }
    </React.Fragment>
  );
  //#endregion
};

export default SearchTripResults;
