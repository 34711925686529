export const cities = [
// UKRAINE
  {
    id: 'uk01',
    cityName: 'Kyiv',
    city: 'Київ',
    country: 'Україна',
    address: 'вул.Симона Петлюри, 32',
  },
  {
    id: 'uk02',
    cityName: 'Zhytomyr',
    city: 'Житомир',
    country: 'Україна',
    address: 'вул.Київська, 93',
  },
  {
    id: 'uk03',
    cityName: 'Rivne',
    city: 'Рівне',
    country: 'Україна',
    address: 'вул.Київська, 40',
  },
  {
    id: 'uk04',
    cityName: 'Lutsk',
    city: 'Луцьк',
    country: 'Україна',
    address: 'вул.Конякіна, 39',
  },
  {
    id: 'uk05',
    cityName: 'Lviv',
    city: 'Львів',
    country: 'Україна',
    address: 'пл.Двірцева, 1',
  },
  {
    id: 'uk06',
    cityName: 'Uman',
    city: 'Умань',
    country: 'Україна',
    address: 'вул.Небесної Сотні, 10А',
  },
  {
    id: 'uk07',
    cityName: 'Vinnytsia',
    city: 'Вінниця',
    country: 'Україна',
    address: 'вул.Київська, 8',
  },
  {
    id: 'uk08',
    cityName: 'Khmelnytskyi',
    city: 'Хмельницький',
    country: 'Україна',
    address: 'проспект Миру, 94/1',
  },
  {
    id: 'uk09',
    cityName: 'Ternopil',
    city: 'Тернопіль',
    country: 'Україна',
    address: 'вул.Торговиця, 7',
  },
  {
    id: 'uk10',
    cityName: 'Zvyagel',
    city: 'Звягель',
    country: 'Україна',
    address: null,
  },
  {
    id: 'uk11',
    cityName: 'Haisyn',
    city: 'Гайсин',
    country: 'Україна',
    address: 'вул. 1-го Травня, 129',
  },
  {
    id: 'uk12',
    cityName: 'Nemyriv',
    city: 'Немирів',
    country: 'Україна',
    address: null,
  },
  {
    id: 'uk13',
    cityName: 'Cherkasy',
    city: 'Черкаси',
    country: 'Україна',
    address: 'вул. Смілянська, 166/1',
  },
  {
    id: 'uk14',
    cityName: 'Kremenchuk',
    city: 'Кременчук',
    country: 'Україна',
    address: 'вул. Театральна, 32/6',
  },
  {
    id: 'uk15',
    cityName: 'Poltava',
    city: 'Полтава',
    country: 'Україна',
    address: null,
  },
  {
    id: 'uk16',
    cityName: 'Kharkiv',
    city: 'Харків',
    country: 'Україна',
    address: 'пр. Гагаріна, 22',
  },
  {
    id: 'uk17',
    cityName: 'Kropyvnytskyi',
    city: 'Кропивницький',
    country: 'Україна',
    address: 'Олександрійське шосе, 1',
  },
  {
    id: 'uk18',
    cityName: 'Dnipro',
    city: 'Дніпро',
    country: 'Україна',
    address: 'вул. Курчатова, 10',
  },
  {
    id: 'uk19',
    cityName: 'Zaporizhzhia',
    city: 'Запоріжжя',
    country: 'Україна',
    address: 'пр. Соборний, 20',
  },
  {
    id: 'uk20',
    cityName: 'Odesa',
    city: 'Одеса',
    country: 'Україна',
    address: null,
  },
  {
    id: 'uk21',
    cityName: 'Mykolaiv',
    city: 'Миколаїв',
    country: 'Україна',
    address: null,
  },
  {
    id: 'uk22',
    cityName: 'Kherson',
    city: 'Херсон',
    country: 'Україна',
    address: null,
  },
  {
    id: 'uk23',
    cityName: 'Dubno',
    city: 'Дубно',
    country: 'Україна',
    address: null,
  },
  {
    id: 'uk24',
    cityName: 'Olexandriya',
    city: 'Олександрія',
    country: 'Україна',
    address: null,
  },

  // LITHUANIA
  {
    id: 'lt01',
    cityName: 'Marijampole',
    city: 'Маріямполе',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lt02',
    cityName: 'Kaunas',
    city: 'Каунас',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lt03',
    cityName: 'Vilnius',
    city: 'Вільнюс',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lt04',
    cityName: 'Panevezys',
    city: 'Паневежис',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lv05',
    cityName: 'Siaule',
    city: 'Шяуляй',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lv06',
    cityName: 'Palanha',
    city: 'Паланга',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lv07',
    cityName: 'Klaipeda',
    city: 'Клайпеда',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lv08',
    cityName: 'Pasvalis',
    city: 'Пасваліс',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lv09',
    cityName: 'Alitus',
    city: 'Алітус',
    country: 'Литва',
    address: null,
  },
  {
    id: 'lv10',
    cityName: 'Kalvariia',
    city: 'Калварія',
    country: 'Литва',
    address: null,
  },

// LATVIA
  {
    id: 'lv01',
    cityName: 'Bauska',
    city: 'Бауска',
    country: 'Латвія',
    address: null,
  },
  {
    id: 'lv02',
    cityName: 'Riga',
    city: 'Рига',
    country: 'Латвія',
    address: null,
  },
  {
    id: 'lv03',
    cityName: 'Yetsava',
    city: 'Єцава',
    country: 'Латвія',
    address: null,
  },
  {
    id: 'lv04',
    cityName: 'Yelhava',
    city: 'Єлгава',
    country: 'Латвія',
    address: null,
  },

  // ESTONIA
  {
    id: 'ee01',
    cityName: 'Parnu',
    city: 'Пярну',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee02',
    cityName: 'Tallinn',
    city: 'Таллінн',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee03',
    cityName: 'Tartu',
    city: 'Тарту',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee04',
    cityName: 'Vyru',
    city: 'Виру',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee05',
    cityName: 'Pyltsaama',
    city: 'Пилтсаама',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee06',
    cityName: 'Paide',
    city: 'Пайде',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee07',
    cityName: 'Viliandi',
    city: 'Вільянді',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee08',
    cityName: 'Keila',
    city: 'Кейла',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee09',
    cityName: 'Mariamma',
    city: 'Мар’ямаа',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee10',
    cityName: 'Elva',
    city: 'Елва',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee11',
    cityName: 'Pylva',
    city: 'Пилва',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee12',
    cityName: 'Yyheva',
    city: 'Йигева',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee13',
    cityName: 'Valha',
    city: 'Валга',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee14',
    cityName: 'Tiuri',
    city: 'Тюрі',
    country: 'Естонія',
    address: null,
  },
  {
    id: 'ee15',
    cityName: 'Tabivere',
    city: 'Табівере',
    country: 'Естонія',
    address: null,
  },
];

export const trips = [
  //From Ukraine
  {
    id: 1,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kyiv',
        arrivingTime: null,
        time: ['09', '00'],
        priceFromEntry: null,
        tripTime: [],
      },
      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['06', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['21', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['22', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['27', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['26', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['23', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['23', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['21', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['25', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['26', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['25', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['26', '00'],
      },

      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['28', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['30', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['31', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['30', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['30', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['30', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['30', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['30', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['30', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['31', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['31', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['09', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['31', '00'],
      },
    ]
  },
  {
    id: 2,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Zhytomyr',
        time: ['10', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['06', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['20', '00'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['21', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['22', '30'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['22', '40'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['22', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['25', '30'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '30'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['22', '00'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['21', '30'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['19', '30'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '00'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '30'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['23', '30'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '30'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['26', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['28', '20'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '00'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '30'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['28', '30'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['28', '30'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['27', '30'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['28', '30'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['28', '00'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '10'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '00'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['28', '30'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '30'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['10', '30'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['29', '30'],
      },
    ]
  },
  {
    id: 3,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Zvyagel',
        time: ['12', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['18', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['19', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['21', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['21', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['22', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['24', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['23', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['20', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['20', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['12', '00'],
        priceFromEntry: "Зателефонуйте нам, щоб отримати більше інформації",
        tripTime: ['18', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['12', '00'],
        priceFromEntry: 3200,
        tripTime: ['22', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: 3000,
        tripTime: ['23', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['12', '00'],
        priceFromEntry: 3000,
        tripTime: ['22', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: 3000,
        tripTime: ['23', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['25', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['26', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['28', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['26', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['14', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['26', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['26', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['28', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['28', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['28', '00'],
      },
    ]
  },
  {
    id: 4,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Vinnytsia',
        time: ['08', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['06', '30'],
        priceFromEntry: 3360,
        tripTime: ['22', '00'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['08', '30'],
        priceFromEntry: 3360,
        tripTime: ['23', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['08', '30'],
        priceFromEntry: 3360,
        tripTime: ['24', '30'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['08', '30'],
        priceFromEntry: 3360,
        tripTime: ['24', '40'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['08', '30'],
        priceFromEntry: 3360,
        tripTime: ['24', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['26', '30'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['08', '30'],
        priceFromEntry: 3360,
        tripTime: ['24', '00'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['08', '30'],
        priceFromEntry: 3360,
        tripTime: ['23', '30'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['08', '30'],
        priceFromEntry: 3360,
        tripTime: ['21', '30'],
      },

      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['08', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '00'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['08', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['08', '30'],
        priceFromEntry: 3800,
        tripTime: ['25', '30'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['08', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },

      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['28', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['26', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '00'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['30', '30'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['30', '30'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['30', '30'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['30', '00'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '10'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '00'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['30', '30'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
    ]
  },
  {
    id: 5,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Khmelnytskyi',
        time: ['10', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['20', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['23', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['23', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['23', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['23', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['25', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['22', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['22', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['10', '00'],
        priceFromEntry: 3360,
        tripTime: ['20', '00'],
      },

      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['10', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3800,
        tripTime: ['25', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['10', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3800,
        tripTime: ['25', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['30', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['30', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['30', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['30', '00'],
      },
    ]
  },
  {
    id: 6,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Ternopil',
        time: ['12', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['18', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['19', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['21', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['21', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['22', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['24', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['23', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['20', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['20', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['12', '00'],
        priceFromEntry: 3360,
        tripTime: ['18', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['12', '00'],
        priceFromEntry: 3800,
        tripTime: ['22', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: 3800,
        tripTime: ['23', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['12', '00'],
        priceFromEntry: 3800,
        tripTime: ['22', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: 3800,
        tripTime: ['23', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['25', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['14', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '00'],
      },
    ]
  },
  {
    id: 7,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Rivne',
        time: ['13', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['13', '00'],
        priceFromEntry: 2800,
        tripTime: ['17', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['13', '00'],
        priceFromEntry: 2800,
        tripTime: ['18', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['13', '00'],
        priceFromEntry: 1900,
        tripTime: ['20', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['13', '00'],
        priceFromEntry: 2800,
        tripTime: ['20', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['13', '00'],
        priceFromEntry: 2800,
        tripTime: ['20', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['23', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['22', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['13', '00'],
        priceFromEntry: 2800,
        tripTime: ['19', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['13', '00'],
        priceFromEntry: 2800,
        tripTime: ['19', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['13', '00'],
        priceFromEntry: 2800,
        tripTime: ['17', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['13', '00'],
        priceFromEntry: 3000,
        tripTime: ['21', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['13', '00'],
        priceFromEntry: 2200,
        tripTime: ['22', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['21', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['22', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['13', '00'],
        priceFromEntry: 3200,
        tripTime: ['24', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['13', '00'],
        priceFromEntry: 3200,
        tripTime: ['25', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['13', '00'],
        priceFromEntry: 3700,
        tripTime: ['26', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['27', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['25', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['25', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['27', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['27', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['27', '00'],
      },
    ]
  },
  {
    id: 8,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Lutsk',
        time: ['14', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['14', '00'],
        priceFromEntry: 2800,
        tripTime: ['16', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['14', '00'],
        priceFromEntry: 2800,
        tripTime: ['17', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['14', '00'],
        priceFromEntry: 1900,
        tripTime: ['19', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['14', '00'],
        priceFromEntry: 2800,
        tripTime: ['19', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['14', '00'],
        priceFromEntry: 2800,
        tripTime: ['19', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['22', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['21', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['14', '00'],
        priceFromEntry: 2800,
        tripTime: ['18', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['14', '00'],
        priceFromEntry: 2800,
        tripTime: ['18', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['14', '00'],
        priceFromEntry: 2800,
        tripTime: ['16', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['14', '00'],
        priceFromEntry: 3000,
        tripTime: ['20', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 2200,
        tripTime: ['21', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['14', '00'],
        priceFromEntry: 2200,
        tripTime: ['20', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 2200,
        tripTime: ['21', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['23', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['24', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['26', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['24', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['24', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['26', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['67', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['26', '00'],
      },
    ]
  },
  {
    id: 9,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Lviv',
        time: ['14', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['16', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['17', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['19', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['19', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['19', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['22', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['21', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['18', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['18', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['14', '00'],
        priceFromEntry: 2890,
        tripTime: ['16', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['14', '00'],
        priceFromEntry: 3300,
        tripTime: ['20', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 3300,
        tripTime: ['21', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['14', '00'],
        priceFromEntry: 3300,
        tripTime: ['20', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 3300,
        tripTime: ['21', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['23', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['24', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['26', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['24', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['24', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['14', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['26', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['67', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3700,
        tripTime: ['26', '00'],
      },
    ]
  },
  {
    id: 10,
    isEveryDay: false,
    daysOfWeek: [1, 4, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Cherkasy',
        time: ['04', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['26', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['27', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['29', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['29', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['29', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['32', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['31', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['28', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['28', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['04', '00'],
        priceFromEntry: 4100,
        tripTime: ['28', '30'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['30', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['31', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['30', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['31', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['33', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['34', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['35', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['36', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['35', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['35', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['34', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['35', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['34', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['35', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['35', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['35', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['36', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['36', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 4490,
        tripTime: ['36', '00'],
      },
    ]
  },
  {
    id: 11,
    isEveryDay: false,
    daysOfWeek:  [1, 4, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kremenchuk',
        time: ['01', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['30', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['35', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['34', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['33', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['34', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['33', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['34', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['36', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['37', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['38', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['39', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['38', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['38', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['37', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['38', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['37', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['38', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['38', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['38', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['39', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['39', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 4900,
        tripTime: ['39', '00'],
      },
    ]
  },
  {
    id: 12,
    isEveryDay: false,
    daysOfWeek: [1, 4, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Poltava',
        time: ['00', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['30', '00'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['31', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['32', '30'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['32', '40'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['32', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['00', '30'],
        priceFromEntry: 5000,
        tripTime: ['34', '30'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['32', '00'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '30'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['32', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['00', '30'],
        priceFromEntry: 4500,
        tripTime: ['29', '30'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['00', '30'],
        priceFromEntry: 4890,
        tripTime: ['34', '00'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['00', '30'],
        priceFromEntry: 4890,
        tripTime: ['34', '30'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['00', '30'],
        priceFromEntry: 4890,
        tripTime: ['33', '30'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['00', '30'],
        priceFromEntry: 4890,
        tripTime: ['34', '30'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['36', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '20'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '30'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '30'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '30'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '30'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '10'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '30'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '30'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['00', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '30'],
      },
    ]
  },
  {
    id: 13,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kharkiv',
        time: ['22', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['33', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['35', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['35', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['35', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['22', '00'],
        priceFromEntry: 5000,
        tripTime: ['37', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['22', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['22', '00'],
        priceFromEntry: 4890,
        tripTime: ['36', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['22', '00'],
        priceFromEntry: 4890,
        tripTime: ['37', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['22', '00'],
        priceFromEntry: 4890,
        tripTime: ['36', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['22', '00'],
        priceFromEntry: 4890,
        tripTime: ['37', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['22', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
    ]
  },
  {
    id: 14,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kropyvnytskyi',
        time: ['04', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['27', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['32', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['04', '00'],
        priceFromEntry: 5000,
        tripTime: ['31', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['28', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['28', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['04', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['04', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['04', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['04', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['04', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['04', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
    ]
  },
  {
    id: 15,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Dnipro',
        time: ['23', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['23', '00'],
        priceFromEntry: 5000,
        tripTime: ['36', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['33', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['33', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['35', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['36', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['35', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['36', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
    ]
  },
  {
    id: 16,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Zaporizhzhia',
        time: ['21', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['33', '00'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['34', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['35', '30'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['35', '40'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['35', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '30'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['21', '30'],
        priceFromEntry: 5000,
        tripTime: ['37', '30'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['35', '00'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['34', '30'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['21', '30'],
        priceFromEntry: 4500,
        tripTime: ['32', '30'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['21', '30'],
        priceFromEntry: 4890,
        tripTime: ['37', '00'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['21', '30'],
        priceFromEntry: 4890,
        tripTime: ['37', '30'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['21', '30'],
        priceFromEntry: 4890,
        tripTime: ['36', '30'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['21', '30'],
        priceFromEntry: 4890,
        tripTime: ['37', '30'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['41', '20'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['42', '30'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['41', '30'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['41', '30'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['40', '30'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['41', '30'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['42', '10'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['41', '30'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['42', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['42', '30'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['21', '30'],
        priceFromEntry: 5450,
        tripTime: ['42', '30'],
      },
    ]
  },
  {
    id: 17,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Uman',
        time: ['06', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['25', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '40'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['29', '30'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '00'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['08', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['08', '00'],
        priceFromEntry: 3800,
        tripTime: ['22', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '00'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['27', '30'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['30', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '20'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['31', '30'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '00'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '10'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
    ]
  },
  {
    id: 18,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Haisyn',
        time: ['06', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['25', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '40'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['29', '30'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '00'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['08', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['08', '00'],
        priceFromEntry: 3800,
        tripTime: ['22', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '00'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['27', '30'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['30', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '20'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['31', '30'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '00'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '10'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['32', '30'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
    ]
  },
  {
    id: 19,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Nemyriv',
        time: ['06', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['06', '30'],
        priceFromEntry: 3360,
        tripTime: ['24', '00'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['06', '30'],
        priceFromEntry: 3360,
        tripTime: ['25', '00'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['06', '30'],
        priceFromEntry: 3360,
        tripTime: ['26', '30'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['06', '30'],
        priceFromEntry: 3360,
        tripTime: ['26', '40'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['06', '30'],
        priceFromEntry: 3360,
        tripTime: ['26', '45'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['28', '30'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['06', '30'],
        priceFromEntry: 3360,
        tripTime: ['26', '00'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['08', '00'],
        priceFromEntry: 3360,
        tripTime: ['24', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['08', '00'],
        priceFromEntry: 3360,
        tripTime: ['22', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['28', '00'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['28', '30'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['27', '30'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['06', '30'],
        priceFromEntry: 3800,
        tripTime: ['28', '30'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['30', '50'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['32', '20'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '00'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['32', '30'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['32', '30'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['32', '30'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['32', '00'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '10'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '00'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['32', '30'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['06', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
    ]
  },
  {
    id: 20,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: "Odesa",
        time: ['23', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['23', '00'],
        priceFromEntry: 5000,
        tripTime: ['36', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['33', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['33', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['23', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['35', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['36', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['35', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['23', '00'],
        priceFromEntry: 4890,
        tripTime: ['36', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['23', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
    ]
  },
  {
    id: 21,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Mykolaiv',
        time: ['21', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['33', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['34', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['36', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['36', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['36', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['21', '00'],
        priceFromEntry: 5000,
        tripTime: ['38', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['35', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['35', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['33', '00'],
      },

      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['21', '00'],
        priceFromEntry: 4890,
        tripTime: ['37', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['21', '00'],
        priceFromEntry: 4890,
        tripTime: ['38', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['21', '00'],
        priceFromEntry: 4890,
        tripTime: ['37', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['21', '00'],
        priceFromEntry: 4890,
        tripTime: ['38', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['40', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['43', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['43', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['43', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['21', '00'],
        priceFromEntry: 5450,
        tripTime: ['43', '00'],
      },
    ]
  },
  {
    id: 22,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kherson',
        time: ['19', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['35', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['36', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['38', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['38', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['38', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['41', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['19', '00'],
        priceFromEntry: 5000,
        tripTime: ['40', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['37', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['37', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['35', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['19', '00'],
        priceFromEntry: 4890,
        tripTime: ['39', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['19', '00'],
        priceFromEntry: 4890,
        tripTime: ['40', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['19', '00'],
        priceFromEntry: 4890,
        tripTime: ['39', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['19', '00'],
        priceFromEntry: 4890,
        tripTime: ['40', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['42', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['43', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['44', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['45', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['44', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['44', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['43', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['44', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['43', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['44', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['44', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['44', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['45', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['45', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['19', '00'],
        priceFromEntry: 5450,
        tripTime: ['45', '00'],
      },
    ]
  },
  {
    id: 23,
    isEveryDay: false,
    daysOfWeek:  [1, 4, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Olexandriya',
        time: ['01', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '30'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['30', '30'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '00'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '10'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['32', '15'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '30'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['31', '00'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['01', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '00'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['01', '00'],
        priceFromEntry: 4890,
        tripTime: ['33', '30'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['01', '00'],
        priceFromEntry: 4890,
        tripTime: ['34', '00'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['01', '00'],
        priceFromEntry: 4890,
        tripTime: ['33', '00'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['01', '00'],
        priceFromEntry: 4890,
        tripTime: ['34', '00'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '20'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '50'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '30'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '00'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '40'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '30'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['01', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '00'],
      },
    ]
  },
  {
    id: 24,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Dubno',
        time: ['13', '50'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Lithuania
      {
        point: 1,
        cityName: 'Marijampole',
        arrivingTime: ['06', '30'],
        time: ['13', '50'],
        priceFromEntry: 2800,
        tripTime: ['16', '40'],
      },
      {
        point: 2,
        cityName: 'Kaunas',
        arrivingTime: ['07', '30'],
        time: ['13', '50'],
        priceFromEntry: 2800,
        tripTime: ['17', '40'],
      },
      {
        point: 3,
        cityName: 'Vilnius',
        arrivingTime: ['09', '00'],
        time: ['13', '50'],
        priceFromEntry: 1900,
        tripTime: ['19', '100'],
      },
      {
        point: 4,
        cityName: 'Panevezys',
        arrivingTime: ['09', '10'],
        time: ['13', '50'],
        priceFromEntry: 2800,
        tripTime: ['19', '20'],
      },
      {
        point: 5,
        cityName: 'Siaule',
        arrivingTime: ['09', '15'],
        time: ['13', '50'],
        priceFromEntry: 2800,
        tripTime: ['19', '25'],
      },
      {
        point: 6,
        cityName: 'Palanha',
        arrivingTime: ['12', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['22', '10'],
      },
      {
        point: 7,
        cityName: 'Klaipeda',
        arrivingTime: ['11', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['21', '10'],
      },
      {
        point: 8,
        cityName: 'Pasvalis',
        arrivingTime: ['08', '30'],
        time: ['13', '50'],
        priceFromEntry: 2800,
        tripTime: ['18', '40'],
      },
      {
        point: 9,
        cityName: 'Alitus',
        arrivingTime: ['08', '00'],
        time: ['13', '50'],
        priceFromEntry: 2800,
        tripTime: ['19', '10'],
      },
      {
        point: 10,
        cityName: 'Kalvariia',
        arrivingTime: ['06', '00'],
        time: ['13', '50'],
        priceFromEntry: 2800,
        tripTime: ['16', '10'],
      },
      //Latvia
      {
        point: 11,
        cityName: 'Bauska',
        arrivingTime: ['10', '30'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['20', '40'],
      },
      {
        point: 12,
        cityName: 'Riga',
        arrivingTime: ['11', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['21', '10'],
      },
      {
        point: 13,
        cityName: 'Yetsava',
        arrivingTime: ['10', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['20', '10'],
      },
      {
        point: 14,
        cityName: 'Yelhava',
        arrivingTime: ['11', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['21', '10'],
      },
      //Estonia
      {
        point: 15,
        cityName: 'Parnu',
        arrivingTime: ['13', '20'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['23', '10'],
      },
      {
        point: 16,
        cityName: 'Tallinn',
        arrivingTime: ['14', '50'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '00'],
      },
      {
        point: 17,
        cityName: 'Tartu',
        arrivingTime: ['15', '30'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '40'],
      },
      {
        point: 18,
        cityName: 'Vyru',
        arrivingTime: ['16', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['26', '10'],
      },
      {
        point: 19,
        cityName: 'Pyltsaama',
        arrivingTime: ['15', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '10'],
      },
      {
        point: 20,
        cityName: 'Paide',
        arrivingTime: ['15', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '10'],
      },
      {
        point: 21,
        cityName: 'Viliandi',
        arrivingTime: ['14', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['24', '10'],
      },
      {
        point: 22,
        cityName: 'Keila',
        arrivingTime: ['15', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '10'],
      },
      {
        point: 23,
        cityName: 'Mariamma',
        arrivingTime: ['14', '30'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['24', '40'],
      },
      {
        point: 24,
        cityName: 'Elva',
        arrivingTime: ['15', '40'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '50'],
      },
      {
        point: 25,
        cityName: 'Pylva',
        arrivingTime: ['15', '30'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '40'],
      },
      {
        point: 26,
        cityName: 'Yyheva',
        arrivingTime: ['15', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['25', '10'],
      },
      {
        point: 27,
        cityName: 'Valha',
        arrivingTime: ['16', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['26', '10'],
      },
      {
        point: 28,
        cityName: 'Tiuri',
        arrivingTime: ['16', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['26', '10'],
      },
      {
        point: 29,
        cityName: 'Tabivere',
        arrivingTime: ['16', '00'],
        time: ['13', '50'],
        priceFromEntry: 3480,
        tripTime: ['26', '10'],
      },
    ]
  },

  //To Ukraine
  //From Lithuania
  {
    id: 25,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Marijampole',
        time: ['20', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['20', '00'],
        priceFromEntry: 3000,
        tripTime: ['20', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['20', '00'],
        priceFromEntry: 3000,
        tripTime: ['18', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['20', '00'],
        priceFromEntry: 3000,
        tripTime: ['17', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['20', '00'],
        priceFromEntry: 3360,
        tripTime: ['21', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['20', '00'],
        priceFromEntry: 3360,
        tripTime: ['19', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['20', '00'],
        priceFromEntry: 3360,
        tripTime: ['17', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['20', '00'],
        priceFromEntry: 2800,
        tripTime: ['16', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['20', '00'],
        priceFromEntry: 2800,
        tripTime: ['15', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['20', '00'],
        priceFromEntry: 2890,
        tripTime: ['15', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['20', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['20', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['20', '00'],
        priceFromEntry: 3360,
        tripTime: ['24', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['20', '00'],
        priceFromEntry: 2800,
        tripTime: ['13', '00'],
      },
    ]
  },
  {
    id: 26,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Marijampole',
        time: ['20', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['20', '00'],
        priceFromEntry: 4100,
        tripTime: ['22', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['25', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['24', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['27', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['24', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['20', '00'],
        priceFromEntry: 4500,
        tripTime: ['25', '30'],
      },
    ]
  },
  {
    id: 27,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kaunas',
        time: ['19', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['19', '00'],
        priceFromEntry: 3000,
        tripTime: ['21', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['19', '00'],
        priceFromEntry: 3000,
        tripTime: ['19', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['19', '00'],
        priceFromEntry: 3000,
        tripTime: ['18', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['19', '00'],
        priceFromEntry: 3360,
        tripTime: ['22', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['19', '00'],
        priceFromEntry: 3360,
        tripTime: ['20', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['19', '00'],
        priceFromEntry: 3360,
        tripTime: ['18', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['19', '00'],
        priceFromEntry: 2800,
        tripTime: ['17', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['19', '00'],
        priceFromEntry: 2800,
        tripTime: ['16', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['19', '00'],
        priceFromEntry: 2890,
        tripTime: ['16', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['27', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['19', '00'],
        priceFromEntry: 3800,
        tripTime: ['25', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['19', '00'],
        priceFromEntry: 3800,
        tripTime: ['25', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['19', '00'],
        priceFromEntry: 3360,
        tripTime: ['25', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['19', '00'],
        priceFromEntry: 2800,
        tripTime: ['14', '00'],
      },
    ]
  },
  {
    id: 28,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kaunas',
        time: ['19', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['19', '00'],
        priceFromEntry: 4100,
        tripTime: ['23', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['25', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['27', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['27', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['28', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['19', '00'],
        priceFromEntry: 4000,
        tripTime: ['25', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['19', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['27', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['19', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '30'],
      },
    ]
  },
  {
    id: 29,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Vilnius',
        time: ['17', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['17', '30'],
        priceFromEntry: 2700,
        tripTime: ['22', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['17', '30'],
        priceFromEntry: 2700,
        tripTime: ['21', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['17', '30'],
        priceFromEntry: 2700,
        tripTime: ['20', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['17', '30'],
        priceFromEntry: 3360,
        tripTime: ['24', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['17', '30'],
        priceFromEntry: 3360,
        tripTime: ['22', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['17', '30'],
        priceFromEntry: 3360,
        tripTime: ['20', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['17', '30'],
        priceFromEntry: 1900,
        tripTime: ['19', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['17', '30'],
        priceFromEntry: 1900,
        tripTime: ['17', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['17', '30'],
        priceFromEntry: 2890,
        tripTime: ['17', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['28', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['17', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['17', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['17', '30'],
        priceFromEntry: 3360,
        tripTime: ['26', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['17', '30'],
        priceFromEntry: 1900,
        tripTime: ['15', '30'],
      },
    ]
  },
  {
    id: 30,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Vilnius',
        time: ['17', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['17', '30'],
        priceFromEntry: 4100,
        tripTime: ['24', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['27', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['26', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['28', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['29', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['30', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['26', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['28', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['28', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['17', '30'],
        priceFromEntry: 4500,
        tripTime: ['28', '00']
      }
    ]
  },
  {
    id: 31,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Panevezys',
        time: ['17', '15'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['17', '15'],
        priceFromEntry: 3200,
        tripTime: ['22', '45'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['17', '15'],
        priceFromEntry: 3200,
        tripTime: ['21', '15'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['17', '15'],
        priceFromEntry: 3200,
        tripTime: ['20', '15'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['17', '15'],
        priceFromEntry: 3360,
        tripTime: ['24', '15'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['17', '15'],
        priceFromEntry: 3360,
        tripTime: ['22', '15'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['17', '15'],
        priceFromEntry: 3360,
        tripTime: ['20', '15'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['17', '15'],
        priceFromEntry: 2800,
        tripTime: ['19', '15'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['17', '15'],
        priceFromEntry: 2800,
        tripTime: ['17', '45'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['17', '15'],
        priceFromEntry: 2890,
        tripTime: ['17', '45'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['28', '45'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['17', '15'],
        priceFromEntry: 3800,
        tripTime: ['26', '45'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['17', '15'],
        priceFromEntry: 3800,
        tripTime: ['26', '45'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['17', '15'],
        priceFromEntry: 3360,
        tripTime: ['26', '45'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['17', '15'],
        priceFromEntry: 2800,
        tripTime: ['15', '45'],
      },
    ]
  },
  {
    id: 32,
    isEveryDay: true,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Panevezys',
        time: ['17', '15'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['17', '15'],
        priceFromEntry: 4100,
        tripTime: ['24', '45'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['27', '45'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['26', '45'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['28', '45'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['29', '35'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['30', '35'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['26', '45'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['28', '45'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['28', '45'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['17', '15'],
        priceFromEntry: 4500,
        tripTime: ['28', '15']
      }
    ]
  },
  {
    id: 33,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Siaule',
        time: ['16', '15'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['16', '15'],
        priceFromEntry: 3200,
        tripTime: ['23', '45'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['16', '15'],
        priceFromEntry: 3200,
        tripTime: ['22', '15'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['16', '15'],
        priceFromEntry: 3200,
        tripTime: ['21', '15'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['16', '15'],
        priceFromEntry: 3360,
        tripTime: ['25', '15'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['16', '15'],
        priceFromEntry: 3360,
        tripTime: ['23', '15'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['16', '15'],
        priceFromEntry: 3360,
        tripTime: ['21', '15'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['16', '15'],
        priceFromEntry: 2800,
        tripTime: ['20', '15'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['16', '15'],
        priceFromEntry: 2800,
        tripTime: ['18', '45'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['16', '15'],
        priceFromEntry: 2890,
        tripTime: ['18', '45'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['29', '45'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['16', '15'],
        priceFromEntry: 3800,
        tripTime: ['27', '45'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['16', '15'],
        priceFromEntry: 3800,
        tripTime: ['27', '45'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['16', '15'],
        priceFromEntry: 3360,
        tripTime: ['27', '45'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['16', '15'],
        priceFromEntry: 2800,
        tripTime: ['16', '45'],
      },
    ]
  },
  {
    id: 34,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Siaule',
        time: ['16', '15'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['16', '15'],
        priceFromEntry: 4100,
        tripTime: ['25', '45'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['28', '45'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['27', '45'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['29', '45'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['30', '35'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['31', '35'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['27', '45'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['29', '45'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['29', '45'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['16', '15'],
        priceFromEntry: 4500,
        tripTime: ['29', '15']
      }
    ]
  },
  {
    id: 35,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Palanha',
        time: ['13', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['13', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['13', '00'],
        priceFromEntry: 4000,
        tripTime: ['25', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['13', '00'],
        priceFromEntry: 4000,
        tripTime: ['24', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['13', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['13', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['13', '00'],
        priceFromEntry: 4200,
        tripTime: ['24', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['23', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['13', '00'],
        priceFromEntry: 3400,
        tripTime: ['22', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['13', '00'],
        priceFromEntry: 3400,
        tripTime: ['22', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['13', '00'],
        priceFromEntry: 4600,
        tripTime: ['31', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['13', '00'],
        priceFromEntry: 4600,
        tripTime: ['31', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['13', '00'],
        priceFromEntry: 4200,
        tripTime: ['31', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['13', '00'],
        priceFromEntry: 3480,
        tripTime: ['20', '00'],
      },
    ]
  },
  {
    id: 36,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Palanha',
        time: ['13', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['13', '00'],
        priceFromEntry: 4490,
        tripTime: ['29', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['13', '00'],
        priceFromEntry: 4900,
        tripTime: ['32', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['31', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['31', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['31', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['31', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['13', '00'],
        priceFromEntry: 5450,
        tripTime: ['32', '30']
      }
    ]
  },
  {
    id: 37,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Klaipeda',
        time: ['15', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['15', '00'],
        priceFromEntry: 4000,
        tripTime: ['25', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['15', '00'],
        priceFromEntry: 4000,
        tripTime: ['23', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['15', '00'],
        priceFromEntry: 4000,
        tripTime: ['22', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['15', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['15', '00'],
        priceFromEntry: 4200,
        tripTime: ['24', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['15', '00'],
        priceFromEntry: 4200,
        tripTime: ['22', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['15', '00'],
        priceFromEntry: 3480,
        tripTime: ['21', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['15', '00'],
        priceFromEntry: 3400,
        tripTime: ['20', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['15', '00'],
        priceFromEntry: 3700,
        tripTime: ['20', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['31', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 4600,
        tripTime: ['29', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 4600,
        tripTime: ['29', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['15', '00'],
        priceFromEntry: 3480,
        tripTime: ['18', '00'],
      },
    ]
  },
  {
    id: 38,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Klaipeda',
        time: ['15', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['15', '00'],
        priceFromEntry: 4490,
        tripTime: ['27', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['15', '00'],
        priceFromEntry: 4900,
        tripTime: ['30', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['29', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['31', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['31', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['32', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['29', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['29', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 5000,
        tripTime: ['29', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['15', '00'],
        priceFromEntry: 5450,
        tripTime: ['30', '30']
      }
    ]
  },
  {
    id: 39,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Pasvalis',
        time: ['17', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['17', '00'],
        priceFromEntry: 3000,
        tripTime: ['23', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['17', '00'],
        priceFromEntry: 3000,
        tripTime: ['21', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['17', '00'],
        priceFromEntry: 3000,
        tripTime: ['20', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['17', '00'],
        priceFromEntry: 3360,
        tripTime: ['24', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['17', '00'],
        priceFromEntry: 3360,
        tripTime: ['22', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['17', '00'],
        priceFromEntry: 3360,
        tripTime: ['20', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['17', '00'],
        priceFromEntry: 2800,
        tripTime: ['19', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['17', '00'],
        priceFromEntry: 2800,
        tripTime: ['18', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['17', '00'],
        priceFromEntry: 2890,
        tripTime: ['18', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['17', '00'],
        priceFromEntry: 3800,
        tripTime: ['27', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['17', '00'],
        priceFromEntry: 3800,
        tripTime: ['27', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['17', '00'],
        priceFromEntry: 3360,
        tripTime: ['27', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['17', '00'],
        priceFromEntry: 2800,
        tripTime: ['16', '00'],
      },
    ]
  },
  {
    id: 40,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Pasvalis',
        time: ['17', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['17', '00'],
        priceFromEntry: 4100,
        tripTime: ['25', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['28', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['27', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['30', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['17', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['17', '00'],
        priceFromEntry: 4000,
        tripTime: ['29', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['17', '00'],
        priceFromEntry: 4500,
        tripTime: ['28', '30'],
      },
    ]
  },
  {
    id: 41,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Alitus',
        time: ['18', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['18', '30'],
        priceFromEntry: 3000,
        tripTime: ['21', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['18', '30'],
        priceFromEntry: 3000,
        tripTime: ['20', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['18', '30'],
        priceFromEntry: 3000,
        tripTime: ['19', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['18', '30'],
        priceFromEntry: 3360,
        tripTime: ['23', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['18', '30'],
        priceFromEntry: 3360,
        tripTime: ['21', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['18', '30'],
        priceFromEntry: 3360,
        tripTime: ['19', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['18', '30'],
        priceFromEntry: 2800,
        tripTime: ['18', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['18', '30'],
        priceFromEntry: 2800,
        tripTime: ['16', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['18', '30'],
        priceFromEntry: 2890,
        tripTime: ['16', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['27', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['18', '30'],
        priceFromEntry: 3800,
        tripTime: ['25', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['18', '30'],
        priceFromEntry: 3800,
        tripTime: ['25', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['18', '30'],
        priceFromEntry: 3360,
        tripTime: ['25', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['18', '30'],
        priceFromEntry: 2800,
        tripTime: ['14', '30'],
      },
    ]
  },
  {
    id: 42,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Alitus',
        time: ['18', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['18', '30'],
        priceFromEntry: 4100,
        tripTime: ['23', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['26', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['25', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['27', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['28', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['29', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['18', '30'],
        priceFromEntry: 4000,
        tripTime: ['25', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['18', '30'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['27', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['18', '30'],
        priceFromEntry: 4500,
        tripTime: ['27', '00'],
      },
    ]
  },
  {
    id: 43,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kalvariia',
        time: ['21', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['21', '00'],
        priceFromEntry: 3000,
        tripTime: ['19', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['21', '00'],
        priceFromEntry: 3000,
        tripTime: ['17', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['21', '00'],
        priceFromEntry: 3000,
        tripTime: ['17', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['21', '00'],
        priceFromEntry: 3360,
        tripTime: ['20', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['21', '00'],
        priceFromEntry: 3360,
        tripTime: ['18', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['21', '00'],
        priceFromEntry: 3360,
        tripTime: ['16', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['21', '00'],
        priceFromEntry: 2800,
        tripTime: ['15', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['21', '00'],
        priceFromEntry: 2800,
        tripTime: ['14', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['21', '00'],
        priceFromEntry: 2890,
        tripTime: ['14', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['25', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['21', '00'],
        priceFromEntry: 3800,
        tripTime: ['23', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['21', '00'],
        priceFromEntry: 3800,
        tripTime: ['23', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['21', '00'],
        priceFromEntry: 3360,
        tripTime: ['23', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['21', '00'],
        priceFromEntry: 2800,
        tripTime: ['12', '00'],
      },
    ]
  },
  {
    id: 44,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Kalvariia',
        time: ['21', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['21', '00'],
        priceFromEntry: 4100,
        tripTime: ['21', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['24', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['23', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['25', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['25', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['26', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['21', '00'],
        priceFromEntry: 4000,
        tripTime: ['23', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['21', '00'],
        priceFromEntry: 4000,
        tripTime: ['25', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['25', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['21', '00'],
        priceFromEntry: 4500,
        tripTime: ['24', '30'],
      },
    ]
  },

  //From Latvia
  {
    id: 45,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Bauska',
        time: ['16', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['16', '00'],
        priceFromEntry: 3200,
        tripTime: ['24', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['16', '00'],
        priceFromEntry: 3200,
        tripTime: ['22', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['16', '00'],
        priceFromEntry: 3200,
        tripTime: ['21', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['16', '00'],
        priceFromEntry: 3800,
        tripTime: ['25', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['16', '00'],
        priceFromEntry: 3800,
        tripTime: ['23', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['16', '00'],
        priceFromEntry: 3800,
        tripTime: ['21', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['16', '00'],
        priceFromEntry: 3000,
        tripTime: ['20', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['16', '00'],
        priceFromEntry: 3000,
        tripTime: ['19', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['16', '00'],
        priceFromEntry: 3300,
        tripTime: ['19', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['16', '00'],
        priceFromEntry: 4600,
        tripTime: ['28', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['16', '00'],
        priceFromEntry: 4600,
        tripTime: ['28', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['16', '00'],
        priceFromEntry: 3800,
        tripTime: ['28', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['16', '00'],
        priceFromEntry: 3480,
        tripTime: ['17', '00'],
      },
    ]
  },
  {
    id: 46,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Bauska',
        time: ['16', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['16', '00'],
        priceFromEntry: 4490,
        tripTime: ['26', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['16', '00'],
        priceFromEntry: 4900,
        tripTime: ['29', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['28', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['28', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['16', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['16', '00'],
        priceFromEntry: 4500,
        tripTime: ['29', '30'],
      },
    ]
  },
  {
    id: 47,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Riga',
        time: ['15', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['15', '00'],
        priceFromEntry: 3000,
        tripTime: ['25', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['15', '00'],
        priceFromEntry: 3000,
        tripTime: ['23', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['15', '00'],
        priceFromEntry: 3000,
        tripTime: ['22', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['15', '00'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['15', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['15', '00'],
        priceFromEntry: 3800,
        tripTime: ['22', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['15', '00'],
        priceFromEntry: 2200,
        tripTime: ['21', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['15', '00'],
        priceFromEntry: 2200,
        tripTime: ['20', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['15', '00'],
        priceFromEntry: 3300,
        tripTime: ['20', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 4600,
        tripTime: ['29', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 45046000,
        tripTime: ['29', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 3800,
        tripTime: ['29', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['15', '00'],
        priceFromEntry: 3480,
        tripTime: ['18', '00'],
      },
    ]
  },
  {
    id: 48,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Riga',
        time: ['15', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['15', '00'],
        priceFromEntry: 4490,
        tripTime: ['27', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['29', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['32', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['29', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['15', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '30'],
      },
    ]
  },
  {
    id: 49,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Yetsava',
        time: ['15', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['15', '30'],
        priceFromEntry: 3000,
        tripTime: ['24', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['15', '30'],
        priceFromEntry: 3000,
        tripTime: ['23', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['15', '30'],
        priceFromEntry: 3000,
        tripTime: ['22', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['15', '30'],
        priceFromEntry: 3800,
        tripTime: ['26', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['15', '30'],
        priceFromEntry: 3800,
        tripTime: ['24', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['15', '30'],
        priceFromEntry: 3800,
        tripTime: ['22', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['15', '30'],
        priceFromEntry: 3480,
        tripTime: ['21', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['15', '30'],
        priceFromEntry: 2200,
        tripTime: ['19', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['15', '30'],
        priceFromEntry: 3300,
        tripTime: ['19', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['30', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['15', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['15', '30'],
        priceFromEntry: 4600,
        tripTime: ['28', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['15', '30'],
        priceFromEntry: 3800,
        tripTime: ['28', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['15', '30'],
        priceFromEntry: 3480,
        tripTime: ['17', '30'],
      },
    ]
  },
  {
    id: 50,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Yetsava',
        time: ['15', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['15', '30'],
        priceFromEntry: 4490,
        tripTime: ['26', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['29', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['28', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['30', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['31', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['32', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['28', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['30', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['30', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['15', '30'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
    ]
  },
  {
    id: 51,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Yelhava',
        time: ['14', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['14', '00'],
        priceFromEntry: 3000,
        tripTime: ['25', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['14', '00'],
        priceFromEntry: 3000,
        tripTime: ['23', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['14', '00'],
        priceFromEntry: 3000,
        tripTime: ['22', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['14', '00'],
        priceFromEntry: 3800,
        tripTime: ['26', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['14', '00'],
        priceFromEntry: 3800,
        tripTime: ['24', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['14', '00'],
        priceFromEntry: 3800,
        tripTime: ['22', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['14', '00'],
        priceFromEntry: 3480,
        tripTime: ['21', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 2200,
        tripTime: ['20', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['14', '00'],
        priceFromEntry: 3300,
        tripTime: ['20', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['14', '00'],
        priceFromEntry: 4600,
        tripTime: ['29', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['14', '00'],
        priceFromEntry: 4600,
        tripTime: ['29', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['14', '00'],
        priceFromEntry: 3800,
        tripTime: ['29', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['14', '00'],
        priceFromEntry: 3480,
        tripTime: ['18', '00'],
      },
    ]
  },
  {
    id: 52,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Yelhava',
        time: ['14', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['14', '00'],
        priceFromEntry: 4490,
        tripTime: ['28', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['32', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['32', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['33', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['30', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['32', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['32', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['14', '00'],
        priceFromEntry: 4890,
        tripTime: ['31', '30'],
      },
    ]
  },

  //From Estonia
  {
    id: 53,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Parnu',
        time: ['12', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['12', '30'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['12', '30'],
        priceFromEntry: 4000,
        tripTime: ['26', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['12', '30'],
        priceFromEntry: 4000,
        tripTime: ['25', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['27', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['25', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['12', '30'],
        priceFromEntry: 3200,
        tripTime: ['24', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['12', '30'],
        priceFromEntry: 3400,
        tripTime: ['22', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['12', '30'],
        priceFromEntry: 3700,
        tripTime: ['22', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 4600,
        tripTime: ['31', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 4600,
        tripTime: ['31', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['12', '30'],
        priceFromEntry: 3480,
        tripTime: ['20', '30'],
      },
    ]
  },
  {
    id: 54,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Parnu',
        time: ['12', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['12', '30'],
        priceFromEntry: 4490,
        tripTime: ['29', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['12', '30'],
        priceFromEntry: 4900,
        tripTime: ['32', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['31', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['34', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['31', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
    ]
  },
  {
    id: 55,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Tartu',
        time: ['10', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['30', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['10', '00'],
        priceFromEntry: 3700,
        tripTime: ['26', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4600,
        tripTime: ['34', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4600,
        tripTime: ['34', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['34', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['10', '00'],
        priceFromEntry: 3480,
        tripTime: ['23', '00'],
      },
    ]
  },
  {
    id: 56,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: "Tartu",
        time: ['10', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: "Cherkasy",
        arrivingTime: ['18', '00'],
        time: ['10', '00'],
        priceFromEntry: 4490,
        tripTime: ['32', '00'],
      },
      {
        point: 2,
        cityName: "Kremenchuk",
        arrivingTime: ['21', '00'],
        time: ['10', '00'],
        priceFromEntry: 4900,
        tripTime: ['35', '00'],
      },
      {
        point: 3,
        cityName: "Poltava",
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 4,
        cityName: "Kharkiv",
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 5,
        cityName: "Dnipro",
        arrivingTime: ['22', '50'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '50'],
      },
      {
        point: 6,
        cityName: "Zaporizhzhia",
        arrivingTime: ['23', '50'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '50'],
      },
      {
        point: 7,
        cityName: "Odesa",
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 8,
        cityName: "Mykolaiv",
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 9,
        cityName: "Kherson",
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
    ]
  },
  {
    id: 57,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Tallinn',
        time: ['11', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['11', '00'],
        priceFromEntry: 4000,
        tripTime: ['29', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['11', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['11', '00'],
        priceFromEntry: 4000,
        tripTime: ['26', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['30', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['11', '00'],
        priceFromEntry: 3200,
        tripTime: ['25', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['11', '00'],
        priceFromEntry: 3400,
        tripTime: ['24', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['11', '00'],
        priceFromEntry: 3700,
        tripTime: ['24', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['33', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['11', '00'],
        priceFromEntry: 3480,
        tripTime: ['22', '00'],
      },
    ]
  },
  {
    id: 58,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Tallinn',
        time: ['11', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['11', '00'],
        priceFromEntry: 4490,
        tripTime: ['31', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['11', '00'],
        priceFromEntry: 4900,
        tripTime: ['34', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '30'],
      },
    ]
  },
  {
    id: 59,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Vyru',
        time: ['08', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['32', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['30', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['29', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['08', '00'],
        priceFromEntry: 3480,
        tripTime: ['28', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['08', '00'],
        priceFromEntry: 3400,
        tripTime: ['27', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['08', '00'],
        priceFromEntry: 3400,
        tripTime: ['27', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4600,
        tripTime: ['36', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4600,
        tripTime: ['36', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['36', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['08', '00'],
        priceFromEntry: 3480,
        tripTime: ['25', '00'],
      },
    ]
  },
  {
    id: 60,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Vyru',
        time: ['08', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['08', '00'],
        priceFromEntry: 4490,
        tripTime: ['34', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['08', '00'],
        priceFromEntry: 4900,
        tripTime: ['37', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
    ]
  },
  {
    id: 61,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Pyltsaama',
        time: ['08', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['08', '30'],
        priceFromEntry: 4000,
        tripTime: ['31', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['08', '30'],
        priceFromEntry: 4000,
        tripTime: ['30', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['08', '30'],
        priceFromEntry: 4000,
        tripTime: ['29', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['08', '30'],
        priceFromEntry: 3480,
        tripTime: ['28', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['08', '30'],
        priceFromEntry: 3400,
        tripTime: ['26', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['08', '30'],
        priceFromEntry: 3700,
        tripTime: ['26', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['08', '30'],
        priceFromEntry: 4600,
        tripTime: ['35', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['08', '30'],
        priceFromEntry: 4600,
        tripTime: ['35', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['08', '30'],
        priceFromEntry: 4200,
        tripTime: ['35', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['08', '30'],
        priceFromEntry: 3480,
        tripTime: ['24', '30'],
      },
    ]
  },
  {
    id: 62,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Pyltsaama',
        time: ['08', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['08', '30'],
        priceFromEntry: 4490,
        tripTime: ['33', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['08', '30'],
        priceFromEntry: 4900,
        tripTime: ['36', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['39', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['08', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '00'],
      },
    ]
  },
  {
    id: 63,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Paide',
        time: ['10', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['10', '00'],
        priceFromEntry: 4000,
        tripTime: ['30', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['10', '00'],
        priceFromEntry: 4000,
        tripTime: ['28', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['10', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['10', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4600,
        tripTime: ['34', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4600,
        tripTime: ['34', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['34', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['10', '00'],
        priceFromEntry: 3480,
        tripTime: ['23', '00'],
      },
    ]
  },
  {
    id: 64,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Paide',
        time: ['10', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['10', '00'],
        priceFromEntry: 4490,
        tripTime: ['32', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['10', '00'],
        priceFromEntry: 4900,
        tripTime: ['35', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
    ]
  },
  {
    id: 65,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Viliandi',
        time: ['12', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['28', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['26', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['12', '00'],
        priceFromEntry: 4000,
        tripTime: ['25', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['25', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['12', '00'],
        priceFromEntry: 3480,
        tripTime: ['24', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: 3400,
        tripTime: ['23', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['12', '00'],
        priceFromEntry: 3700,
        tripTime: ['23', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['12', '00'],
        priceFromEntry: 4600,
        tripTime: ['32', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['12', '00'],
        priceFromEntry: 4600,
        tripTime: ['32', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['12', '00'],
        priceFromEntry: 4200,
        tripTime: ['32', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['12', '00'],
        priceFromEntry: 3480,
        tripTime: ['21', '00'],
      },
    ]
  },
  {
    id: 66,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Viliandi',
        time: ['12', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['12', '00'],
        priceFromEntry: 4490,
        tripTime: ['30', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['12', '00'],
        priceFromEntry: 4900,
        tripTime: ['33', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['32', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['32', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['12', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
    ]
  },
  {
    id: 67,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Keila',
        time: ['10', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['10', '00'],
        priceFromEntry: 4000,
        tripTime: ['30', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['10', '00'],
        priceFromEntry: 4000,
        tripTime: ['28', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['10', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['27', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['10', '00'],
        priceFromEntry: 3480,
        tripTime: ['26', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3400,
        tripTime: ['25', '00'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['10', '00'],
        priceFromEntry: 3700,
        tripTime: ['25', '00'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4600,
        tripTime: ['34', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4600,
        tripTime: ['34', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 4200,
        tripTime: ['34', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['10', '00'],
        priceFromEntry: 3480,
        tripTime: ['23', '00'],
      },
    ]
  },
  {
    id: 68,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Keila',
        time: ['10', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['10', '00'],
        priceFromEntry: 4490,
        tripTime: ['32', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['10', '00'],
        priceFromEntry: 4900,
        tripTime: ['35', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['10', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
    ]
  },
  {
    id: 69,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Mariamma',
        time: ['12', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['12', '30'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['12', '30'],
        priceFromEntry: 4000,
        tripTime: ['26', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['12', '30'],
        priceFromEntry: 4000,
        tripTime: ['25', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['27', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['25', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['12', '30'],
        priceFromEntry: 3480,
        tripTime: ['24', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['12', '30'],
        priceFromEntry: 3400,
        tripTime: ['25', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['12', '30'],
        priceFromEntry: 3700,
        tripTime: ['25', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 4600,
        tripTime: ['31', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 4600,
        tripTime: ['31', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['12', '30'],
        priceFromEntry: 3480,
        tripTime: ['20', '30'],
      },
    ]
  },
  {
    id: 70,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Mariamma',
        time: ['12', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['12', '30'],
        priceFromEntry: 4490,
        tripTime: ['29', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['12', '30'],
        priceFromEntry: 4900,
        tripTime: ['32', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['31', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['34', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['31', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['12', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
    ]
  },
  {
    id: 71,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Elva',
        time: ['09', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['09', '30'],
        priceFromEntry: 4000,
        tripTime: ['30', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['09', '30'],
        priceFromEntry: 4000,
        tripTime: ['29', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['09', '30'],
        priceFromEntry: 4000,
        tripTime: ['28', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['09', '30'],
        priceFromEntry: 4200,
        tripTime: ['32', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['09', '30'],
        priceFromEntry: 4200,
        tripTime: ['30', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['09', '30'],
        priceFromEntry: 4200,
        tripTime: ['28', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['09', '30'],
        priceFromEntry: 3480,
        tripTime: ['27', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['09', '30'],
        priceFromEntry: 3400,
        tripTime: ['25', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['09', '30'],
        priceFromEntry: 3400,
        tripTime: ['25', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['36', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['09', '30'],
        priceFromEntry: 4600,
        tripTime: ['34', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['09', '30'],
        priceFromEntry: 4600,
        tripTime: ['34', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['09', '30'],
        priceFromEntry: 4200,
        tripTime: ['34', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['09', '30'],
        priceFromEntry: 3480,
        tripTime: ['23', '30'],
      },
    ]
  },
  {
    id: 72,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Elva',
        time: ['09', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['09', '30'],
        priceFromEntry: 4490,
        tripTime: ['32', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['09', '30'],
        priceFromEntry: 4900,
        tripTime: ['35', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['34', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['36', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['38', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['34', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['36', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['36', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['09', '30'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
    ]
  },
  {
    id: 73,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Pylva',
        time: ['08', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['32', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['30', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['29', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['08', '00'],
        priceFromEntry: 3480,
        tripTime: ['28', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['08', '00'],
        priceFromEntry: 3400,
        tripTime: ['27', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['08', '00'],
        priceFromEntry: 3700,
        tripTime: ['27', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4600,
        tripTime: ['36', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4600,
        tripTime: ['36', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['36', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['08', '00'],
        priceFromEntry: 3480,
        tripTime: ['25', '00'],
      },
    ]
  },
  {
    id: 74,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Pylva',
        time: ['08', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['08', '00'],
        priceFromEntry: 4490,
        tripTime: ['34', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['08', '00'],
        priceFromEntry: 4900,
        tripTime: ['37', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
    ]
  },
  {
    id: 75,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Yyheva',
        time: ['10', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['10', '30'],
        priceFromEntry: 4000,
        tripTime: ['29', '30'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['10', '30'],
        priceFromEntry: 4000,
        tripTime: ['28', '00'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['10', '30'],
        priceFromEntry: 4000,
        tripTime: ['27', '00'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['10', '30'],
        priceFromEntry: 4200,
        tripTime: ['31', '00'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['10', '30'],
        priceFromEntry: 4200,
        tripTime: ['29', '00'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['10', '30'],
        priceFromEntry: 4200,
        tripTime: ['27', '00'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['10', '30'],
        priceFromEntry: 3480,
        tripTime: ['26', '00'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['10', '30'],
        priceFromEntry: 3400,
        tripTime: ['24', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['10', '30'],
        priceFromEntry: 3700,
        tripTime: ['24', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['10', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['10', '30'],
        priceFromEntry: 4600,
        tripTime: ['33', '30'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['10', '30'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['10', '30'],
        priceFromEntry: 3480,
        tripTime: ['22', '30'],
      },
    ]
  },
  {
    id: 76,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Yyheva',
        time: ['10', '30'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['10', '30'],
        priceFromEntry: 4490,
        tripTime: ['31', '30'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['10', '30'],
        priceFromEntry: 4900,
        tripTime: ['34', '30'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['36', '20'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['37', '20'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['33', '30'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '30'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['10', '30'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
    ]
  },
  {
    id: 77,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Valha',
        time: ['08', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['32', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['30', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['08', '00'],
        priceFromEntry: 4000,
        tripTime: ['29', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['33', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['31', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['29', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['08', '00'],
        priceFromEntry: 3480,
        tripTime: ['28', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['08', '00'],
        priceFromEntry: 3400,
        tripTime: ['27', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['08', '00'],
        priceFromEntry: 3700,
        tripTime: ['27', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4600,
        tripTime: ['36', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4600,
        tripTime: ['36', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 4200,
        tripTime: ['36', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['08', '00'],
        priceFromEntry: 3480,
        tripTime: ['25', '00'],
      },
    ]
  },
  {
    id: 78,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Valha',
        time: ['08', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['08', '00'],
        priceFromEntry: 4490,
        tripTime: ['34', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['08', '00'],
        priceFromEntry: 4900,
        tripTime: ['37', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['39', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['38', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['08', '00'],
        priceFromEntry: 5450,
        tripTime: ['37', '30'],
      },
    ]
  },
  {
    id: 79,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Tiuri',
        time: ['11', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['11', '00'],
        priceFromEntry: 4000,
        tripTime: ['29', '00'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['11', '00'],
        priceFromEntry: 4000,
        tripTime: ['27', '30'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['11', '00'],
        priceFromEntry: 4000,
        tripTime: ['26', '30'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['30', '30'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['28', '30'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['26', '30'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['11', '00'],
        priceFromEntry: 3480,
        tripTime: ['25', '30'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['11', '00'],
        priceFromEntry: 3400,
        tripTime: ['24', '30'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['11', '00'],
        priceFromEntry: 3700,
        tripTime: ['24', '30'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 4600,
        tripTime: ['33', '00'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 4200,
        tripTime: ['33', '00'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['11', '00'],
        priceFromEntry: 3480,
        tripTime: ['22', '00'],
      },
    ]
  },
  {
    id: 80,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Tiuri',
        time: ['11', '00'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['11', '00'],
        priceFromEntry: 4490,
        tripTime: ['31', '00'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['11', '00'],
        priceFromEntry: 4900,
        tripTime: ['34', '00'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '50'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['36', '50'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['33', '00'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['35', '00'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['11', '00'],
        priceFromEntry: 5450,
        tripTime: ['34', '30'],
      },
    ]
  },
  {
    id: 81,
    isEveryDay: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Tabivere',
        time: ['10', '40'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Kyiv',
        arrivingTime: ['16', '00'],
        time: ['10', '40'],
        priceFromEntry: 4000,
        tripTime: ['29', '20'],
      },
      {
        point: 2,
        cityName: 'Zhytomyr',
        arrivingTime: ['14', '30'],
        time: ['10', '40'],
        priceFromEntry: 4000,
        tripTime: ['27', '50'],
      },
      {
        point: 3,
        cityName: 'Zvyagel',
        arrivingTime: ['13', '30'],
        time: ['10', '40'],
        priceFromEntry: 4000,
        tripTime: ['26', '50'],
      },
      {
        point: 4,
        cityName: 'Vinnytsia',
        arrivingTime: ['17', '30'],
        time: ['10', '40'],
        priceFromEntry: 4200,
        tripTime: ['30', '50'],
      },
      {
        point: 5,
        cityName: 'Khmelnytskyi',
        arrivingTime: ['15', '30'],
        time: ['10', '40'],
        priceFromEntry: 4200,
        tripTime: ['28', '50'],
      },
      {
        point: 6,
        cityName: 'Ternopil',
        arrivingTime: ['13', '30'],
        time: ['10', '40'],
        priceFromEntry: 4200,
        tripTime: ['26', '50'],
      },
      {
        point: 7,
        cityName: 'Rivne',
        arrivingTime: ['12', '30'],
        time: ['10', '40'],
        priceFromEntry: 3480,
        tripTime: ['25', '50'],
      },
      {
        point: 8,
        cityName: 'Lutsk',
        arrivingTime: ['11', '00'],
        time: ['10', '40'],
        priceFromEntry: 3400,
        tripTime: ['24', '50'],
      },
      {
        point: 9,
        cityName: 'Lviv',
        arrivingTime: ['11', '00'],
        time: ['10', '40'],
        priceFromEntry: 3700,
        tripTime: ['24', '50'],
      },
      {
        point: 10,
        cityName: 'Kropyvnytskyi',
        arrivingTime: ['22', '00'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['35', '20'],
      },
      {
        point: 11,
        cityName: 'Uman',
        arrivingTime: ['20', '00'],
        time: ['10', '40'],
        priceFromEntry: 4600,
        tripTime: ['33', '20'],
      },
      {
        point: 12,
        cityName: 'Haisyn',
        arrivingTime: ['20', '00'],
        time: ['10', '40'],
        priceFromEntry: 4600,
        tripTime: ['33', '20'],
      },
      {
        point: 13,
        cityName: 'Nemyriv',
        arrivingTime: ['20', '00'],
        time: ['10', '40'],
        priceFromEntry: 4200,
        tripTime: ['33', '20'],
      },
      {
        point: 14,
        cityName: 'Dubno',
        arrivingTime: ['09', '00'],
        time: ['10', '40'],
        priceFromEntry: 3480,
        tripTime: ['22', '20'],
      },
    ]
  },
  {
    id: 82,
    isEveryDay: false,
    daysOfWeek: [0, 3, 5],
    promoConditions: {},
    points: [
      {
        point: 0,
        cityName: 'Tabivere',
        time: ['10', '40'],
        priceFromEntry: null,
        tripTime: [],
      },

      //Ukraine
      {
        point: 1,
        cityName: 'Cherkasy',
        arrivingTime: ['18', '00'],
        time: ['10', '40'],
        priceFromEntry: 4490,
        tripTime: ['31', '20'],
      },
      {
        point: 2,
        cityName: 'Kremenchuk',
        arrivingTime: ['21', '00'],
        time: ['10', '40'],
        priceFromEntry: 4900,
        tripTime: ['34', '20'],
      },
      {
        point: 3,
        cityName: 'Poltava',
        arrivingTime: ['20', '00'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['33', '20'],
      },
      {
        point: 4,
        cityName: 'Kharkiv',
        arrivingTime: ['22', '00'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['35', '20'],
      },
      {
        point: 5,
        cityName: 'Dnipro',
        arrivingTime: ['22', '50'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['37', '10'],
      },
      {
        point: 6,
        cityName: 'Zaporizhzhia',
        arrivingTime: ['23', '50'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['37', '10'],
      },
      {
        point: 7,
        cityName: 'Odesa',
        arrivingTime: ['20', '00'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['33', '20'],
      },
      {
        point: 8,
        cityName: 'Mykolaiv',
        arrivingTime: ['22', '00'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['35', '20'],
      },
      {
        point: 9,
        cityName: 'Kherson',
        arrivingTime: ['22', '00'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['35', '20'],
      },
      {
        point: 10,
        cityName: 'Olexandriya',
        arrivingTime: ['21', '30'],
        time: ['10', '40'],
        priceFromEntry: 5450,
        tripTime: ['34', '50'],
      },
    ]
  },
];
