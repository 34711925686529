import React, {useState} from "react";
import "./BusPark.scss";

// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";

// import required modules
import {FreeMode, Navigation, Thumbs, Pagination} from "swiper";

const listItems = [
  "Зручні сидіння з регулюванням;",
  "Регулюючі столики з підстаканниками для вашого напою;",
  "Клімат контроль, кондиціонер, автономне опалення салону;",
  "TV та WiFi для різноманіття поїздки;",
  "USB-роз’єми для підзарядки пристроів, 220 вольт;",
  "Туалет в автобусі;",
  "Містке багажне відділення;",
  "В автобусі наявні пледи, подушечки."
];

const slideItem = [
  "slide1",
  "slide2",
  "slide3",
  "slide4",
  "slide5",
  "slide6",
  "slide7",
  "slide8",
  "slide9",
  "slide10",
];

export const BusPark = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="buspark" id="bus-park">
      {/*#region CAROUSEL*/}
      <div className="buspark__slider">
        <Swiper
          spaceBetween={10}
          navigation={true}
          thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed
              ? thumbsSwiper : null}}
          modules={[FreeMode, Navigation, Thumbs, Pagination]}
          className="mySwiper2"
          loop={true}
          pagination={
            {
              clickable: true
            }
          }
        >
          {slideItem.map((img, i) => (
            <SwiperSlide
              className="buspark__slider-slide"
            >
              <img
                className="buspark__slider-img"
                src={`./assets/images/carouselBusPark/${img}.webp`}
                alt={img}
                key={'${img.substring(0, 3)}-${i}'}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
          loop={true}
        >
          {slideItem.map((img, i) => (
            <SwiperSlide
              className="buspark__slider-slide"
            >
              <img
                className="buspark__slider-img"
                src={`./assets/images/carouselBusPark/${img}.webp`}
                alt={img}
                key={'${img.substring(0, 3)}-${i}'}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/*#end region*/}

      <div className="buspark__park">
        <h1 className="buspark__park-title">
          Автопарк <span className="no-wrap">SEM BUS</span>
        </h1>
        <p className="buspark__park-text">
          Автопарк нашої компанії складається тільки з сучасних комфортабельних автобусів SETRA  Євро 5/6  на яких поїздки запам’ятовуються виключним комфортом.
          Це вже відчувається при посадці в автобус. Велика кількість продуманих деталей доповнює картину
        </p>

        <span className="buspark__park-list-title">
          А саме:
        </span>

        <ul className="buspark__park-list">
          {listItems.map((item, i) => (
            <li
              className="buspark__park-list-item"
              key={i}
            >
              {item}
            </li>
          ))}
        </ul>

      </div>
    </div>
  );
};
