import React, {useEffect, useState} from 'react';
import ChatLeftSidebar from "../ChatLeftSidebar/ChatLeftSidebar";
import UserChat from "../UserChat/UserChat";
import {
  getAccountContactsList,
  listenToAllMessagesForAdmin
} from "../../utils/helpers";
import './AdminChat.scss';

const AdminChat = () => {
  //#region Variable for listener unsubscribe
  const [unsubscribe, setUnsubscribe] = useState([]);
  //#endregion

  //#region Contacts list and sound processing
  const [contactsList, setContactsList] = useState([]);

  const [countUpdates, setCountUpdates] = useState(0);

  const [lastUpdatedDataChunk, setLastUpdatedDataChunk] = useState({});

  function changeCountAndCurrentAccountMessages(data, accountId) {
    if (data) {
      const newData = {
        [accountId]: data,
      }
      setLastUpdatedDataChunk(newData);
      setCountUpdates(count => count + 1);
    }
  };

  async function changeContactsList(data) {
    if (data) {
      let contacts = [];

      try {
        contacts = await getAccountContactsList('admin');

        setContactsList(contacts);

      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    changeContactsList(true).catch(e => console.log(e));
  }, [countUpdates]);
  //#endregion

  //#region Launch listener
  useEffect(() => {
    let curRef = listenToAllMessagesForAdmin(
      changeCountAndCurrentAccountMessages,
      'admin');

    setUnsubscribe(state => [...state, curRef]);

    return () => {
      try {
        unsubscribe.forEach(unsubRef => {
          unsubRef.off();
        });
      } catch (error) {
        console.log(error);
      }

      setUnsubscribe([]);
    };
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <div className='admin-chat'>
        <ChatLeftSidebar
          contactsList={contactsList}
        />
        <UserChat
          contactsList={contactsList}
        />
      </div>
    </>
  );
  //#endregion
};

export default AdminChat;
