import React, {useContext, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import ModalNotification from "../../modals/ModalNotification/ModalNotification";
import {userModel} from "../../utils/models";
import {AppContext} from "../../context/appContext";
import {
  registerUser,
  signInWithGoogleButtonClickHandler,
} from "../../utils/helpers";
import {
  auth,
  confirmCodeAndSignInWithPhone,
  sendCodeToSignInWithPhone
} from "../../utils/firebaseConfigAndFunctions";
import InputMask from "react-input-mask";
import './RegistrationForm.scss';

const RegistrationForm = ({addClasses}) => {
  //#region Get navigation
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {setUser} = useContext(AppContext);
  //#endregion

  //#region Update userAccount data
  const [userAccountData, setUserAccountData] = useState(userModel);

  function updateAccountData(fieldName, newValue) {
    setUserAccountData(data => ({
      ...data,
      [fieldName]: newValue,
    }))
  }

  //#endregion

  //#region Notification
  const [notification, setNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Submit form and register new user
  function setUserAfterReg(userData) {
    setUser(userData);
  };

  async function registerNewUser(event, accountData) {
    event.preventDefault();

    if (accountData.password !== accountData.repeatPassword) {
      setNotification(true);
      setNotificationTitle('Error in form filling');
      setNotificationMessage('Passwords don\'t match');

      return;
    }

    try {
      await registerUser(accountData.fullName, accountData.email,
        accountData.password, setUserAfterReg);

      navigate('/profile');
    } catch (error) {
      console.log(error);
    }
  }
  //#endregion

  //#region Switch to phone auth and back, handle phone auth
  const [isPhoneAuth, setIsPhoneAuth] = useState(false);
  //#endregion

  //#region Phone auth
  const [phoneToAuth, setPhoneToAuth] = useState('');
  const [isFieldForCodeVisible, setIsFieldForCodeVisible] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState({});

  const signInViaPhoneNumber = async () => {
    try {
      await confirmCodeAndSignInWithPhone(confirmationResult, verificationCode,
        setUser);

      navigate('/profile');
    } catch (error) {
      console.log(error);
    }
  }
  //#endregion


  return (
    <>
      <div className={`registration-form-container`}>
        {!isPhoneAuth ?
          <form
            className={`registration-form ${addClasses}`}
            onSubmit={(event) => registerNewUser(event,
              userAccountData)}
          >
            <h2
              className={'registration-form__title'}
            >
              Створити акаунт
            </h2>

            <input
              type="text"
              className={'regular-form-control registration-form__item'}
              placeholder={'Повне ім\'я'}
              value={userAccountData.fullName}
              onChange={(event) => updateAccountData('fullName',
                event.target.value)}
              required
            />

            <input
              type="email"
              className={'regular-form-control registration-form__item'}
              placeholder="Електронна пошта"
              value={userAccountData.email}
              onChange={(event) => updateAccountData('email',
                event.target.value)}
              required
            />

            <input
              type="password"
              className={'regular-form-control registration-form__item'}
              placeholder="Пароль"
              value={userAccountData.password || ''}
              onChange={(event) => updateAccountData('password',
                event.target.value)}
              required
            />

            <input
              type="password"
              placeholder="Повторити пароль"
              className={'regular-form-control registration-form__item'}
              value={userAccountData.repeatPassword || ''}
              onChange={(event) => updateAccountData('repeatPassword',
                event.target.value)}
              required
            />

            <button
              type="submit"
              className={'regular-button registration-form__item registration-form__email-sign-up-button'}
            >
              Зареєструватися
            </button>

            <div className={'buttons-container registration-form__item'}>
              <button
                type={'button'}
                className={'regular-button button-with-icon ' +
                  'registration-form__phone-sign-in-button'}
                onClick={() => setIsPhoneAuth(true)}
              >
                <span>
                  Вхід
                </span>

                <img
                  src={'assets/images/cell-phone-icon.svg'}
                  className={'phone-icon'}
                />
              </button>

              <button
                className={'regular-button button-with-icon ' +
                  'registration-form__google-sign-in-button'}
                onClick={(event) => signInWithGoogleButtonClickHandler(event,
                  setUser, navigate)}
              >
                <span>
                  Вхід
                </span>

                <img
                  src={'assets/images/logo_google_g_icon.svg'}
                  className={'icon-in-button button-with-icon_icon'}
                />
              </button>
            </div>

            <div className={'registration-form__links-container'}>
              Вже маєте акаунт?
              <Link to="/login" className={'registration-form__signin-link'}>
                Увійти
              </Link>
            </div>
          </form>
          :
          <div className={'registration-form__phone'}>
            <h4
              className={'registration-form__title'}
            >
              Вхід в особистий кабінет
            </h4>

            <InputMask
              mask="+380 \ 99 999 99 99"
              maskChar=" "
              placeholder="Телефон"
              required
              className={'regular-form-control login-form__item'}
              onChange={(event => setPhoneToAuth(event.target.value))}
              disabled={isFieldForCodeVisible}
            />

            {!isFieldForCodeVisible &&
              <button
                onClick={() => sendCodeToSignInWithPhone(auth, phoneToAuth,
                  setIsFieldForCodeVisible, setConfirmationResult)}
                className={'regular-button login-form__item ' +
                  'login-form__send-code-button'}
              >
                Відправити код
              </button>
            }
            <div id={'recaptcha-container'}></div>

            {isFieldForCodeVisible &&
              <>
                <input
                  type={'text'}
                  className={'regular-form-control login-form__item'}
                  onChange={(event) => setVerificationCode(
                    event.target.value)}
                />

                <button
                  onClick={() => signInViaPhoneNumber()}
                  type={'button'}
                  className={'login-form__item regular-button ' +
                    'login-form__confirm-code-button'}
                >
                  Підтвердити код
                </button>
              </>
            }

            <button
              type={'button'}
              className={'regular-button login-form__go-back-button login-form__item'}
              onClick={() => setIsPhoneAuth(false)}
            >
              Назад
            </button>
          </div>
        }
      </div>

      {/*#region privacy and security*/}
      <p className="registration-form__privacy">
        {'Натискаючи на "Реєстрація", ви автоматично погоджуєтесь з нашими '}
        <a
          target='_blank'
          href="terms-of-use"
        >
          Умовами використання
        </a>
        {' і '}
        <a
          target='_blank'
          href="privacy-policy"
        >
          Політикою конфіденційності
        </a>
      </p>
      {/*#endregion*/}

      {notification &&
        <ModalNotification
          showModal={notification}
          setShowModal={setNotification}
          notificationTitle={notificationTitle}
          notificationText={notificationMessage}
        />
      }
    </>
  );
};

export default RegistrationForm;
