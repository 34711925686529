import {Drawer} from "antd";
import React, {useContext, useState} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";
import {AppContext} from "../context/appContext";
import {logOut} from "../utils/firebaseConfigAndFunctions";
import {SocialBar} from "../components/SocialBar/SocialBar";
import StoreBadge from 'react-store-badge';
import {useNavigate} from "react-router-dom";

export const Sidebar = (props) => {
  const {setOpenSidebar, openSidebar, setAreSearchResultsVisible} = props;
  const socialsList = ["fb", "insta", "viber", "whatsapp", "telegram"];

  const menuItems = {
    home: 'home',
    profile: 'profile',
  }

  const [currentItem, setCurrentItem] = useState(menuItems.home);

  const {isLoggedIn} = useContext(AppContext);

  const navigate = useNavigate();

  //#region Render
  return (
    <Drawer
      title={
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "50px",
          flexWrap: "wrap",
          gap: "20px"
        }}>

          <ScrollLink
            to="main"
            className="header__list-link"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => {
              setCurrentItem(menuItems.home);
              setAreSearchResultsVisible(false);
            }}
          >
            <img
              className="header__logo"
              src="./assets/images/logo.svg"
              alt="logo"
            />
          </ScrollLink>

          <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
            {!isLoggedIn
              ? (<>
                  <div className="header__login-item header__login-item-white">
                    <Link
                      to={'/login'}
                      onClick={() => setOpenSidebar(!openSidebar)}
                    >
                      Вхід
                    </Link>
                  </div>


                  <div className="header__login-item">
                    <Link
                      to={'/register'}
                      onClick={() => setOpenSidebar(!openSidebar)}
                    >
                      Реєстрація
                    </Link>
                  </div>
                </>
              ) : (<>
                <div className="header__login-item header__login-item-white">
                  <Link
                    to={'/'}
                    onClick={() => logOut()}
                  >
                    Вихід
                  </Link>
                </div>
              </>)}
          </div>
        </div>
      }
      placement={'top'}
      width={"100%"}
      closable={false}
      onClose={() => setOpenSidebar(!openSidebar)}
      open={openSidebar}
      extra={
        <>
          <button
            className={classNames(
              "header__opener header__opener-open"
            )}
            onClick={(e) => setOpenSidebar(!openSidebar)}
          >
          </button>
        </>
      }
    >
      <nav>
        <ul className="header__list">
          <li
            className={currentItem === menuItems.home ?
              'header__list-item header__list-item-active' : 'header__list-item'}
            onClick={() => {
              setCurrentItem(menuItems.home);
              setOpenSidebar(!openSidebar)
            }}
          >

            <ScrollLink
              to="bus-park"
              className="header__list-link"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => {
                setOpenSidebar(!openSidebar);
                setAreSearchResultsVisible(false);
                navigate('/');
              }}
            >
              Про нас
            </ScrollLink>
          </li>

          {isLoggedIn &&
            <li
              className={currentItem === menuItems.profile ?
                'header__list-item header__list-item-active' : 'header__list-item'}
              onClick={() => {
                setCurrentItem(menuItems.profile);
                setOpenSidebar(!openSidebar)
              }}
            >
              <Link
                to="/profile"
                className="header__list-link"
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                Профіль
              </Link>
            </li>
          }


          <li
            className='header__list-item'
          >
            <a
              href="tel:+380975013093"
              className="header__list-link"
            >
              <span className="header__list-link">
                Зателефонувати
              </span>
            </a>
          </li>
        </ul>
      </nav>

      <div className="header__socials"
        style={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: "15vh"}}>
        <SocialBar
          socialsList={socialsList}
          greyIcon={true}
        />
      </div>

      <div className="header__app">
        <StoreBadge
          name="Cheerswipe"
          appStoreUrl=""
          locale={'ua-uk'}
          platform={'ios'}
          width="400"
        />

        <StoreBadge
          name="SemBusPlayMarket"
          googlePlayUrl="https://play.google.com/store/apps/details?id=app.web.sembus.pwa.twa"
          locale={'ua-uk'}
          platform={'android'}
          width="400"
        />
      </div>
    </Drawer>
  );
  //#endregion
}
