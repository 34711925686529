import React from "react";

const PrivacyPolicy = () => (
  <div className='terms-of-use'>
    <h1>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ТА COOKIES</h1>

    <p>
      Компанія SEM BUS серйозно ставиться до своєї конфіденційності та захисту
      ваших даних. У цьому документі пояснюється, як ми поводимося з вашими
      особистими даними, які ваші права і як ви можете ними скористатися.
    </p>

    <p>
      Перед реєстрацією на нашій платформі уважно прочитайте цю Політику
      конфіденційності.
    </p>

    <p>
      Відповідно до Умов використання ця політика конфіденційності описує, як
      SEM BUS обробляє та захищає ваші особисті дані під час використання
      платформи. Тут ви ("Користувач" та/або "Ви") знайдете чітку та повну
      інформацію про способи обробки, а також засоби контролю, які
      використовуються для захисту ваших персональних даних, щоб продемонструвати
      абсолютну прозорість у цьому важливому питанні.
    </p>

    <p>
      SEM BUS вживе необхідних заходів і подбає про захист усієї інформації та
      ваших особистих даних, використовуючи повні та ефективні механізми захисту
      інформації.
    </p>

    <p>
      <strong>1. Визначення</strong>
    </p>

    <p>
      Щоб спростити вам читання цього документа, ми представляємо деякі корисні
      визначення для вашої інтерпретації.
    </p>

    <p>
      Конфіденційність даних. Конфіденційність даних фокусується на правах
      окремих осіб, цілях збирання та оброблення даних, перевагах щодо
      конфіденційності та тому, як організації контролюють персональні дані
      суб'єктів даних.
    </p>

    <p>
      Безпека даних. Безпека даних належить до засобів захисту, які організація
      вживає для запобігання несанкціонованому доступу третіх осіб до вашої
      особистої інформації. Основна увага приділяється захисту даних від
      зловмисних атак і запобіганню експлуатації даних (витік даних або
      кібератака). Включає в себе контроль доступу, шифрування, а також фізичну
      і логічну мережеву безпеку.
    </p>

    <p>
      Власник або Ви: Фізична особа, до якої належать оброблювані персональні
      дані, що обробляються.
    </p>

    <p>
      Персональні дані: інформація, що стосується ідентифікованої або
      ідентифікованої фізичної особи. Це приклади персональних даних, що дають
      змогу ідентифікувати вас: ім'я, ПІБ, мобільний телефон, електронна пошта
      тощо.
    </p>

    <p>
      Конфіденційні персональні дані: особиста інформація про расове або етнічне
      походження, релігійні переконання, політичні погляди, приналежність до
      профспілки або організації релігійного, філософського або політичного
      характеру, дані, що стосуються здоров'я або сексуального життя, генетичні
      або біометричні дані, якщо вони пов'язані з фізичною особою. SEM BUS також
      вважає важливим класифікувати дані дітей та підлітків як конфіденційні дані,
      завжди наголошуючи на важливості додаткових заходів контролю, що охоплюють
      увесь життєвий цикл інформації, а також докладаючи всіх зусиль для
      заохочення використання наявних механізмів безпеки для забезпечення того,
      щоб такі дані зберігались надійно та оброблялись лише уповноваженими
      особами.
    </p>

    <p>
      Мета: обробка для законних, конкретних, явних та поінформованих цілей
      власника без можливості подальшого опрацювання у спосіб, несумісний
      із цими цілями.
    </p>

    <p>
      Необхідність: обмеження обробки до мінімуму, необхідного для досягнення
      її цілей, з повнотою відповідних даних, пропорційною і не надмірною
      по відношенню до цілей обробки даних.
    </p>

    <p>
      Обробка: це використання нами ваших особистих даних, включно з, окрім
      іншого, такими діями: збирання, зберігання, консультування, спільне
      використання, передання, класифікацію, відтворення, видалення, інактивацію
      та оцінювання.
    </p>

    <p>
      Анонімізовані дані: дані, що відносяться до власника, які не можуть бути
      ідентифіковані з огляду на використання розумних і доступних технічних
      засобів під час їхньої обробки.
    </p>

    <p>
      Правові підстави: це юридичні гіпотези, які дозволяють нам обробляти
      персональні дані для певної мети: наприклад, це може бути ваша згода,
      необхідність виконання укладеного з вами договору або дотримання
      юридичного зобов'язання.
    </p>

    <p>
      Згода: це вільне, поінформоване і недвозначне вираження, за допомогою
      якого власник погоджується на обробку своїх персональних даних для певної
      мети.
    </p>

    <p>
      Співробітник із захисту даних (співробітник із захисту даних), який
      виступає каналом зв'язку між SEM BUS і суб'єктами даних, коли йдеться
      про захист їхніх особистих даних.
    </p>

    <p>
      Політика: Це Політика конфіденційності даних.
    </p>

    <p>
      <strong>
        2. Як збираються ваші персональні дані та які типи персональних даних
        збираються?
      </strong>
    </p>

    <p>
      а) Реєстраційні дані
    </p>

    <p>
      Ми збираємо особисті дані, коли ви створюєте або змінюєте свій обліковий
      запис SEM BUS. Ці дані можуть включати повне ім'я, дату народження,
      фотографію профілю та інші реєстраційні дані, запитувані на нашій платформі.
    </p>

    <p>
      Ми також збираємо особисті дані, коли ви входите на платформу,
      використовуючи платформу Google. Контактні дані користувачів вказані на
      зазначених вище платформах. Ця інформація може бути пов'язана з іншими
      даними, які SEM BUS збирає про вас, і в ситуаціях, описаних у розділі
      "Використання та обмін особистими даними". Інформація, що надається
      третіми сторонами, може регулюватися їхніми відповідними політиками
      конфіденційності.
    </p>

    <p>
      б) Навігаційна інформація: ми також збираємо інформацію, коли ви
      використовуєте наш веб-сайт. Ця інформація включає, але не обмежується:
    </p>

    <p>
      - Детальна інформація про те, як ви використовували наші послуги;
    </p>

    <p>
      - Інформація про пристрій, така як тип і мова вашого браузера;
    </p>

    <p>
      - Айпі адреса.
    </p>

    <p>
      Ідентифікатори, пов'язані з файлами cookie або іншими технологіями, які
      можуть однозначно ідентифікувати ваш пристрій або браузер; сторінки, які
      ви відвідали до або після перегляду нашої платформи. Ми можемо
      використовувати файли cookie та інші технології, як-от веб-маяки,
      веб-сховище та рекламні теги, для збору інформації про вашу активність,
      браузер і пристрій.
    </p>

    <p>
      <strong>
        3. Для яких цілей збираються ваші особисті дані?
      </strong>
    </p>

    <p>
      Дані збираються для надання ресурсів і послуг на Платформі. SEM BUS
      використовує зібрані персональні дані для надання, персоналізації,
      обслуговування та поліпшення своїх продуктів і послуг. Це включає в себе
      використання ваших даних для:
    </p>

    <p>
      а) Створення та оновлення облікового запису:
    </p>

    <p>
      дають змогу виконувати внутрішні операції, необхідні для надання наших
      послуг, включно з усуненням неполадок програмного забезпечення та
      операційних проблем; і проводити аналіз даних, тести та дослідження, а
      також відстежувати й аналізувати тенденції в активності використання;
      Безпека: SEM BUS використовує ваші особисті дані для забезпечення безпеки
      та цілісності наших користувачів і послуг. Це включає, наприклад:
      Використання даних пристрою, профілювання та використання, серед іншого,
      для запобігання, виявлення та боротьби з шахрайством і небезпечними діями.
      Це включає в себе обробку цих даних у певних країнах для виявлення
      практики або стандартів, які вказують на шахрайство або ризик інцидентів,
      пов'язаних із безпекою.
    </p>

    <p>
      б) Підтримка клієнтів:
    </p>

    <p>
      SEM BUS використовує зібрані дані для надання допомоги, коли ви
      звертаєтеся до нашої служби підтримки, щоб:
    </p>

    <p>
      - Дослідити і вирішити проблеми, запитані Користувачем.
    </p>

    <p>
      - Контролювати та покращувати наші послуги.
    </p>

    <p>
      - Юридичні процедури та вимоги. Ми можемо використовувати ваші дані для
      розслідування та вирішення претензій або суперечок, пов'язаних із
      використанням вами послуг SEM BUS, відповідно до чинного законодавства або
      у відповідь на запити регулюючих органів, державних установ та офіційних
      опитувань.
    </p>

    <p>
      <strong>
        4. Кому SEM BUS передає ваші особисті дані?
      </strong>
    </p>

    <p>
      (а) Як ми використовуємо ваші персональні дані
    </p>

    <p>
      Щоб поліпшити пропозицію продуктів і послуг і надати послугу гнучкішим і
      безпечнішим способом, SEM BUS може використовувати вашу особисту
      інформацію для таких цілей:
    </p>

    <p>
      <ol>
        <li>
          Адмініструвати, надавати, постачати, підтримувати, розвивати,
          покращувати, розповсюджувати і розширювати послуги і виконувати
          зобов'язання, що випливають з використання послуг, що надаються
          SEM BUS.
        </li>
        <li>
          Обробити замовлення на надані нами послуги;
        </li>
        <li>
          Ідентифікувати вас і персоналізувати ваш досвід і використання
          платформи та послуг SEM BUS, включно з наданням рекламних акцій;
        </li>
        <li>
          Анонімно проводити внутрішні дослідження ваших інтересів і поведінки,
          щоб краще зрозуміти ваші потреби та інтереси і запропонувати вам більш
          цікаві послуги;
        </li>
        <li>
          Зробити доступними і поліпшити механізми безпеки і моніторингу
          платформи і сервісів SEM BUS;
        </li>
        <li>
          Виявити та захистити SEM BUS та її користувачів від шахрайства,
          зловживань або незаконних дій;
        </li>
        <li>
          Дотриматись юридичних зобов'язань щодо збору та передачі даних уряду
          відповідно до чинного законодавства.
        </li>
      </ol>
    </p>

    <p>
      SEM BUS докладає всіх зусиль для підтримки стандартів безпеки,
      конфіденційності та захисту персональних даних, придатних для її
      користувачів. Внутрішні дані користувачів доступні тільки уповноваженим
      співробітникам, дотримуючись принципів пропорційності, необхідності та
      остаточності для цілей SEM BUS, на додачу до прихильності до
      конфіденційності та збереження приватного життя Користувачів.
    </p>

    <p>
      (б) кому ми передаємо ваші персональні дані
    </p>

    <p>
      <ul>
        <li>
          Комерційним партнерам, які надають послуги або працюють від імені
          SEM BUS, відповідно до специфікацій і керівних принципів, визначених
          SEM BUS, включно з договірними зобов'язаннями щодо збереження
          конфіденційності інформації Третіми сторонами.
        </li>
        <li>
          Організаціям, органам влади та іншим державним органам для дотримання
          законодавства і правил, застосовних до платформи.
        </li>
        <li>
          Органу поліції або представнику прокуратури при підозрі, грубому
          порушенні або розслідуванні злочину із застосуванням насильства або
          серйозної загрози за участю користувачів платформи.
        </li>
        <li>
          Для здійснення та захисту будь-яких прав SEM BUS.
        </li>
      </ul>
    </p>

    <p>
      Зверніть увагу, що інформація, передана третім сторонам, регулюється
      політикою конфіденційності кожної з них.
    </p>

    <p>
      <strong>
        5. Зберігання особистих даних
      </strong>
    </p>

    <p>
      Мы храним ваши личные данные только столько времени, сколько необходимо.
      Мы гарантируем, что если у нас нет причин или законной необходимости
      обрабатывать ваши персональные данные, мы удалим ваши данные.
    </p>

    <p>
      <strong>
        6. Каковы ваши права как субъекта данных?
      </strong>
    </p>

    <p>
      Персональні дані належать вам, і закони України гарантують, що у вас є
      низка прав, пов'язаних із ними. Ми прагнемо забезпечити дотримання цих
      прав, і в цьому розділі ми пояснимо, як ви можете скористатися ними за
      допомогою SEM BUS. Українське законодавство гарантує вам такі права:
    </p>

    <p>
      Підтвердження і доступ: дозволяє вам перевірити, чи обробляє SEM BUS
      особисті дані про вас, і, якщо так, запросити копію ваших особистих даних,
      які ми зберігаємо.
    </p>

    <p>
      Корекція: це дає змогу вам запросити виправлення ваших неповних, неточних
      або застарілих особистих даних.
    </p>

    <p>
      Анонімізація, блокування або видалення: це дає змогу вам попросити нас
      знеособити ваші дані, щоб вони більше не могли бути пов'язані з вами і,
      отже, перестали бути особистими даними; заблокувати ваші дані, тимчасово
      позбавивши нас можливості їх оброблення; і видалити ваші дані, і в цьому
      випадку ми видалимо всі ваші дані без можливості скасування, за винятком
      випадків, коли передбачено законом.
    </p>

    <p>
      Утилізація: якщо ми обробляємо ваші Персональні дані на підставі вашої
      згоди, ви можете запросити видалення даних.
    </p>

    <p>
      Обмін інформацією: ви маєте право знати державні та приватні особи, з
      якими SEM BUS ділиться даними. У будь-якому разі, якщо у вас є сумніви або
      ви хочете отримати детальнішу інформацію, ви маєте право запросити цю
      інформацію у нас.
    </p>

    <p>
      Інформація про можливість незгоди: дає змогу Вам мати чітку та повну
      інформацію про можливість і наслідки ненадання згоди. Ваша згода, коли
      вона потрібна, має бути вільною та поінформованою. Тому щоразу, коли ми
      запитуємо вашу згоду, ви можете відмовитися від неї, хоча в таких випадках
      нам, можливо, доведеться обмежити наші послуги вам.
    </p>

    <p>
      Відкликання згоди
    </p>

    <p>
      Ви маєте право відкликати свою згоду на обробку даних, засновану на згоді.
      Однак це не вплине на законність будь-якої раніше виконаної обробки. Якщо
      ви відкличете свою згоду, ми не зможемо надати вам певні результати, але
      ми повідомимо вам, коли це має місце.
    </p>

    <p>
      Щоразу, коли ви користуєтеся своїми правами, SEM BUS може запросити
      додаткову інформацію для підтвердження вашої особистості з метою
      запобігання шахрайству. Ми робимо це для забезпечення вашої безпеки і
      конфіденційності. Проте, відповіді на деякі запити можуть бути отримані не
      відразу, але SEM BUS зобов'язується відповідати на всі запити в розумні
      строки і завжди відповідно до чинного законодавства.
    </p>

    <p>
      Ви також можете попросити нас не надсилати вам маркетингові повідомлення і
      не використовувати ваші особисті дані для профілювання з метою прямого
      маркетингу або будь-якої іншої вигоди на підставі вашої згоди. Ви можете
      відмовитися від отримання інформаційних бюлетенів електронною поштою та
      інших маркетингових повідомлень, дотримуючись інструкцій щодо відмови від
      підписки, наданих вам у цих електронних листах. Повідомлення з операційних
      облікових записів не постраждають, якщо ви відмовитеся від маркетингових
      розсилок.
    </p>

    <p>
      <strong>
        7. Вжиті заходи безпеки
      </strong>
    </p>

    <p>
      Ми постійно впроваджуємо та оновлюємо адміністративні, технічні та фізичні
      заходи безпеки для захисту вашої інформації від несанкціонованого доступу,
      випадкових або незаконних ситуацій знищення, втрати, зміни, передавання
      або будь-якої форми неналежного чи незаконного поводження.
    </p>

    <p>
      Серед використовуваних методів - брандмауери, шифрування даних і контроль
      доступу до інформації. Якщо вам відомо або ви вважаєте, що ваша
      реєстраційна інформація була загублена, викрадена, перенаправлена або
      скомпрометована, або в разі несанкціонованого використання, негайно
      зв'яжіться з нами.
    </p>

    <p>
      Хоча ми розраховуємо на надійні заходи та засоби контролю інформаційної
      безпеки, неможливо гарантувати, що зловмисники не отримають доступ до
      даних, інформації та комунікацій. Це ризик, притаманний використанню
      комп'ютеризованих систем, який впливає на будь-яке використання цифрових
      послуг.
    </p>

    <p>
      <strong>
        8. Як зв'язатися з SEM BUS
      </strong>
    </p>

    <p>
      Якщо ви вважаєте, що ваша особиста інформація використовувалася у спосіб,
      несумісний із цією Політикою або з вашим вибором як суб'єкта даних, або
      якщо у вас є інші запитання, коментарі чи пропозиції щодо цієї Політики,
      ви можете зв'язатися з нашою командою через наш Сайт, у розділі "Контакти".
    </p>

    <p>
      Контактна адреса електронної пошти: sembus.director@gmail.com.
    </p>

    <p>
      <strong>
        9. Оновлення цієї Політики
      </strong>
    </p>

    <p>
      Оскільки ми завжди прагнемо поліпшити наш Сайт, цю Політику може бути
      оновлено, щоб забезпечити Користувачеві більшу безпеку і зручність, а
      також для подальшого поліпшення досвіду Користувача. З цієї причини ми
      рекомендуємо Користувачеві періодично отримувати доступ до нашої Політики,
      щоб бути в курсі будь-яких змін. Для зручності ми вказуємо в кінці
      документа дату останнього оновлення. Якщо будуть внесені відповідні зміни,
      які потребуватимуть нової згоди Користувачів, ми опублікуємо це оновлення
      і запросимо нову згоду.
    </p>

    <p>
      Дата останнього оновлення: 20.06.2023.
    </p>
  </div>
);

export default PrivacyPolicy
