import React, {useRef, useState, useEffect} from "react";
import './SearchTripResultsCard.scss';
import RaceDetails from "../RaceDetails/RaceDetails";
import classNames from 'classnames';

export const SearchTripResultsCard = ({
  tripData,
  format,
  route,
  handleDetailsClick,
  handleOrderTicketsClick,
  raceDetailsIndex,
  forward
}) => {
  const point1 = route.points.find(point => point.cityData.id === tripData
    .departurePoint.id);
  const point2 = route.points.find(point => point.cityData.id === tripData
    .arrivingPoint.id);

  const departureCity = forward ? point1.cityData : point2.cityData;
  const arrivingCity = !forward ? point1.cityData : point2.cityData;
  const departureTime = forward ? point1.time : point2.time;
  const arrivingTime = !forward ? point1.arrivingTime : point2.arrivingTime;

  const price = route.points.find(point => point.cityData.id === arrivingCity
    .id).priceFromEntry;
  const tripTime = route.points.find(point => point.cityData.id === arrivingCity
    .id).tripTime;

  const departureDate = forward
    ? `${Intl.DateTimeFormat("uk", {weekday: 'short'})
      .format(new Date(tripData.departureDate))} ${format(new Date(
        tripData.departureDate), 'dd.MM.yyyy')}`
    : `${Intl.DateTimeFormat("uk", {weekday: 'short'})
      .format(new Date(tripData.backDepartureDate))} ${format(new Date(
        tripData.backDepartureDate), 'dd.MM.yyyy')}`

  const departureDateSetHours = forward
    ? new Date(new Date(tripData.departureDate).setHours(departureTime[0],
      departureTime[1]))
    : new Date(new Date(tripData.backDepartureDate).setHours(departureTime[0],
      departureTime[1]));

    const departureMillisecond = departureDateSetHours.getTime();
    const tripMillisecond = (+tripTime[0] * 60 + +tripTime[1]) * 60000;
    const arrivingDate = new Date(departureMillisecond + tripMillisecond);

  const arrivalDate = `${Intl.DateTimeFormat("uk", {
    weekday: 'short',
  }).format(arrivingDate)} ${format(arrivingDate, 'dd.MM.yyyy')}`;

  const dropdownHeight = useRef(null);

  useEffect(() => {
    setDropHeight(dropdownHeight?.current?.clientHeight)
    setTimeout(() => {
      dropdownHeight?.current.classList.add("card-wrap__info")
    }, 200)
  }, []);

  const [dropHeight, setDropHeight] = useState('')

  return (
    <>
      <div className="card-wrap">
        <div className={'trip-info-card'}>
        {/*#region first block*/}
        <div className="card-main-info">
          {/*#region departure*/}
          <div className="card-main-info__direction">
            <div className="card-main-info__time">
                    <span className="">
                      {departureDate}
                    </span>
              <span className="">
                      {`${departureTime[0]}:${departureTime[1]}`}
                    </span>
            </div>

            <div className="card-main-info__place">
                    <span className="">
                      {`${departureCity?.city}`}
                    </span>
              <span className="">
                      {`${departureCity?.address || ''}`}
                    </span>
            </div>
          </div>
          {/*#endregion*/}

          {/*#region arrival*/}
          <div className="card-main-info__direction">
            <div className="card-main-info__time">
              <span className="">
                {arrivalDate}
              </span>

              <span className="">
                {`${arrivingTime[0]}:${arrivingTime[1]}`}
              </span>
            </div>

            <div className="card-main-info__place">
              <span className="">
                {`${arrivingCity?.city}`}
              </span>

              <span className="">
                {`${arrivingCity?.address || ''}`}
              </span>
            </div>
          </div>
          {/*#endregion*/}

          <div className="card-main-info__direction-info">
            <span className="card-main-info__time">
              {`${tripTime[0] || '00'} год ${tripTime[1] || '00'} хв `}
            </span>

            <span className="card-main-info__time">
              {`Автобус: ${tripData?.busInTrip || '“SETRA” класу EURO-5,' +
              ' EURO-6'}`}
            </span>
          </div>
        </div>
        {/*#endregion*/}

        {/*#region second block*/}
        <div className="card-price">
          <div className="card-price__value">
            <span></span>
            {/*<span>{price}</span>*/}
            <span>Зателефонуйте нам, щоб отримати більше інформації</span>
            {/*<span>{typeof price === 'number' ? 'ГРН' : ''}</span>*/}
          </div>

          <button
            type={'button'}
            className="card-price__button regular-button"
            onClick={() => handleOrderTicketsClick(false)}
          >
            Дізнатися деталі
          </button>
        </div>
        {/*#endregion*/}

        {/*#region third block*/}
        <div className="card-details">
          <span
            className={classNames("card-details__text", {"card-details__text-clicked": raceDetailsIndex === route.id})}
            onClick={() => {
              handleDetailsClick(route.id);
            }}
          >
                  Деталі
                  <br/>
                  рейсу
                </span>
        </div>
        {/*#endregion*/}

      </div>

        <div
          ref={dropdownHeight}
          style={raceDetailsIndex === route.id ? {
            marginTop: "0px", opacity: "1"
          } : {
            marginTop: `${dropHeight * -1 - 10}px`, opacity: "0"
          }}
        >
          <RaceDetails
            addClasses="trip-info-card__race-details"
          />
        </div>
      </div>
    </>
  );
};
