import React, {useContext, useState, useEffect} from 'react';
import classNames from 'classnames';
import {Link, useNavigate} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";
import {SocialBar} from "../SocialBar/SocialBar";
import {logOut} from "../../utils/firebaseConfigAndFunctions";
import {AppContext} from "../../context/appContext";
import {Sidebar} from "../../drawer/sidebar";
import './Header.scss';

const socialsList = ["fb", "insta", "viber", "whatsapp", "telegram"];

export const Header = ({
  setAreSearchResultsVisible = () => {},
}) => {
  const navigate = useNavigate();

  //#region Handle menu
  const menuItems = {
    home: 'home',
    profile: 'profile',
  }
  const [openMenu] = useState(false);
  const [currentItem, setCurrentItem] = useState(menuItems.home);

  useEffect(() => {
    const body = document.querySelector("body");

    if (openMenu) {
      body.style.overflow = "hidden";
    } else {body.style.overflow = "inherit"}

  }, [openMenu]);
  //#endregion

  //#region Get auth status
  const {isLoggedIn} = useContext(AppContext);
  //#endregion

  //#region Render

  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <header
      className={classNames(
      "header",
      {"header-is-open": openMenu}
    )}
      id="main"
    >
      <Sidebar
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        setAreSearchResultsVisible={setAreSearchResultsVisible}
      />

    <div className="header__top">
      <ScrollLink
        to="main"
        href="/"
        className="header__list-link"
        spy={true}
        smooth={true}
        duration={500}
        onClick={() => {
          setCurrentItem(menuItems.home);
          setAreSearchResultsVisible(false);
          navigate('/');
        }}
      >
        <img
          className="header__logo"
          src="./assets/images/logo.svg"
          alt="logo"
        />
      </ScrollLink>

      <button
        className={classNames(
          "header__opener",
          {"header__opener-open": openMenu}
        )}
        onClick={(e) => setOpenSidebar(!openSidebar)}
      >
      </button>
    </div>

    <nav className="header__nav">
      <ul className="header__list">
        <li className={currentItem === menuItems.home
          ? 'header__list-item header__list-item-active' : 'header__list-item'}
        >
          <ScrollLink
              to="bus-park"
              href="#"
              className="header__list-link"
              spy={true}
              smooth={true}
              duration={500}
          >
            <span
              onClick={() => {
                setCurrentItem(menuItems.home);
                navigate('/');
                setAreSearchResultsVisible(false);
              }}
            >
              Про нас
            </span>
          </ScrollLink>
        </li>

        {isLoggedIn &&
            <li
                className={currentItem === menuItems.profile ?
                    'header__list-item header__list-item-active'
                  : 'header__list-item'}
                onClick={() => setCurrentItem(menuItems.profile)}
            >
              <Link
                  to="/profile"
                  className="header__list-link"
              >
                Профіль
              </Link>
            </li>
        }
      </ul>
    </nav>

    <div className="header__phone">
      <a
        href="tel:+380975013093"
        className="header__phone-link"
      >
        <span className="header__phone-text">
          Зателефонувати
        </span>
        <span className="header__phone-number">
          {' +38(097) 501 30 93'}
        </span>
      </a>

      <a
        href="tel:+380988082606"
        className="header__phone-link"
      >
        <span className="header__phone-text">
          Зателефонувати
        </span>
        <span className="header__phone-number">
          {' +38(098) 808 26 06'}
        </span>
      </a>

      <a
        href="tel:+380982175063"
        className="header__phone-link"
      >
        <span className="header__phone-text">
          Зателефонувати
        </span>
        <span className="header__phone-number">
          {' +38(098) 217 50 63'}
        </span>
      </a>

      <a
        href="tel:+380977561208"
        className="header__phone-link"
      >
        <span className="header__phone-text">
          Зателефонувати
        </span>
        <span className="header__phone-number">
          {' +38(097) 756 12 08'}
        </span>
      </a>
    </div>

    <div className="header__login">
      {!isLoggedIn
        ?(<>
            <div className="header__login-item header__login-item-white">
              <Link
                  to={'/login'}
              >
                Вхід
              </Link>
            </div>


            <div className="header__login-item">
              <Link
                  to={'/register'}
              >
                Реєстрація
              </Link>
            </div>
          </>
        ) : (<>
                <div className="header__login-item header__login-item-white">
                  <Link
                      to={'/'}
                      onClick={() => logOut()}
                  >
                    Вихід
                  </Link>
                </div>
            </>)}
    </div>

    <div className="header__socials">
      <SocialBar
        socialsList={socialsList}
        greyIcon={true}
      />
    </div>
    </header>
  );
  //#endregion
};
