import React, {useState} from 'react';
import {userModel} from "../utils/models";

export const AppContext = React.createContext({
  user: userModel,
  isUserDataComplete: false,
  activeChatUserId: '',
  isLoggedIn: false,
  setUser: () => {},
  setIsUserDataComplete: () => {},
  setActiveChatUserId: () => {},
  setIsLoggedIn: () => {},
});

export const AppProvider = ({children}) => {
  const [user, setUser] = useState(userModel);
  const [isUserDataComplete, setIsUserDataComplete] = useState(false);
  const [activeChatUserId, setActiveChatUserId] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const contextValue = {
    user,
    setUser,
    isUserDataComplete,
    setIsUserDataComplete,
    activeChatUserId,
    setActiveChatUserId,
    isLoggedIn,
    setIsLoggedIn
  }
  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  )
};
